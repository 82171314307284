import {throwError as observableThrowError,  Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Component, OnInit, Input, OnChanges, Inject, Pipe, PipeTransform, Injectable} from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Project } from '../model/project';
import { User } from '../../user/model/user';
import { Router, ActivatedRoute } from '@angular/router';
import { Step } from 'prosemirror-transform';

import { AuthService } from '../../auth.service'
import { UserService } from '../../user/services/user.service'
import { DomSanitizer } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

import { debounce } from 'lodash';


var io = require('socket.io-client');

import {configSettings} from '../../config';
let config = new configSettings();

@Injectable()
export class PaperService {
    constructor( 
        private router: Router,  
        private route: ActivatedRoute, 
        private http: Http, 
        private httpClient: HttpClient,  
        private authService: AuthService, 
        private userService: UserService, 
        @Inject(DOCUMENT) private document:any, 
        private sanitizer: DomSanitizer
    ) { 
        this.submitSteps = debounce(this.submitSteps, 1000, {maxWait: 2000})
    }

    getPapersList(projectId) : Observable<any>
    {
        var url = config.api.project_server.papers.get_papers_list.replace(":project_id", projectId);
        return this.http.post(url, {}, this.authService.getAuthorisedRequestOptions())
            .pipe(map((res) => {
                console.log("In get papers list. the response is:");
                console.log(res);
                var ret = res.json();
                return ret;
            })
            ,catchError(this.handleError));
    }    

    getPaper(projectId, paperId) : Observable<any>
    {
        console.log("IN GET PAPER!!")
        var url = config.api.project_server.papers.get_paper.replace(":project_id", projectId).replace(":paper_id", paperId);
        return this.httpClient.post(url, {}, this.authService.getAuthorisedRequestOptionsHttpClient())
        .pipe(map((res) => {
                console.log("In get paper. the response is:");
                console.log(res);
                var ret = res;
                if(!ret.hasOwnProperty("commentVersion"))
                {
                    console.log('ERROR! No commentVersion received');
                    throw new Error('No commentVersion received');
                }
                return ret;

            })
            ,catchError(this.handleError));
    }    
    
    generatePaperFromIds(projectId, itemIds, paperName, paperConfig, navigate = true) : Observable<any>
    {
        console.log("In Paper Service Generate Paper");
        var url = config.api.project_server.papers.generate_paper_from_ids.replace(":project_id", projectId);
        var body = {item_ids: itemIds, paper_name: paperName, config: paperConfig};
        var self = this;
        console.log("Posting...");

        let options:any = this.authService.getAuthorisedRequestOptionsHttpClient();
        options.responseType = 'json';
        return this.httpClient.post<any>(url, body, options).pipe(map((res:any) => {
                console.log("In generate paper response. the response is:");
                console.log(res)
                var paperId = res.paperId;
                console.log(paperId);
//           if(navigate)
//                {
                    console.log ("Routing to new paper");
                    self.router.navigate(['/projects/' + projectId + '/papers/' + paperId]);
//                }
                return paperId;

            })
            ,catchError(this.handleError));
    }     
    
    
    generatePaperFromFilters(projectId, filters, paperName, config, navigate = true) : Observable<any>
    {
        console.log("In Paper Service Generate Paper");
        var url = config.api.project_server.papers.generate_paper_from_filters.replace(":project_id", projectId);
        var body = {filters: filters, paper_name: paperName};
        var self = this;
        console.log("Posting...");

        let options:any = this.authService.getAuthorisedRequestOptionsHttpClient();
        options.responseType = 'json';
        return this.httpClient.post<any>(url, body, options).pipe(map((res:any) => {
                console.log("In generate paper response. the response is:");
                console.log(res)
                var paperId = res.paperId;
                console.log(paperId);
//           if(navigate)
//                {
                    console.log ("Routing to new paper");
                    self.router.navigate(['/projects/' + projectId + '/papers/' + paperId]);
//                }
                return paperId;

            })
            ,catchError(this.handleError));
    }

    deletePaperPaper(projectId, paperId) : Observable<any>
    {
        var url = config.api.project_server.papers.delete_paper.replace(":project_id", projectId);
        var body = {paper_id: paperId};
        return this.http.post(url, body, this.authService.getAuthorisedRequestOptions())
        .pipe(map((res) => {
                console.log("Deleted the paper");
                return paperId;

            })
            ,catchError(this.handleError));

    }

    getEvents(projectId, paperId, version, commentVersion, clientId) : Observable<any>
    {
        console.log("In Get Events")
        var url = config.api.project_server.papers.get_events.replace(":project_id", projectId).replace(":paper_id", paperId);
        var body = {version: version, commentVersion: commentVersion, clientId: clientId};
        console.log(body);
        return this.http.post(url, body, this.authService.getAuthorisedRequestOptions())
        .pipe(map((res) => {
                console.log("In get events response:");
                console.log(res);
                console.log("Converting to json")
                var ret = res.json();
                console.log("converted. returning")
                return ret;

            })
            ,catchError(this.handleError));
    }    
    
    
    submitSteps(projectId, paperId, version, steps, clientId, comment)
    {
        console.log("in submit steps");
        var url = config.api.project_server.papers.do_submit_steps.replace(":project_id", projectId).replace(":paper_id", paperId);
        
        var submitBody = {steps: steps, version: version, clientId: clientId, comment: comment};
        console.log(submitBody);
        this.httpClient.post(url, submitBody, this.authService.getAuthorisedRequestOptionsHttpClient())
        .pipe(map((res) => {
                console.log("in submit steps RESPONSE");
                let body = res;
                return body;
            })
            ,catchError(this.handleError))
            .subscribe();
    }
    
    exportToDocx(htmlString, projectId, paperId)
    {
        console.log("in export to docx");
        var url = config.api.project_server.papers.do_export_to_docx.replace(":project_id", projectId).replace(":paper_id", paperId);
        
        var submitBody = {htmlString: htmlString};
        this.httpClient.post(url, submitBody, this.authService.getAuthorisedRequestOptionsHttpClient())
        .pipe(map((res) => {
                console.log("in exportToDocx RESPONSE");
                let body = res;
                return body;
            })
            ,catchError(this.handleError))
            .subscribe();
    }





//     submitStepsBody = null;
//     submitTimer = null;
//     submitSteps(serverDetails, projectId, paperId, version, steps, clientId, comment)
//     {
//         console.log("in submit steps");

        
//         var submitBody = {steps: steps, version: version, clientId: clientId, comment: comment, paperId: paperId, projectId: projectId};
//         console.log(submitBody);
        
//         if(this.submitTimer)
//         {
//             clearTimeout(this.submitTimer);
//         }

//         this.submitStepsBody = submitBody;
//         this.submitTimer = setTimeout(() => { 



//                     let url = config.api.project_server.papers.do_submit_steps.replace(":project_id", this.submitStepsBody.projectId).replace(":paper_id", this.submitStepsBody.paperId);
//                     console.log("###################POSTING POSTING!! to " + url )
//                     console.log(JSON.stringify(this.submitStepsBody));
//                     this.http.post(url, this.submitStepsBody, this.authService.getAuthorisedRequestOptions())
//                     .map((res) => {
//                         console.log("<<<<<<<<<in submit steps RESPONSE");
//                         let body = res.json();
//                         return body;
//                     }).subscribe();

                    

//         }, 1000);
        
//         return true;

// //            ,catchError(this.handleError);
//     }






    private handleError (error: Response | any) 
    {
        let errMsg: string;
        if(error instanceof Response) {
            const body = error.json() || '';
            const err = body.error || JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        }
        else
        {
            errMsg = error.message ? error.message : error.toString();
        }
        console.error(errMsg);
        return observableThrowError(errMsg);
    }    



}
