import {inputRules, wrappingInputRule, textblockTypeInputRule,
        smartQuotes, emDash, ellipsis, InputRule} from "prosemirror-inputrules"
//import 'rxjs/add/operator/toPromise';
import { ProjectService } from "../../services/project.service";


//
//export function inputRules({rules}) {
//  return new Plugin({
//    state: {
//      init() { return null },
//      apply(tr, prev) {
//        let stored = tr.getMeta(this)
//        if (stored) return stored
//        return tr.selectionSet || tr.docChanged ? null : prev
//      }
//    },
//
//    props: {
//      handleTextInput(view, from, to, text) {
//        let state = view.state, $from = state.doc.resolve(from)
//        if ($from.parent.type.spec.code) return false
//        let textBefore = $from.parent.textBetween(Math.max(0, $from.parentOffset - MAX_MATCH), $from.parentOffset,
//                                                  null, "\ufffc") + text
//        for (let i = 0; i < rules.length; i++) {
//          let match = rules[i].match.exec(textBefore)
//          let tr = match && rules[i].handler(state, match, from - (match[0].length - text.length), to)
//          if (!tr) continue
//          view.dispatch(tr.setMeta(this, {transform: tr, from, to, text}))
//          return true
//        }
//        return false
//      }
//    },
//
//    isInputRules: true
//  })
//}
//
//











function markInputRule(regexp, markType, getAttrs, endMark) {
    
    //const newRegexp = new RegExp(regexp.source.replace(/\$$/, '') + '(.)' + '$')
    
  return new InputRule(regexp, (state, match, start, end) => {
    let attrs = getAttrs instanceof Function ? getAttrs(match) : getAttrs
    let tr = state.tr
    debugger;
    
    console.log("In the markinputrule handler");
    console.log(match);
    if (match[1]) {
      let textStart = start + match[0].indexOf(match[1])
      let textEnd = textStart + match[1].length
      if (textEnd < end) {
          tr.delete(textEnd, end)
      }
      if (textStart > start) 
      {
          tr.delete(start, textStart)
      }
      end = start + match[1].length
    }
    tr
    .insertText(match[0], start, end - 1)
    .addMark(start, end, markType.create(attrs))
      
    if(endMark)
    {
        tr.insertText(' ')            
        .removeStoredMark(markType) // Do not continue with mark.
    }
    
    return tr
  })
}

// : (NodeType) → InputRule
// Given a blockquote node type, returns an input rule that turns `"> "`
// at the start of a textblock into a blockquote.
export function blockQuoteRule(nodeType) {
  return wrappingInputRule(/^\s*>\s$/, nodeType)
}

// : (NodeType) → InputRule
// Given a list node type, returns an input rule that turns a number
// followed by a dot at the start of a textblock into an ordered list.
export function orderedListRule(nodeType) {
  return wrappingInputRule(/^(\d+)\.\s$/, nodeType, match => ({order: +match[1]}),
                           (match, node) => node.childCount + node.attrs.order == +match[1])
}

// : (NodeType) → InputRule
// Given a list node type, returns an input rule that turns a bullet
// (dash, plush, or asterisk) at the start of a textblock into a
// bullet list.
export function bulletListRule(nodeType) {
  return wrappingInputRule(/^\s*([-+*])\s$/, nodeType)
}

// : (NodeType) → InputRule
// Given a code block node type, returns an input rule that turns a
// textblock starting with three backticks into a code block.
export function codeBlockRule(nodeType) {
  return textblockTypeInputRule(/^```$/, nodeType)
}

// : (NodeType, number) → InputRule
// Given a node type and a maximum level, creates an input rule that
// turns up to that number of `#` characters followed by a space at
// the start of a textblock into a heading whose level corresponds to
// the number of `#` signs.
export function headingRule(nodeType, maxLevel) {
  return textblockTypeInputRule(new RegExp("^(#{1," + maxLevel + "})\\s$"),
                                nodeType, match => ({level: match[1].length}))
}
//
//export function linkRule(nodeType) {
//    return markInputRule(/((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)$/, nodeType, {"href": "", "title": "ABC"}, false)
//}
//
//export function docIdInputRule(markType, projectService, serverDetails, projectId, allIds) {
//    return new InputRule
//    (
//        /([A-z]{2,6}(\.\d{2,9}){1,4})$/, 
//        (state, match, start, end) => {
//            let tr = state.tr
//
//            console.log("In the markinputrule handler");
//            console.log(match);
//
////            projectService.getItemByBates(serverDetails, projectId, match[0]).subscribe
////            (
////                response => 
////                {
////                    console.log("YAY! It's a valid Doc ID");
////                    console.log(match);
////                    console.log(start);
////                    console.log(end);
////
////                    if (match[1]) {
////                      let textStart = start + match[0].indexOf(match[1])
////                      let textEnd = textStart + match[1].length
////                      if (textEnd < end) {
////                          tr.delete(textEnd, end)
////                      }
////                      if (textStart > start) 
////                      {
////                          tr.delete(start, textStart)
////                      }
////                      end = start + match[1].length
////                    }
////                    console.log("Inserting the mark now");
////                    tr
////                    .insertText(match[0], start, end - 1)
////                    .addMark(start, end, markType.create(attrs))
////
////        //            if(endMark)
////        //            {
////        //                tr.insertText(' ')            
////        //                .removeStoredMark(markType) // Do not continue with mark.
////        //            }
////
////                    return tr
////                },
////                err => 
////                {
////                    console.log("Not a DocId")
////                }
////            )
//            
//            
//            
//            
//            
//            
//            
//            var isMatch = false
//            var attrs =  {href: "", title: ""}
//            for(var i = 0; i < allIds.length; i++)
//            {
//                if(match[1] && match[1] == allIds[i].bates)
//                {
//                    console.log("YAY Its a doc id")
//                    isMatch = true;
//                    
//                    attrs.href = projectService.getHyperlink(serverDetails, projectId, allIds[i].id, "preview", allIds[i].bates);
//                    attrs.title = allIds[i].bates + ".pdf";
//                    
//                  let textStart = start + match[0].indexOf(match[1])
//                  let textEnd = textStart + match[1].length
//                  if (textEnd < end) {
//                      tr.delete(textEnd, end)
//                  }
//                  if (textStart > start) 
//                  {
//                      tr.delete(start, textStart)
//                  }
//                  end = start + match[1].length
//                }
//            }
//
//            if(isMatch)
//            {
//                console.log("Inserting the mark now");
//                
//                
//                
//                tr
//                .insertText(match[0], start, end - 1)
//                .addMark(start, end, markType.create(attrs))
//            }
//            else
//            {
//                tr.insertText(match[0], start, end)
//            }
//
//            return tr;
//        }
//    )
//}

export function emRule(nodeType) {
    return markInputRule(/(?:^|[^`_])(?:`)([^`]+)(?:`|_)$/, nodeType, {"href": "", "title": "ABC"}, false)
}


// : (Schema) → Plugin
// A set of input rules for creating the basic block quotes, lists,
// code blocks, and heading.
export function buildInputRules(schema, projectService, projectId, allIds) {
    
  let rules = smartQuotes.concat(ellipsis, emDash), type
  if (type = schema.nodes.blockquote) rules.push(blockQuoteRule(type))
  if (type = schema.nodes.ordered_list) rules.push(orderedListRule(type))
  if (type = schema.nodes.bullet_list) rules.push(bulletListRule(type))
  if (type = schema.nodes.code_block) rules.push(codeBlockRule(type))
  if (type = schema.nodes.heading) rules.push(headingRule(type, 6))
//  if (type = schema.marks.link) rules.push(docIdInputRule(type, projectService, projectId, allIds))
  return inputRules({rules})
}