import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaperService } from "../../services/paper.service";
import { ProjectService } from "../../services/project.service";

@Component({
    selector: 'lrw-papersview',
    templateUrl: 'papersview.component.html',
    styleUrls: ['./papersview.component.css'],
    providers: [
        ProjectService
    ]    
})

export class PapersViewComponent {    
    projectId: any;
    projectName: any;
    papers: any;
    excelIndexes: any;
    serverDetails: any;
    constructor
    (
        private route: ActivatedRoute, 
        private paperService: PaperService,
        private projectService: ProjectService, 
    )
    {
        this.route.params.subscribe
        (
            params => 
            {
                this.projectId = params['project_id'];
                this.paperService.getPapersList(this.projectId).subscribe
                (
                    response => 
                    {
                        console.log("We got the papers list from the server");
                        this.papers = response;
                        console.log(this.papers);
                    }
                );

                this.projectService.getExcelIndexes(this.projectId).subscribe
                (
                    response => 
                    {
                        console.log("We got the Excel list from the server");
                        this.excelIndexes = response;
                        console.log(this.excelIndexes);
                    }
                );
            }
        )
    }

    deletePaper = function(paperId)
    {
        var self = this;
        this.paperService.deletePaperPaper(this.projectId, paperId)
        .subscribe
        (
            (paperId) =>
            {
                console.log("Trying to update the list to get rid of the deleted paper " + paperId);
                for(var i = 0; i < self.papers.length; i++)
                {
                    console.log(self.papers[i]);
                    if(self.papers[i].id == paperId)
                    {
                        self.papers.splice(i, 1);
                        console.log("We have deleted a paper");
                    }
                }
            }
        )
    }

    getExcel = function(filename)
    {
        console.log("We are going to call the ptoject service Excel thing")
        var self = this;

        this.projectService.getExcelUrl(this.projectId, filename).subscribe
        (
            url => 
            {
                window.open(url);
            }
        );
    }
}
