import { Component, Input, Output, OnDestroy, EventEmitter, ElementRef } from '@angular/core';
import { Injector, Renderer2 } from '@angular/core';
import { ProjectService } from "../../../services/project.service";

@Component({
//   selector: 'lrw-paper-itemreference',
   templateUrl: './itemreference.component.html',
   styleUrls: ['./itemreference.component.css'],
})

export class ItemReferenceComponent implements OnDestroy {    

   @Input()
   public projectid: string;
   @Input()
   public text: string;
   @Input()
   public id: string;
   @Input()
   public displayattribute: string;
   @Input()
   public _displayvalue: string;    
   @Input()
   public bates: string;    
   @Input()
   public linktogroup: string;
   @Input()
   public page: string;
   
   @Output() replacedoc = new EventEmitter();

   myElementRef: ElementRef;

   constructor (private elementRef: ElementRef, private projectService: ProjectService) {
      console.log("NEW ITEMREFERENCE COMPONENT")
      this.myElementRef = elementRef;
    }

   handleClick()
   {
      //  console.log("ItemReferenceComponent Click YEEEEEEE");
      //  console.log(this.id);
      //  console.log(this._displayvalue);


       this.projectService.getFile(this.projectid, this.id, this.linktogroup, this.bates).subscribe(
           response => {
               console.log("RESPONSE")
               console.log(response)
               this.projectService.openDataInNewTab(response, "application/pdf", "preview.pdf");
           }
       )


   }

   replaceDoc()
   {
      let newDocId = prompt("New Document Id")
      this.projectService.getItemByBates(this.projectid, newDocId).subscribe((doc) => {
         console.log("We are in the subscribe function")
         console.log(doc);
         if(doc && doc.attributes['summary/bates'])
         {
            this.replacedoc.emit({newBates: doc.attributes['summary/bates'] });
         }
      })


   }

   ngOnDestroy()
   {
      console.log("DESTROYING ME")
   }
}