import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../auth.service';
import { UserService } from '../../services/user.service';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
    selector: 'login',
    templateUrl: './user.activation.component.html',
    styleUrls: ['./user.activation.component.css']
})

export class UserActivationComponent {
    tokenDetails: any;
     tokenEmail: string;
     token: string;
     tokenStatus: string;

    constructor(public authService: AuthService, private router: Router, private route: ActivatedRoute, private userService: UserService) {
        this.tokenStatus = "";
        console.log("In the user activation constructor");
        
        this.route.params.subscribe(params => {
            this.route.queryParams.subscribe(queryParams => {
                this.token = params['token'];
                if(queryParams.hasOwnProperty('forwardurl'))
                {
                    console.log("We have a forward url of " + queryParams['forwardurl']);
                }
                else
                {
                    console.log("couldnt get the forward url ");
                    console.log(queryParams);
                }
                this.loadTokenDetails();
            })
        })
    }
    
    loadTokenDetails () {
        this.userService.getActivationTokenDetails(this.token).subscribe((tokenDetails) => {
            console.log("We have gotten the token details");
            console.log(tokenDetails);
            this.tokenDetails = tokenDetails;
            this.tokenEmail = this.tokenDetails.email;
            this.tokenStatus = "none";
            
            if(tokenDetails.expiry < tokenDetails.current_time)
            {
                this.tokenStatus = "expired"
            }
            else
            {
                this.tokenStatus = "valid";
            }
            console.log(this.tokenDetails.email);
        },(err) => {
            if(err) {
                if(err.toString().includes("Link expired"))
                {
                    console.log("Setting status as expired");
                    this.tokenStatus = "expired"
                }
                else if(this.token.length > 0)
                {
                    this.tokenStatus = "invalid";
                }
            }
        });
    }
    
    onSubmit(form) {
        //TODO Validate input before submitting
        var firstName = ""; //Temporary fix to make it less intimidating for users to sign up
        var lastName = "";
        
        if(form.password == form.password2 && form.password.length > 3)
        {
            this.userService.executeActivation(this.token, this.tokenEmail, firstName, lastName, form.password).subscribe();
        }
    }
    
    onInitiateReset(form) {
        this.userService.initiateActivation(form.email.toString()).subscribe();
    }
}