


<!--
<div class="lrw-tagicon-container">
    <div class="lrw-tagicon-triangle" [ngStyle]="{'border-color': tag.visible ? 'transparent ' + tag.fill_colour + ' transparent transparent' : 'transparent #bbb transparent transparent'}"></div>
    <div class="lrw-tagicon-tagicon" [ngStyle]="{'background-color': tag.visible ? tag.fill_colour : '#bbb', 'color': tag.visible ? tag.text_colour : 'white'}">
        {{tag.title}}
    </div>
    <div class="lrw-tagicon-dot"></div>
</div>

-->


<div class="lrw-tagicon-tagicon-simple" [ngStyle]="{'background-color': tag.visible ? tag.fill_colour : '#bbb', 'color': tag.visible ? tag.text_colour : 'white'}">
    {{tag.title}}
</div>

