<div class="loginComponent">

    <div class="formContainer">
    <h2 class="form-signin-heading">Create a new acount</h2>
        <form #f="ngForm">
            <div class="form-group" [class.invalid]="hasError">
                <label for="email">Email</label>
                <input #autofocus id="email" name="email" ngModel #email="ngModel" class="form-control" placeholder="user@domain.com" required email type="text" (keydown.enter)="onSubmit(f.value)">
                <div>{{errorMsg}}</div>
                <label *ngIf="signupRestriction == 'invitation_code'" for="email">Invitation Code</label>
                <input *ngIf="signupRestriction == 'invitation_code'" id="invitationcode" name="invitationcode" ngModel #invitationcode="ngModel" class="form-control" required  type="text" (keydown.enter)="onSubmit(f.value)">
            </div>
            <button *ngIf="!submitted" class="btn btn-lg btn-primary btn-block submit" type="button" (click)="onSubmit(f.value)">Sign Up!</button>
            <div *ngIf="submitted">
                <br>
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only"></span>
                </div>
            </div>
        </form>
    </div>
    </div>