import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule, FormGroup } from '@angular/forms';
import { HttpModule, JsonpModule } from '@angular/http';

import { NewUserFormComponent } from './components/new/user.new.component'
import { ResetPasswordComponent } from './components/password_reset/user.password_reset.component'
import { LoginEmailComponent } from './components/login_email/user.login_email.component'
//import { SignupEmailComponent } from './components/login_email/user.signup_email.component'
import { LoginPasswordComponent } from './components/login_password/user.login_password.component'
import { UnregisteredComponent } from './components/unregistered/user.unregistered.component'
import { UserActivationComponent } from './components/activation/user.activation.component'
import { MessageComponent } from './components/message/user.message.component'
import { UserComponent } from './index'
import { UserService } from './services/user.service'

@NgModule({
  imports: [
      CommonModule,
      BrowserModule,
      HttpModule,
      FormsModule,
      ReactiveFormsModule,
      JsonpModule
  ],
  declarations: [
      NewUserFormComponent,
      UserComponent,
      LoginEmailComponent,
//      SignupEmailComponent,
      LoginPasswordComponent,
      UnregisteredComponent,
      ResetPasswordComponent,
      UserActivationComponent,
      MessageComponent
  ],
    providers: [
        UserService
    ],
    exports: [
        UserComponent,
        LoginEmailComponent,
        LoginPasswordComponent,
        UnregisteredComponent
    ]
})
export class UserModule { }
