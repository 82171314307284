import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { Project } from '../../model/project'
import { ProjectService } from '../../services/project.service';
import { EmitterService } from '../../../emitter.service';
import {ValidationManager} from "ng2-validation-manager";
import { User } from '../../../user/model/user';
@Component({
  selector: 'new-project-form',
  templateUrl: './project.new.component.html',
  styleUrls: ['./project.new.component.css']
})

export class NewProjectFormComponent implements OnInit {

    model = new Project('', '', '', '');
    submitted = false;
    form;
    
    ngOnInit()
    {
        this.form = new ValidationManager({
            name: 'required',
        })
    }
    
    constructor(private projectService: ProjectService) {

    }
    
    onSubmit()
    {
        if(this.form.isValid()) {
//            this.projectService.newProject(this.form.getValue('name'));
            this.form.reset();
        }
    }

    clearForm()
    {
        this.form.reset();
    }

    get diagnoistics() { return JSON.stringify(this.model);}
}
