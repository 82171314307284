import { Component, OnInit, Input, Output, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

var alphabet = {a: 1, b: 2, c: 3, d: 4, e: 5, f: 6, g: 7, h: 8, i: 9, j: 10, k: 11, l: 12, m:13, n:14, o:15, p:16, q:17, r: 18, s: 19, t: 20, u: 21, v: 22, w: 23, x: 24, y: 25, z: 26};
var imagePath = '/assets/images/icons/email.png';
@Component({
    selector: 'lrw-icon',
    templateUrl: './icon.html',
    styleUrls: ['./icon.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class IconComponent implements OnInit {    
    //@Input() public itemType: string;
    _itemType: string;
    _topLevelRelationship: string;
    
    get itemType():string {
        return this._itemType;
    }
    
    @Input('itemType') set itemType(value: string) {
        this._itemType = value ? value : "???";
        this.refresh();
    }

    @Input('topLevelRelationship') set topLevelRelationship(value: string) {
        this._topLevelRelationship = value;
        this.refresh();
    }    

    iconLetter: string;
    background: string;
     imgSrc: string;

    refresh = function() {
         this.background = this.getIconBackground(this.itemType);
         this.iconLetter = this.getIconLetter(this.itemType);
         this.imgSrc = '/assets/images/icons/' + this.itemType.toLowerCase() + '.png';
    }

     stockIconColours = {
        "email": [216,216,20],
        "archive": [212,170,0],
        "directory": [208,169,49],
        "document": [0,85,212],
        "spreadsheet": [25,179,86],
         "presentation": [255,102,0],
         "image": [128,229,255],
         "text": [135,205,222],
         "calendar": [255,128,128],
         "html": [167,172,147],
         "code": [102,102,102],
         "system": [158,158,158],
         "pdf": [212,0,0],
         "multimedia": [113,200,55],
         "audio": [145,111,111]
     }
     
     
     
    constructor() {
    }
     
     ngOnInit()
     {
         this.refresh();
     }
    
    hslToRgb(h, s, l){
        var r, g, b;

        if(s == 0){
            r = g = b = l; // achromatic
        }else{
            var hue2rgb = function hue2rgb(p, q, t){
                if(t < 0) t += 1;
                if(t > 1) t -= 1;
                if(t < 1/6) return p + (q - p) * 6 * t;
                if(t < 1/2) return q;
                if(t < 2/3) return p + (q - p) * (2/3 - t) * 6;
                return p;
            }

            var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            var p = 2 * l - q;
            r = hue2rgb(p, q, h + 1/3);
            g = hue2rgb(p, q, h);
            b = hue2rgb(p, q, h - 1/3);
        }

        return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
    }
    
    charTotal(str)
    {
        var num = 0;
        for(var i = 0; i < str.length; i++)
        {
            num = num + str.charCodeAt(i);
        }
        return num;
    }
    
    stringToHue(str)
    {
        var numstr = (this.charTotal(str) * 73).toString();
        var hundred = numstr.substring(numstr.length - 2);
        return parseFloat(hundred) / 100;
    }

    stringToLightness(str)
    {
        var numstr = (this.charTotal(str) * 99).toString();
        var hundred = numstr.substring(numstr.length - 2);
        return (parseFloat(hundred) / 300) + 0.4;
    }
    
    stringToSaturation(str)
    {
        var numstr = (this.charTotal(str) * 87).toString();
        var hundred = numstr.substring(numstr.length - 2);
        return (parseFloat(hundred) / 300) + 0.5;
    }
    
    getIconBackground(itemType)
    {
        if(this.stockIconColours.hasOwnProperty(itemType.toLowerCase()))
        {
            return "rgb(" + this.stockIconColours[itemType.toLowerCase()][0] + "," + this.stockIconColours[itemType.toLowerCase()][1] + "," + this.stockIconColours[itemType.toLowerCase()][2] + ")";
        }
        else
        {
              var h = this.stringToHue(itemType);
              var l = this.stringToLightness(itemType);
              var s = this.stringToSaturation(itemType);

            var rgbArray = this.hslToRgb(h, s, l);
            var rgbString = "rgb(" + rgbArray[0] + "," + rgbArray[1] + "," + rgbArray[2] + ")";
            return rgbString;
        }
    }
    

    getIconLetter(extension) {
        return extension[0];
    }    
    
    hasIcon(itemType)
    {
        return this.stockIconColours.hasOwnProperty(itemType.toLowerCase());
    }
}