<div class="filterPanelHint">
    Item is found in any of these locations
</div>
<div class="filterPanelControls">
    <div class="pathTreeContainer">
        <tree-root [nodes]='treeNodeData' [options]="options" #tree>
            <ng-template #treeNodeTemplate let-node let-index="index">
                <div class="bi lrwCheckbox" [ngClass]="{'bi-check': node.data.checked, 'bi-stop-fill': node.data.indeterminate}" (click)="check(node, !node.data.checked)">&nbsp;</div>
                <lrw-icon [itemType]='node.data.type'></lrw-icon>
                <span>{{node.data.title}}</span>
            </ng-template>
        </tree-root>
    </div>
</div>
