import { Component, AfterViewInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { AuthService } from '../../../auth.service';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'login',
    templateUrl: './user.login_password.component.html',
    styleUrls: ['./user.login_password.component.css']
})

export class LoginPasswordComponent implements AfterViewInit {
    email: string;
    errorMsg: string;
    hasError: boolean;
    
    @ViewChild('autofocus', { static: true }) autofocus: ElementRef;
    constructor(public authService: AuthService, private router: Router, private route: ActivatedRoute, private renderer: Renderer2) {
        this.email = localStorage.getItem("loginEmail");
        this.hasError = false;
    }

    ngAfterViewInit() {
        this.autofocus.nativeElement.focus();
    }    

    onLogin(credentials: any) {
        console.log(credentials);
        var password = credentials.password;
        var email = localStorage.getItem("loginEmail");
        this.authService.login({email: email, password: password}, this.route.snapshot.queryParams['returnUrl'] || '/')
        .subscribe(
            () => {},
            (err) => {
                console.log(err);
                this.hasError = true;
                this.errorMsg = "Error. Check password and try again.";
            }
        );
    }
}