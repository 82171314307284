import { Component } from '@angular/core';
import { AuthService } from '../../../auth.service';
import { UserService } from '../../services/user.service';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
    selector: 'login',
    templateUrl: './user.password_reset.component.html',
    styleUrls: ['./user.password_reset.component.css']
})

export class ResetPasswordComponent {
    tokenDetails: any;
    tokenEmail: string;
    token: string;
    tokenStatus: string;
    constructor(public authService: AuthService, private router: Router, private route: ActivatedRoute, private userService: UserService) {
        this.tokenStatus = "none";
        console.log("In the password resetter constructor");
        this.route.params.subscribe(params => {
            this.token = params['token'];
            this.loadTokenDetails();
        })        
    }
    
    loadTokenDetails () {
        this.userService.getPasswordResetTokenDetails(this.token).subscribe((tokenDetails) => {
            console.log("We have gotten the token details");
            console.log(tokenDetails);
            this.tokenDetails = tokenDetails;
            this.tokenEmail = this.tokenDetails.email;
            
            if(tokenDetails.expiry < tokenDetails.current_time)
            {
                this.tokenStatus = "expired"
            }
            else
            {
                this.tokenStatus = "valid";
            }
            console.log(this.tokenDetails.email);
        },(err) => {
            if(err) {
                if(err.toString().includes("Link expired"))
                {
                    console.log("Setting status as expired");
                    this.tokenStatus = "expired"
                }
                else if(this.token.length > 0)
                {
                    this.tokenStatus = "invalid";
                }
            }
        });
    }
    
    onSubmit(form) {
        if(form.password == form.password2 && form.password.length > 3)
        {
            this.userService.executePasswordReset(this.token, form.password).subscribe();
        }
    }
    
    onInitiateReset(form) {
        this.userService.initiatePasswordReset(form.email.toString()).subscribe();
    }
}