import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';

//var config = require('./config');

import {configSettings} from './config';
let config = new configSettings();

@Injectable()
export class Project_ServerService {
    
    required_project_server_version : any = "1.1.1";
    
    constructor(private http: HttpClient, private router: Router, private authService: AuthService) {
    }
    
    //Legacy: returnes nonsense and doesnt get used, It's just here until the code gets cleaned up
    getProjectServer(id: string) : Observable<any>
    {
        var projectServerUrl = config.api.urls.project_server
        console.log("trying to get project server at the following url");
        console.log(projectServerUrl);
        var requestOptions = this.authService.getAuthorisedRequestOptionsHttpClient();
        console.log(requestOptions);
        console.log(this.authService.isCurrentUserAuthenticated());
        return this.http.get(projectServerUrl, requestOptions)
    }
    
    getProjectItems(id: string)
    {
        return this.http.get(config.api.project_server.get_items.replace(":id", id), this.authService.getAuthorisedRequestOptionsHttpClient())
            .pipe(map((data) => {
                return data || {};
            }))        
    }
    
    private handleError (error: Response | any) 
    {
        console.log("Project Service Service: There has been an error");
        console.log(error);
    }    
}