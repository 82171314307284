<div class="formContainer">
    <h2 class="form-signin-heading">Reset password</h2>
    <form class="form-signin" #f="ngForm" *ngIf="tokenStatus=='valid'">
        <div>Reset password for <b>{{tokenEmail}}</b></div>
        <br>
        <div class="form-group" [class.invalid]="refpwd.invalid && !refpwd.untouched">
            <label for="password">New password</label>
            <input id="password" name="password" #refpwd="ngModel" ngModel class="form-control" placeholder="" required minlength="7" type="password">
            <div *ngIf="refpwd.invalid && !refpwd.untouched">must be at least 7 characters</div>
        </div>
        <br>
        <div class="form-group" [class.invalid]="hasError || (refpwd2.value != refpwd.value && !refpwd2.untouched)">            
            <label for="password2">Confirm password</label>
            <input id="password2" name="password2" #refpwd2="ngModel" ngModel class="form-control" placeholder="" required type="password">
            <div *ngIf="refpwd2.value != refpwd.value && !refpwd2.untouched">passwords don't match</div>
        </div>
        <button class="btn btn-lg btn-primary btn-block submit" type="button" (click)="onSubmit(f.value)">Submit</button>
    </form>
    <div *ngIf="tokenStatus=='invalid'">
        <h2 class="form-signin-heading">Link invalid</h2>
        This is not a valid password reset link. Please try the login page to reset your password.
    </div>
    <div *ngIf="tokenStatus=='expired'">
        <h2 class="form-signin-heading">Link expired</h2>
        <div>
            Please enter your email to generate a new password reset link.
        </div>        
        <form class="form-signin" #initiateResetForm="ngForm">
            <div class="form-group" [class.invalid]="hasError">
                <label for="email">Email</label>
                <input id="email" name="email" ngModel class="form-control" placeholder="Email" required="" type="email">
            </div>
            <button class="btn btn-lg btn-primary btn-block submit" type="button" (click)="onInitiateReset(initiateResetForm.value)">Submit</button>
        </form>
    </div>
    <div *ngIf="tokenStatus=='none'">
        <div>
            Please enter your email address and we will send you a link to reset your password.
        </div>        
        <form class="form-signin" #initiateResetForm="ngForm">
            <div class="form-group" [class.invalid]="hasError">            
                <label for="email">Email</label>
                <input id="email" name="email" ngModel class="form-control" placeholder="Email" required="" type="email">
            </div>
            <button class="btn btn-lg btn-primary btn-block submit" type="button" (click)="onInitiateReset(initiateResetForm.value)">Submit</button>
        </form>
    </div>    
</div>