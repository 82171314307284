import { Component, OnInit, Input, Output, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
var moment = require('moment-timezone');
const isoDatestringValidator = require("iso-datestring-validator");

@Component({
    selector: 'lrw-attribute-value',
    templateUrl: './attribute-value.html',
    styleUrls: ['./attribute-value.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class AttributeValueComponent implements OnInit {   
    constructor(private sanitizer:DomSanitizer) {
    }
    //Timestamps
    timestamp_format:any =  "YYYY-MM-DD HH:mm Z";
    timestamp_date:any = "YYYY-MM-DD";
    timestamp_time:any = "HH:mm";
    timestamp_timezone:any = "Z";

    timestamp_displayFormatHTML(value, displayTimezone)
    {

        console.log("html formatting the following attribute");
        console.log(value);
        let css_date = 'margin-right: 6px';
        let css_separator = "opacity: 0.4";
        let css_time = 'opacity: 0.6';


        if(value == null || value == "" || !isoDatestringValidator.isValidISODateString(value))
        {
            console.log("OH NO, NOT A VALID DATE");
            console.log(value)
            return "";
        }


        console.log("Formatting a date");
        console.log(value);
        console.log(moment(value));
        console.log(displayTimezone);
        

        var ret = "<span style='" + css_date + "'>" +
        moment(value).tz(displayTimezone).format(this.timestamp_date)
            .replace(/-/g, "<span style='" + css_separator + "'>&ndash;</span>") +
        "</span><span style='" + css_time + "'>" +
        moment(value).tz(displayTimezone).format(this.timestamp_time) +
        "</span>"

        return this.sanitizer.bypassSecurityTrustHtml(ret);
    }

    innerHtml: any = "";


    //@Input() public itemType: string;
    _attributeName: string;
    get attributeName():string {
        return this._attributeName;
    }
    @Input('attribute-name') set attributeName(value: string) {
        this._attributeName = value;
    }

    _attributeValue: string;
    get attributeValue():string {
        return this._attributeValue;
    }
    @Input('attribute-value') set attributeValue(value: string) {
        this._attributeValue = value;
    }
     
    _displayTimezone: string;
    get displayTimezone():string {
        return this._displayTimezone;
    }
    @Input('display-timezone') set displayTimezone(value: string) {
        this._displayTimezone = value;
    }

     
     ngOnInit()
     {
         if(this.attributeName.includes("[date]"))
         {
             this.innerHtml = this.timestamp_displayFormatHTML(this.attributeValue, this.displayTimezone);
         }
         else
         {
             this.innerHtml = this.attributeValue
         }
     }

}