
import {throwError as observableThrowError,  Observable, EMPTY } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { User } from '../model/user';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';



import { AuthService } from '../../auth.service';
//var config = require('../../config');
import { Router, ActivatedRoute } from '@angular/router';


import {configSettings} from '../../config';
let config = new configSettings();


@Injectable()
export class UserService {
    constructor(
        private http: Http, 
        private httpClient: HttpClient,  
        private authService: AuthService, 
        private router: Router, 
        private route: ActivatedRoute) { }

    getPublicStatus(email: string) : Observable<any>
    {
        console.log(config);
        console.log("Here is process.env");
        console.log(process.env)
        console.log("Calling: " + config.api.urls.users_public_status);
        return this.http.post(config.api.urls.users_public_status, {email: email})
        // ...and calling .json() on the response to return the data
        .pipe(map((res) => {
            let body = res.json();
            return {found: true, email: body.data.email, registered: body.data.registered};
        })
        // ...deal with any errors
        ,catchError(this.handleError)
        );
    }
    
    getUserByEmail(account_id: string, email: string) : Observable<User>
    {
        console.log("We are in the user service list function");
        
        return this.http.get(config.api.urls.account_users.replace(":id", account_id), this.authService.getAuthorisedRequestOptions())
            // ...and calling .json() on the response to return the data
            .pipe(map((res) => {
                let users = res.json().data;
                for(var i = 0; i < users.length; i++)
                {
                    if(users[i].email == email)
                    {
                        return users[i];
                    }
                }
                return {};
            })
            // ...deal with any errors
            ,catchError(this.handleError)
            );
    }

    initiatePasswordReset(email: string) : Observable<any>
    {
        console.log("Trying to initiate a password reset for " + email );
        return this.http.post(config.api.urls.password_reset_initiate, {"email": email})
            .pipe(map((res) => {
                console.log("Initiated password reset");
                console.log(res.json())
                this.router.navigateByUrl('message/resetlinksent')
            })
            // ...deal with any errors
            ,catchError((err) => {
                console.log("There was an error generating the password reset link");
                return this.router.navigateByUrl('message/reseterror');
            })
        )
    }


    
    getPasswordResetTokenDetails(token: string) : Observable<any>
    {
        return this.http.get(config.api.urls.password_reset_token_details.replace(":token", token))
            .pipe(map((res) => {
                console.log("Returned from the tken details server");
                console.log(res.json())
                return res.json();
            })
            // ...deal with any errors
            ,catchError(this.handleError)
            );
    }
    
    executePasswordReset(token: string, newPassword: string) : Observable<any>
    {
        return this.http.post(config.api.urls.password_reset_execute.replace(":token", token), {password: newPassword})
            .pipe(map((res) => {
                console.log("Returned from the tken details server");
                console.log(res.json())
                this.router.navigateByUrl('message/resetdone');
            })
            // ...deal with any errors
            ,catchError((err) => {
                return(this.handleError(err));
            })
            );
    }
    
    initiateActivation(email: string, invitation_code="") : Observable<any>
    {
        console.log("Trying to initiate user activation for " + email );
        console.log(config.api.urls.user_activation_initiate);
        return this.http.post(config.api.urls.user_activation_initiate, {"email": email, "invitation_code": invitation_code})
            .pipe(map((res) => {
                console.log("Initiated user activation");
                console.log(res.json())
                this.router.navigateByUrl('message/useractivationlinksent')
            })
            // ...deal with any errors
            ,catchError((err) => {
                console.log("There was an error generating the user activation link");
                return this.router.navigateByUrl('message/useractivationlinkerror');
            })
            )
    }
    
    getActivationTokenDetails(token: string) : Observable<any>
    {
        return this.http.get(config.api.urls.user_activation_token_details.replace(":token", token))
            .pipe(map((res) => {
                console.log("Returned from the tken details server");
                console.log(res.json())
                return res.json();
            })
            // ...deal with any errors
            ,catchError(this.handleError)
            );
    }
    
    executeActivation(token: string, email: string, first_name: string, last_name: string, newPassword: string) : Observable<any>
    {
        //TODO validate input before sending
        
        return this.http.post(config.api.urls.user_activation_execute.replace(":token", token), {email: email, first_name: first_name, last_name: last_name, password: newPassword})
            .pipe(map((res) => {
                console.log("Returned from the tken details server");
                console.log(res.json())
            
            
                var forwardUrl = this.route.snapshot.queryParams['forwardurl'] ? this.route.snapshot.queryParams['forwardurl'] : null;
                console.log(this.route.snapshot.queryParams);
                console.log(forwardUrl);
            
            
                if(forwardUrl)
                {
                    console.log("There is a forward url")
                    this.authService.login({email: email, password: newPassword}, this.route.snapshot.queryParams['forwardurl'])
                    .subscribe(
                        () => {},
                        (err) => {
                            console.log(err);
                        }
                    );
                }
                else
                {
                    this.router.navigateByUrl('message/useractivationdone');
                }
            })
            // ...deal with any errors
            ,catchError((err) => {
                return(this.handleError(err));
            })
            )
    }
        
    
    private async handleError (error: Response | any) 
    {
        console.log("Project Service: There has been an error");
        console.log(error);
        let errMsg: string;
        if(error instanceof Response) {
            const body = await error.json() || '';
            const err = body.error || JSON.stringify(body);
            console.log("First Part");
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        }
        else
        {
            console.log("Second Part");
            errMsg = error.message ? error.message : error.toString();
        }
        console.error(errMsg);
        return observableThrowError(errMsg);
    }

    newUser(email: string, invitation_code = "") : Observable<any>
    {


        let user = {user_email: email, email: email, invitation_code: invitation_code}

        return this.http.post(config.api.urls.do_create_user_by_email, user, {})
        .pipe(map(response => response.json()))
        .pipe(mergeMap(firstData => {
          // Perform the second HTTP request using data from the first request
          return this.http.post(config.api.urls.user_activation_initiate, user, {})
            .pipe(map((res) => {
                    console.log("Initiated user activation");
                    console.log(res.json())
                    this.router.navigateByUrl('message/useractivationlinksent')
                })
                // ...deal with any errors
                ,catchError((err) => {
                    console.log("There was an error generating the user activation link");
                    return this.router.navigateByUrl('message/useractivationlinkerror');
                })
            )


        }));





        // console.log("About to create a new user")
        // this.http.post(config.api.urls.do_create_user_by_email, JSON.stringify(user), {}).subscribe(
        //     () =>
        //     {
        //         console.log("Trying to initiate user activation for " + email );
        //         console.log(config.api.urls.user_activation_initiate);
        //         return this.http.post(config.api.urls.user_activation_initiate, {"email": email, "invitation_code": invitation_code})
        //             .pipe(map((res) => {
        //                     console.log("Initiated user activation");
        //                     console.log(res.json())
        //                     this.router.navigateByUrl('message/useractivationlinksent')
        //                 })
        //                 // ...deal with any errors
        //                 ,catchError((err) => {
        //                     console.log("There was an error generating the user activation link");
        //                     return this.router.navigateByUrl('message/useractivationlinkerror');
        //                 })
        //             )
        //     }
        // );


    }

    listUserAccountProjects() : Observable<any>
    {
        console.log("We are in the project service list function");
        var url = config.api.urls.user_account_projects.replace(":id", this.authService.getCurrentUser().user_id);

        return this.httpClient.post<any>(url, {}, this.authService.getAuthorisedRequestOptionsHttpClient())
        // ...and calling .json() on the response to return the data
        .pipe(map((res) => {
            console.log("HERE IS THE RES")
            console.log(JSON.stringify(res))
            return res.data || {};
        }),
        // ...deal with any errors
        catchError
        (
            (err: Response | any) =>
            {
                console.log("ERROR getting the list of user account projects");
                console.log(err);
                return this.handleError(err);
            }
        )); 
    }

    getUnviewedUserNotifications() : Observable<any>
    {
        console.log("We are in the notifications list function");
        let currentUser = this.authService.getCurrentUser();
        if(currentUser)
        {
            var url = config.api.urls.unviewed_user_notifications.replace(":id", currentUser.user_id);

            return this.httpClient.post<any>(url, {}, this.authService.getAuthorisedRequestOptionsHttpClient())
                // ...and calling .json() on the response to return the data
                .pipe(map((res) => {
                    return res || {};
                }),
                // ...deal with any errors
                catchError
                (
                    (err: Response | any) =>
                    {
                        return this.handleError(err);
                    }
                )); 

        }
        else
        {
            return EMPTY;
        }

        
    }

    dismissUserNotifications(notificationIds) : Observable<any>
    {
        console.log("We are in the notifications list function");
        var url = config.api.urls.dismiss_user_notifications.replace(":id", this.authService.getCurrentUser().user_id);
        return this.http.post(url, {token: this.authService.getToken(), notificationIds: notificationIds}, this.authService.getAuthorisedRequestOptions())
            // ...and calling .json() on the response to return the data
            .pipe(map((res) => {
                let body = res.json();
                console.log(body)
                return body || {};
            })
            // ...deal with any errors
            ,catchError(this.handleError)
            );
    }

    getSignupRestrictionSetting() : Observable<any>
    {
        console.log("We are in the get signup restriction function");
        
        return this.http.post(config.api.urls.get_signup_restriction_setting, {}, this.authService.getAuthorisedRequestOptions())
            // ...and calling .json() on the response to return the data
            .pipe(map((res) => {
                let signup_restriction_setting = res.json().data.signup_restriction_setting;
                console.log("here is the signup restriction setting")
                console.log(signup_restriction_setting);
                return signup_restriction_setting;
            })
            // ...deal with any errors
            ,catchError(this.handleError)
            );
    }

}