<div class="loginComponent">
<div class="formContainer">
<img class="dialog-logo" src="./assets/images/logo-dark.svg"/>
<h2 class="form-signin-heading">Log in</h2>
    <form #f="ngForm">
        <div class="form-group" [class.invalid]="hasError">
            <label for="email">Email</label>
            <input #autofocus id="email" name="email" ngModel #email="ngModel" class="form-control inset-input" placeholder="user@domain.com" required email type="text" (keydown.enter)="onSubmit(f.value)">
            <div>{{errorMsg}}</div>
        </div>
        <button class="btn btn-lg btn-primary btn-block submit" type="button" (click)="onSubmit(f.value)">Next &#x25BA;</button>
    </form>
    <br>
    <span>No account? <a class="forgotpassword" href="users/new">Click here to Sign Up</a></span>
</div>
</div>