import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { Administrator } from '../../model/administrator'
import { AdministratorService } from '../../services/administrator.service';
import { EmitterService } from '../../../emitter.service';
import { ValidationManager } from "ng2-validation-manager";
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../../auth.service';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'change_password',
    templateUrl: './administrator.change_password.component.html',
    styleUrls: ['./administrator.change_password.component.css']
})

export class ChangePasswordComponent implements OnInit {
    constructor(private administratorService: AdministratorService, private route: ActivatedRoute, private authService: AuthService) {
        console.log("We are in the constructor for the AdministratorEditComponent");
        this.success = false;
    }

    administrator = {}
    submitted = false;
    form;
    success;

    ngOnInit()
    {
        this.administrator = this.authService.getCurrentUser();
        
        this.form = new ValidationManager({
            existing_password: 'required',
            new_password: 'required',
            new_confirm_password: 'required|equalTo:new_password'
        });
    }
    
    onSubmit()
    {
        console.log("here is the administrator:");
        console.log(this.administrator);
        if(this.form.isValid()) {
            this.administratorService.change_password(
                this.form.getValue('existing_password'), 
                this.form.getValue('new_password'), 
                this.form.getValue('new_confirm_password')
            ).subscribe(
                data => {
                    console.log("we are updating the success status");
                    this.success = true;
                },
                err => {
                    console.log(err);
                }
            );
            this.form.reset();
        }
        return false; //prevent fields from popuuing up in url
    }

    clearForm()
    {
        this.form.reset();
    }
}