<div class="filterPanelHint">
    Item 'Date' is within this range
</div>
<div class="filterPanelControls">
    <div class="form-group">
        <label for="earliestDate">Earliest <span class="hint">yyyy-mm-dd</span></label>
        <input class="form-control input-sm inset-input" id="earliestDate"  [(ngModel)]="data.earliest"  (ngModelChange)="onChange($event)"  placeholder="e.g. 2007-10-29"/>
    </div>
    <div class="form-group">
        <label for="latestDate">Latest <span class="hint">yyyy-mm-dd</span></label>
        <input class="form-control input-sm inset-input" id="latestDate" [(ngModel)]="data.latest" (ngModelChange)="onChange($event)" placeholder="e.g. 2007-10-29"/>
    </div>
</div>