import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'lrw-filter-date',
    templateUrl: './filter.date.component.html',
    styleUrls: ['./filter.date.component.css']
})

export class FilterDateComponent {    
    initDate: Date;
    
    earliestDate: string = "";
    latestDate: string = "";
    bsValue: any;

    private _data: any;
    get data():any {
        return this._data;
    }
    @Input('data') set data(value: any) {
        this._data = value ? value : null;
        
    }
    
    
    @Output() filterChange: EventEmitter<any> = new EventEmitter();

    constructor() {
    }

    yyyymmdd(date) {
        var yyyy = date.getFullYear().toString();
        var mm = (date.getMonth() + 1).toString();
        var dd = date.getDate().toString();
        
        var mmChars = mm.split('');
        var ddChars = dd.split('');
        
        var str = yyyy + "-" + (mmChars[1]?mm:"0"+mmChars[0]) + "-" + (ddChars[1]?dd:"0"+ddChars[0]);
        console.log(str);
        return str;
    }

    onChange(event)
    {
        this.filterChange.emit(this.data);
    }
}