import { Component, OnInit, Input, Output, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter } from '@angular/core';
var randomColor = require('randomcolor');

@Component({
    selector: 'lrw-avatar',
    templateUrl: './avatar.html',
    styleUrls: ['./avatar.css'],
})

export class AvatarComponent { 

    _user_email: string;
    _user_id: string;
    _size: any;

    get user_email(): string
    {
        return this._user_email;
    }
    get user_id(): string
    {
        return this._user_id;
    }
    get size(): any
    {
        return this._size;
    }

    @Input('user_email') set user_email(value: string) {
        this._user_email = value;
        this.refresh();
    }

    @Input('user_id') set user_id(value: string) {
        this._user_id = value;
        this.refresh();
    }

    @Input('size') set size(value: any) {
        this._size = value;
        this.refresh();
    }






    avatarStyle: any = {};

    refresh() {
        let backgroundColor = randomColor(
            {
                luminosity: 'bright', 
                format: "hslArray",
                seed: this.user_id
            }
        )

        console.log(backgroundColor)
        let MINL = 60;
        let MAXL = 80;
        backgroundColor[2] = Math.min(Math.max(backgroundColor[2], MINL), MAXL);

        let MINS = 30;
        let MAXS = 60;
        backgroundColor[1] = Math.min(Math.max(backgroundColor[1], MINS), MAXS);


        let hsl = "hsl(" + backgroundColor[0] + "," + backgroundColor[1] + "%," + backgroundColor[2] + "%)";

        this.avatarStyle = {'backgroundColor': hsl}

        console.log("The seed is: " + this.user_id)

        console.log(this.user_email);
        console.log(this.user_id)
    }
}