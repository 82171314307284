import { Component } from '@angular/core';
import { EmitterService } from '../emitter.service';

@Component({
    selector: 'user-widget',
    template: `
        <div class="userTop">
        </div>

        `
})

export class UserComponent {
    private listId = 'USER_COMPONENT_LIST';
}