<div class="container newAccountForm">
    <form [formGroup]="form.getForm()" (ngSubmit)="onSubmit();">
        <div class="form-group">
            <label for="name">Name</label>
            <input type="text" class="form-control" id="name"  name="name" formControlName="name">
            <small class="error-alert" *ngIf="form.hasError('name')">First Name is required</small>
        </div>
        <button class="btn btn-success" type="submit">Submit</button>
        <button class="btn btn-default" type="button" (click)="clearForm()">Reset</button>
    </form>
</div>