<div class="filterPanelHint">
    Item 'Type' is any of these
</div>
<div class="filterPanelControls">
    <div (click)="allnone($event)" class="entry allnone">
        <div class="bi lrwCheckbox" [ngClass]="{'bi-check': masterCheckStatus == 'all', 'bi-stop-fill': masterCheckStatus == 'partial'}">&nbsp;</div>
        <span>Select</span>
    </div>
    <div *ngFor="let doctype of doctypes" (click)="onCheckboxClick($event, doctype)" class="entry">
        <div class="bi lrwCheckbox" [ngClass]="{'bi-check': isChecked(doctype)}" >&nbsp;</div>
        <lrw-icon [itemType]='doctype'></lrw-icon>
        <span class="typename">{{doctype}}</span>
    </div>
</div>
