import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../../model/user'
import { UserService } from '../../services/user.service';
import { EmitterService } from '../../../emitter.service';
import { ValidationManager } from "ng2-validation-manager";

@Component({
  selector: 'new-user-form',
  templateUrl: './user.unregistered.component.html',
  styleUrls: ['./user.unregistered.component.css']
})

export class UnregisteredComponent implements OnInit {

    submitted = false;
    form;
    message = "Generate Activation Link"
    generatingLink = false;
    signupRestriction;

    ngOnInit()
    {
//        this.form = new ValidationManager({
//            first_name: 'required',
//            last_name: 'required',
//            email: 'required|email',
//            password: 'required',
//            confirm_password: 'required|equalTo:password'
//        })


        this.userService.getSignupRestrictionSetting().subscribe(
            setting =>
            {
                this.signupRestriction = setting;
            }
        );

    }
    
    constructor(private userService: UserService) {

    }
    
    regenerateAccountActivationLink()
    {
        if(this.generatingLink == false)
        {
            if(this.signupRestriction == "invitation_code")
            {
                let invitation_code = prompt("Please provide invitation code:")
                this.generatingLink = true;
                this.message = "Generating link..."
                this.userService.initiateActivation(localStorage.getItem('loginEmail'), invitation_code).subscribe();
            }
            else
            {
                this.generatingLink = true;
                this.message = "Generating link..."
                this.userService.initiateActivation(localStorage.getItem('loginEmail')).subscribe();
            }
        }
    }
}
