import {Plugin} from 'prosemirror-state';
import {toggleMark, setBlockType, wrapIn} from "prosemirror-commands";
import {wrapInList} from "prosemirror-schema-list";
import {setAlign, insertHorizontalRule, insertNewTable, printJson, addComment} from "./commands";
import {schema} from "./schema";

function setClass(dom, cls, on) {
  if (on) dom.classList.add(cls)
  else dom.classList.remove(cls)
}

class MenuView {
    items: any;
    editorView: any;
    dom: any;
    
    constructor(items, editorView) {
        this.items = items;
        this.editorView = editorView;
        
        this.dom = document.getElementById("menubar");
        this.dom.id = "menubar";
        this.dom.className = "menubar";
//        items.forEach(({dom}) => this.dom.appendChild(dom));
        
        this.update();
        
        this.dom.addEventListener("mousedown", e => {
            e.preventDefault();
            editorView.focus();
            items.forEach(({command, dom}) => {
                if(dom.contains(e.target))
                {
                    command(editorView.state, editorView.dispatch, editorView);
                }
            });
        });
    }
    
    update() {
        this.items.forEach(({command, dom}) => {
            var active = command(this.editorView.state, null, this.editorView);
            setClass(dom, "inactive", !active)
        })
    }
    
    destroy() { this.dom.remove() }
}

function menuPlugin(items, parentNode) {
    return new Plugin ({
        view(editorView) {
            let menuView = new MenuView(items, editorView);
            parentNode.appendChild(menuView.dom);
            return menuView;
        }
    })
}


function icon(text, name) {
    let span = document.createElement("span");
    span.className = "menuicon menuicon-" + name;
    span.title = name;
    span.textContent = text;
    return span;
}

function getButton(name)
{
    return document.getElementById("cmd-" + name);
}

function menu(parentNode) {
    return menuPlugin ([
        {command: toggleMark(schema.marks.strong), dom: getButton("strong")},
        {command: toggleMark(schema.marks.em), dom: getButton("em")},
        {command: toggleMark(schema.marks.underline), dom: getButton("underline")},
        {command: toggleMark(schema.marks.strikethrough), dom: getButton("strikethrough")},
        {command: setBlockType(schema.nodes.paragraph), dom: getButton("paragraph")},
        {command: setBlockType(schema.nodes.heading, {level: 1}), dom: getButton("h1")},
        {command: setBlockType(schema.nodes.heading, {level: 2}), dom: getButton("h2")},
        {command: setBlockType(schema.nodes.heading, {level: 3}), dom: getButton("h3")},
//        {command: wrapIn(schema.nodes.blockquote), dom: icon(">", "blockquote")},
        {command: setAlign("left"), dom: getButton("alignleft")},
        {command: setAlign("center"), dom: getButton("aligncenter")},
        {command: setAlign("right"), dom: getButton("alignright")},
        {command: wrapInList(schema.nodes.bullet_list), dom: getButton("bullet-list")},
        {command: wrapInList(schema.nodes.ordered_list), dom: getButton("ordered-list")},
        {command: insertHorizontalRule(), dom: getButton("horizontal-rule")},
        {command: insertNewTable(5,5, {gridlines: true}), dom: getButton("new-table")},
        // {command: addComment(), dom: getButton("comment")},
        // {command: printJson(), dom: getButton("print-json")},
//        {command: exportToDocx(), dom: getButton("export-to-docx")}
    ], parentNode)
}

export default menu;