import { Component } from '@angular/core';
import {EmitterService} from '../emitter.service';

@Component({
    selector: 'account-widget',
    template: `
        <div>
            <account-list></account-list>
        </div>
        `
})

export class AccountComponent {
    private listId = 'USER_COMPONENT_LIST';
}