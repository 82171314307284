<div class="projectPage projectViewPage" [hidden]="activeTab!='evidence'">
        <as-split direction="horizontal" [gutterSize]="1" (dragEnd)="onDragEnd($event)" (dragProgress)="onDragProgress($event)" (dragStart)="onDragProgress($event)" (window:resize)="onDragProgress($event)">
            <as-split-area [size]="15" class="leftPane">
            
                
                
                <div class="toolsSection" *ngIf="this.junkMode == false">
                    <div class="filterSectionHeader">
                        <div>
                            <span class="ui-section-header">Actions</span>
                        </div>
                    </div>
                    
                    <div class="toolsButton">
                        <div class="panelContainer collapsed">
                            <div class="panelHeading"  data-bs-toggle="modal" data-bs-target="#importDataModal">
                                <span class="bi bi-box-arrow-in-right filterIcon"></span>
                                <span class="panelHeadingContent">Import Data</span>
                            </div>
                        </div>
                    </div>
                                        
                    <div class="toolsButton">
                        <div class="panelContainer collapsed">
                            <div class="panelHeading dropdown-toggle" id="tagControlDropdown" #tagControlDropdown data-bs-toggle="dropdown">
                                <span class="bi bi-tag filterIcon"></span>
                                <span class="panelHeadingContent">Tag Items</span>                   
                            </div>
                            <div class="dropdown-menu tagsDropdownMenu" aria-labelledby="tagControlDropdown">
                                <div class="tagDialogMain" (click)="$event.stopPropagation()">
                                    <!-- <div class="tagDialogHeader" *ngIf="datagrid">
                                        <div class="applyTo">Tag {{datagrid.subPrincipalIds.length}} Selected Items:</div>
                                    </div> -->
                                    <div class="tagList">
                                        <ul>
                                            <li *ngFor="let tag of visibleTagIds | sortedTags: tags" class="tagEntry">
                                                <div (click)="closeTagsDropdown(); $event.stopPropagation(); performTagOperation('remove', tagOperationItems, tag.id);">
                                                    <span class="untag">Untag</span>
                                                </div>
                                                <div class="tag" (click)="closeTagsDropdown(); $event.stopPropagation(); performTagOperation('add', tagOperationItems, tag.id);">
                                                    <lrw-tagicon [tag]="tag"></lrw-tagicon>
                                                    <span class="tagShortcut" *ngIf="tag.shortcut && tag.shortcut.length > 0">{{tag.shortcut}}</span>
                                                </div>
                                            </li>
                                        </ul>
                                        <!-- <ul>
                                            <li *ngFor="let tag of hiddenTagIds | sortedTags: tags" class="tagEntry">
                                                <div  (click)="$event.stopPropagation(); performTagOperation('remove', tagOperationItems, tag.id);">
                                                    <span class="bi bi-dash"></span><span>Untag</span>
                                                </div>
                                                <div class="tag" (click)="$event.stopPropagation(); performTagOperation('add', tagOperationItems, tag.id);">
                                                    <lrw-tagicon [tag]="tag"></lrw-tagicon>
                                                </div>
                                            </li>
                                        </ul> -->
                                    </div>
                                    <div class="tagDialogHeader">
                                        <div class="applyTo">New Tag:</div>
                                    </div>
                                        <div class="newTagControls">
                                            <button class="btn btn-default btn-sm notround" type="button"
                                                         [popper]="popper1Content" 
                                                         [popperShowOnStart]="false"
                                                         [popperTrigger]="'click'"
                                                         [popperHideOnClickOutside]="true"
                                                         [popperHideOnScroll]="true"
                                                         [popperPlacement]="'bottom-start'"
                                                         [popperApplyClass]="'colourpickerPopper'"
                                                        >
                                                <div class="fillColourIndicator" [style.background]="addTagInputs.fill_colour" [style.color]="addTagInputs.text_colour" [style.margin]="'auto'"></div>
                                            </button>
                                            <input type="text" class="form-control input-sm tagNameInput" [(ngModel)]="addTagInputs.title" placeholder="tag name" (keyup.enter)="addTagInputs.title.length > 0 && addTag(addTagInputs.title)" (input)="tagInputChange()">
                                            <input type="text" class="form-control input-sm shortcutInput" [(ngModel)]="addTagInputs.shortcut" placeholder="Key" (keyup.enter)="addTagInputs.title.length > 0 && addTag(addTagInputs.title)" maxlength="1" (input)="tagShortcutInputChange($event)">
                                            <popper-content #popper1Content>
                                                <lrw-colourpicker [(fillColour)]="addTagInputs.fill_colour" [(textColour)]="addTagInputs.text_colour" (close)="popper1Content.hide()"></lrw-colourpicker>
                                            </popper-content>                                        
                                            <button class="btn btn-default btn-sm notround" type="button" [ngClass]="{'btn-success': addTagInputs.title.length > 0}" (click)="closeTagsDropdown(); addTag(addTagInputs.title)" [disabled]="addTagInputs.title.length < 1"><span class="bi bi-check"></span></button>
                                        </div>                                                            
                                    <div class="tagDialogFooter">
                                        <div class="manageTags">
                                            <a class="link" (click)="closeTagsDropdown(); document.getElementById('newIndexInput').focus();" data-bs-toggle="modal" data-bs-target="#manageTagsModal" >
                                                Manage Tags
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>      
                        </div>
                    </div> 


                    <div class="toolsButton">
                        <div class="panelContainer collapsed">
                            <div class="panelHeading dropdown-toggle" 
                                id="junkDropdown"
                                data-bs-toggle="dropdown" 
                                aria-haspopup="true"
                            >
                                <span class="bi bi-trash filterIcon"></span>
                                <span class="panelHeadingContent" >Junk Items</span>
                            </div>

                            <div class="dropdown-menu junkDropdownMenu" aria-labelledby="junkDropdown">
                                <div class="junkDialogMain" (click)="$event.stopPropagation()">
                                    <!-- <div class="tagDialogHeader" *ngIf="datagrid">
                                        <div class="applyTo">Tag {{datagrid.subPrincipalIds.length}} Selected Items:</div>
                                    </div> -->
    
                                    <ul class="dropdownSubmenu">
                                        <li>
                                            <a (click)="markJunkItems()">
                                                <span class="bi bi-reply-fill flipX"></span>
                                                <a>Send selected items to junk</a>
                                            </a>                                            
                                        </li>                                        
                                        <li>
                                            <a (click)="showJunk()">
                                                <span class="bi bi-eye"></span>
                                                <span>Show junk items</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>   
                        </div>

   
                    </div> 


                    <div class="toolsButton">
                        <div class="panelContainer collapsed">
                            <div class="panelHeading" data-bs-toggle="modal" data-bs-target="#setIndexCategoryModal">
                                <span class="bi bi-file-text filterIcon"></span>
                                <span class="panelHeadingContent">Set Index Category</span>
                            </div>
                        </div>
                    </div>                    
                    <div class="toolsButton">
                        <div class="panelContainer collapsed">
                            <div class="panelHeading" data-bs-toggle="modal" data-bs-target="#generateIndexModal">
                                <span class="bi bi-file-text filterIcon"></span>
                                <span class="panelHeadingContent">Generate Court Book Index</span>
                            </div>
                        </div>
                    </div>

                    <div class="toolsButton" *ngIf="this.generatingExport == false">
                        <div class="panelContainer collapsed">
                            <div class="panelHeading" (click)="exportToExcel()">
                                <span class="bi bi-file-earmark-spreadsheet filterIcon"></span>
                                <span class="panelHeadingContent">Export to Excel</span>
                            </div>
                        </div>
                    </div>
                    <div class="toolsButton" *ngIf="this.generatingExport == true">
                        <div class="panelContainer collapsed">
                            <div class="panelHeading executing">
                                <span class="bi bi-hourglass-split filterIcon"></span>
                                <span class="panelHeadingContent">Generating Export...</span>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="toolsButton">
                        <div class="panelContainer collapsed">
                            <div class="panelHeading" (click)="shareProjectModal.open()">
                                <span class="glyphicon glyphicon-share-alt filterIcon"></span>
                                <span class="panelHeadingContent" >Share Case</span>
                            </div>
                        </div>
                    </div>      -->
                    
                    


                    <!-- <div class="toolsButton">
                        <div class="panelContainer collapsed">
                            <div class="panelHeading" (click)="exportDataModal.open()">
                                <span class="glyphicon glyphicon-export filterIcon"></span>
                                <span class="panelHeadingContent">Export Data</span>
                            </div>
                        </div>
                    </div> -->

                </div>

                
                <div class="toolsSection" *ngIf="this.junkMode == true">
                    <div class="filterSectionHeader junkSectionHeader">
                        <div>
                            <span>Showing junk items</span>
                        </div>
                    </div>



                    <div class="toolsButton">
                        <div class="panelContainer collapsed">
                            <div class="panelHeading" (click)="exitJunkMode()">
                                <span class="bi bi-arrow-left filterIcon"></span>
                                <span class="panelHeadingContent">Go back to main</span>
                            </div>
                        </div>
                    </div>

                    <div class="filterSectionHeader">
                        <div>
                            <span class="ui-section-header">Actions</span>
                        </div>
                    </div>
                    <div class="toolsButton">
                        <div class="panelContainer collapsed">
                            <div class="panelHeading" (click)="restoreJunkItems()">
                                <span class="bi bi-reply-fill flipX"></span>
                                <span class="panelHeadingContent">Restore Selected Items</span>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="span-divider">

                </div>


                
                <div class="filterSectionContainer">
                <div class="filterSectionHeader">
                    <div>
                        <span class="ui-section-header">Filters</span>
                        <button class="btn btn-success apply-filters" (click)="applyFilters()" *ngIf="filtersDirty">Apply</button>
                        <a *ngIf="filtersDirty" class="apply-filters"><span (click)="revertFilters()" [style.marginLeft.px]="5">Cancel</span></a>
                        <a class="clearAll" *ngIf="filtersApplied" (click)="clearFilters()">Clear All</a>
                    </div>
                </div>
                <div class="filterSection">
                    <div class="panelContainer"  id="keywordsPanel">
                        <div class="panelHeading" [ngClass]="{filterActive: appliedFilters.keywords && appliedFilters.keywords.includeKeywords  && (appliedFilters.keywords.includeKeywords.length > 0 ||  appliedFilters.keywords.excludeKeywords.length > 0)}"  data-bs-toggle="collapse" data-bs-target="#keywordsContent" aria-expanded="true">
                            <span class="bi bi-search filterIcon"></span>
                            <span class="panelHeadingContent">Keywords</span>
                            <span class="bi bi-funnel-fill"></span>
                            <span class="openclose"></span>
                        </div>
                        <div id="keywordsContent" class="panel-collapse collapse in show filterPanel">
                            <div class="filterPanelContent">
                                <lrw-filter-keywords (filterChange)="onFilterChange('keywords', $event)" [data]="pendingFilters.keywords">
                                </lrw-filter-keywords>
                            </div>
                        </div>
                    </div>
                    <div class="panelContainer collapsed"  id="datePanel">
                        <div class="panelHeading collapsed"  [ngClass]="{filterActive: appliedFilters.date && (appliedFilters.date.earliest != '' || appliedFilters.date.latest != '')}" data-bs-toggle="collapse" data-bs-target="#dateContent">
                            <span class="bi bi-calendar filterIcon" ></span>
                            <span class="panelHeadingContent">Date</span>
                            <span class="bi bi-funnel-fill"></span>
                            <span class="openclose"></span>
                        </div>
                        <div id="dateContent" class="panel-collapse collapse filterPanel"  (click)="$event.stopPropagation()">
                            <div class="filterPanelContent">
                                <lrw-filter-date (filterChange)="onFilterChange('date', $event)" [data]="pendingFilters.date">
                                </lrw-filter-date>
                            </div>
                        </div>                    
                    </div>
                    <div class="panelContainer collapsed" id="typePanel">
                        <div class="panelHeading collapsed" [ngClass]="{filterActive: appliedFilters.type && appliedFilters.type.length > 0}"  data-bs-toggle="collapse" data-bs-target="#typeContent">
                            <span class="bi bi-image filterIcon"></span>
                            <span class="panelHeadingContent">Type</span>
                            <span class="bi bi-funnel-fill"></span>
                            <span class="openclose"></span>
                        </div>
                        <div id="typeContent" class="panel-collapse collapse filterPanel" (click)="$event.stopPropagation()" [ngClass]="{unapplied: pendingFilters.type != appliedFilters.type}">
                            <div class="filterPanelContent">    
                                <lrw-filter-type (filterChange)="onFilterChange('type', $event)" [data]="pendingFilters.type" [doctypes]="doctypes">
                                </lrw-filter-type>
                            </div>
                        </div>
                    </div>
                    <div class="panelContainer collapsed" id="partiesPanel">
                        <div class="panelHeading collapsed" [ngClass]="{filterActive: appliedFilters.parties?.any.length > 0 || appliedFilters.parties?.to.length > 0 || appliedFilters.parties?.from.length > 0}"  data-bs-toggle="collapse" data-bs-target="#partiesContent">
                            <span class="bi bi-people filterIcon"></span>
                            <span class="panelHeadingContent">Parties</span>
                            <span class="bi bi-funnel-fill"></span>
                            <span class="openclose"></span>
                        </div>
                        <div id="partiesContent" class="panel-collapse collapse filterPanel" (click)="$event.stopPropagation()">
                            <div class="filterPanelContent">    
                                <lrw-filter-parties (filterChange)="onFilterChange('parties', $event)" [data]="pendingFilters.parties">
                                </lrw-filter-parties>
                            </div>
                        </div>
                    </div>
                    <div class="panelContainer collapsed" id="pathPanel">
                        <div class="panelHeading collapsed" [ngClass]="{filterActive: appliedFilters.path && appliedFilters.path.length > 0}"  data-bs-toggle="collapse" data-bs-target="#pathContent" >
                            <span class="bi bi-folder2-open filterIcon"></span>
                            <span class="panelHeadingContent">Path</span>
                            <span class="bi bi-funnel-fill"></span>
                            <span class="openclose"></span>
                        </div>
                        <div id="pathContent" class="panel-collapse collapse filterPanel"  (click)="$event.stopPropagation()">
                            <div class="filterPanelContent">     
                                <lrw-filter-path  [projectId]='projectId' (filterChange)="onFilterChange('path', $event)" [data]="pendingFilters.path">
                                </lrw-filter-path>
                            </div>
                        </div>
                    </div>
                    <div class="panelContainer collapsed"  id="tagsPanel">
                        <div class="panelHeading collapsed"  [ngClass]="{filterActive: appliedFilters.tags && appliedFilters.tags.includeIds && appliedFilters.tags.bannedIds && (appliedFilters.tags.includeIds.length > 0 || appliedFilters.tags.bannedIds.length > 0)}" data-bs-toggle="collapse" data-bs-target="#tagsContent">
                            <span class="bi bi-tags filterIcon"></span>
                            <span class="panelHeadingContent">Tagged</span>
                            <span class="bi bi-funnel-fill"></span>
                            <span class="openclose"></span>
                        </div>
                        <div id="tagsContent" class="panel-collapse collapse filterPanel">
                            <div class="filterPanelContent">    
                                <lrw-filter-tags [tags]="tags" (filterChange)="onFilterChange('tags', $event)" [data]="pendingFilters.tags">
                                </lrw-filter-tags>
                            </div>
                        </div>
                    </div>
                    <div class="panelContainer collapsed" id="historyPanel">
                        <div class="panelHeading collapsed"  data-bs-toggle="collapse" data-bs-target="#historyContent" >
                            <span class="bi bi-clock-history filterIcon"></span>
                            <span class="panelHeadingContent">History</span>
                            <span class="openclose"></span>
                        </div>
                        <div id="historyContent" class="panel-collapse collapse filterPanel"  (click)="$event.stopPropagation()">
                            <div class="filterPanelContent">
                                <ul class="filterHistoryList">
                                    <li *ngFor="let filter of filterHistory.slice(); let i = index" class="filterHistoryEntry" (click)="filterHistoryClick(filter, i)">
                                        <ng-template #filterHistoryTooltip><pre><code>{{filterPrettifier(filter.filter)}}</code></pre></ng-template>
                                        <div [tooltip]="filterHistoryTooltip" containerClass="filterHistoryTooltip"  placement="right">
                                            <div class="filtersModified">
                                                {{filter.filtersModified || 'Blank'}}
                                            </div>
                                            <div class="timeFiltered" *ngIf="i > 0">
                                                {{filter.time | amTimeAgo}}
                                            </div>
                                            <div class="timeFiltered" *ngIf="i == 0">
                                                now
                                            </div>
                                        </div>
                                    </li>
                                    <li *ngIf="filterHistory.length == 0" class="filterHistoryEntry noFilters" >
                                        No recent filters
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style="flex: 1">
                    </div>
                </div>
    <!--
                    
                    <div class="panelContainer collapsed"  id="modifierPanel">
                        <div class="panelHeading"  [ngClass]="{filterActive: false}" data-bs-toggle="collapse" data-bs-target="#modifiersContent">
                            <span class="glyphicon glyphicon-asterisk filterIcon"></span>
                            <span class="panelHeadingContent">Modifiers</span>
                            <span class="glyphicon glyphicon-filter"></span>
                        </div>
                        <div id="modifiersContent" class="panel-collapse collapse filterPanel">
                            <div class="filterPanelContent">    
                                <lrw-filter-modifiers>
                                </lrw-filter-modifiers>
                            </div>
                        </div>
                    </div>
    -->
                    
                </div>
            </as-split-area>
            <as-split-area #datagridSplitArea [size]="60" (click)="datagridFocused" (blur)="datagridBlurred" class="datagridSplitArea white" [ngClass]="{focused: datagridHasFocus, unfocused: !datagridHasFocus}">
                <div *ngIf="projectIsEmpty == true">
                    <div *ngIf="projectIsEmptyButImporting == false">
                        <div class="projectEmptyImportDataMessage">This project has no data in it yet</div>
                        <div class="projectEmptyImportDataButton"  data-bs-toggle="modal" data-bs-target="#importDataModal">
                            <span class="bi bi-box-arrow-in-right filterIcon"></span>
                            <span class="panelHeadingContent">Import Data</span>
                        </div>
                    </div>
                    <div *ngIf="projectIsEmptyButImporting == true">
                        <div class="projectEmptyImportDataMessage">Importing data...</div>
                    </div>
                </div>
                <lrw-datagrid  [hidden]="projectIsEmpty == true"
                              #datagrid
                              (principalChange)="debouncePrincipalChange($event)" 
                              (itemDataUpdated)="handleItemDataUpdate($event)"
                              [projectId]='projectId' 
                              [limit]="queryLimit" 
                              [destinationPage1Base]="1" 
                              [filters]="appliedFilters"
                              [lrwsfilters]="lrwsFilters"
                              [queryIds]="queryIds" 
                              [captureKeyEvents]="datagridHasFocus"
                              [tags]="tags"
                              [displayTimezone]="displayTimezone"
                              [junkInstruction]="junkInstruction"
                              (resized)="onResize($event)"
                              [columns]="dataGridColumns"
                              >
                </lrw-datagrid>
            </as-split-area>
            <as-split-area [size]="25" class="detailPane" #itemViewerSplitArea>
                <lrw-item-viewer
                                 #itemviewer 
                                 [item]='principalItem || {}' 
                                 [projectId]='projectId' 
                                 [tags]="tags"
                                 [itemDataUpdate]="itemDataUpdate"
                                 [keywords]="keywordHighlightString"
                                 [displayTimezone]="displayTimezone"
                                 >
                </lrw-item-viewer>
            </as-split-area>
        </as-split>
    </div>
    
    <div class="papersPage" [ngStyle]="{'display': activeTab == 'papers' ? 'flex' : 'none'}">
    <!--
        <lrw-doceditor *ngIf="serverDetails" [projectId]="projectId" [serverDetails]="serverDetails" [paperId]="'b89c4403-4c9a-4b57-ba39-46b65c011124'" >
        </lrw-doceditor>
    -->
    </div>
    
    <div class="papersListPage" [ngStyle]="{'display': activeTab == 'papersList' ? 'flex' : 'none'}">
        <ul>
            <li *ngFor="let paper of papers">
                {{paper.id}}
            </li>        
        </ul>
    </div>
    
    <div class="modal" #manageTagsModal id="manageTagsModal"  (click)="$event.stopPropagation()">
        <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                  <h1>Manage Tags</h1>
              </div>
              <div class="modal-body">
            <table class="manageTagsTable">
                <colgroup>
                    <col span="1" style="width:80px">
                    <col span="1" style="width:80px">
                    <col span="1" style="width:150px">
                    <col span="1" style="width:100px">
                    <col span="1" style="width:150px">
                </colgroup>
                <thead>
                    <tr>
                        <td>
                        </td>
                        <td>
                            <span>Colour</span>
                        </td>                    
                        <td>
                            <span>Title</span>
                        </td>                    
                        <td>
                            <span>Shortcut<br>Key</span>
                        </td>
                        <td>
                            <span></span>
                        </td>                    
                    </tr>
                </thead>
                <tbody>
                </tbody>
                <tr *ngFor="let tag of tagIds | sortedTags: tags" [ngClass]="{editingRow: editingTag == tag.id}">
                    <td>
                        <a [style.visibility]="editingTag == tag.id ? 'collapse' : 'visible'"><span class="bi bi-pencil" (click)="setEditTagInputs(tag); editingTag = tag.id"></span></a>
                        <a [style.visibility]="editingTag == tag.id ? 'collapse' : 'visible'"><span class="bi bi-trash-fill" (click)="editingTag = ''; deleteTag(tag)" [style.color]="'#b74141'"></span></a>
                    </td>
                    <td>
                        <div *ngIf="editingTag != tag.id"  class="fillColourIndicator" [style.background]="tag.fill_colour" [style.color]="tag.text_colour" [style.margin]="'auto'"></div>
                        
                        
                        
                                        <button *ngIf="editingTag == tag.id"  class="btn btn-default btn-sm notround" type="button"
                                                     [popper]="popper2Content" 
                                                     [popperShowOnStart]="false"
                                                     [popperTrigger]="'click'"
                                                     [popperHideOnClickOutside]="true"
                                                     [popperHideOnScroll]="true"
                                                     [popperPlacement]="'bottom-start'"
                                                     [popperApplyClass]="'colourpickerPopper'"
                                                    ><div class="fillColourIndicator" [style.background]="editTagInputs.fill_colour" [style.color]="editTagInputs.text_colour"></div>
                                        </button>
                                        <popper-content #popper2Content>
                                            <lrw-colourpicker [(fillColour)]="editTagInputs.fill_colour" [(textColour)]="editTagInputs.text_colour" (close)="popper2Content.hide()"></lrw-colourpicker>
                                        </popper-content>                                        
                        
                        
                        
                    </td>                
                    <td>
                        <span *ngIf="editingTag != tag.id" >{{tag.title}}</span>
                        <input *ngIf="editingTag == tag.id" type="text" class="form-control input-sm tagNameInput" [(ngModel)]="editTagInputs.title" (keyup.enter)="addTagInputs.title.length > 0 && addTag(addTagInputs.title)" (input)="tagInputChange()">
                    </td>                
                    <td>
                        <span *ngIf="editingTag != tag.id" class="tagShortcut" >{{tag.shortcut}}</span>
                        <input *ngIf="editingTag == tag.id" type="text" class="form-control input-sm shortcutInput" [(ngModel)]="editTagInputs.shortcut" (keyup.enter)="addTagInputs.title.length > 0 && addTag(addTagInputs.title)" maxlength="1" (input)="tagShortcutInputChange($event)" [style.width.px]="40" [style.margin]="'auto'">
                    </td>
                    <td>
                        <button [style.visibility]="editingTag != tag.id ? 'collapse' : 'visible'"   class="btn btn-default btn-sm notround" type="button" [ngClass]="{'btn-success': 1}"  [disabled]="false" (click)="saveTagChanges(tag.id, editTagInputs); editingTag=''">Save</button>
                        <a [style.visibility]="editingTag != tag.id ? 'collapse' : 'visible'" [style.marginLeft.px]='5'><span (click)="editingTag=''">Cancel</span></a>
                    </td>
                </tr>
            </table>        
        </div>
        <div class="modal-footer">
            <a class="apply-filters"><span [style.marginLeft.px]="5" data-bs-dismiss="modal">Close</span></a>
        </div>
    </div>    
    </div>
    </div>
    
    
    
    <div class="modal" #generateIndexModal id="generateIndexModal" (click)="$event.stopPropagation()"  aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h1>Generate Index</h1>
                </div>
                <div class="modal-body">
                <!-- <h4 class="newIndexItemCount" *ngIf="datagrid">
                    {{datagrid.subPrincipalIds.length}} selected items
                </h4> -->
                <h4>
                    <span>Name of new index: </span><span><input class="form-control section-input inset-input" type="text" placeholder="e.g. Relevant docs for custodian" [(ngModel)]="newIndexName" id="newIndexInput"></span>
                </h4>
                <label><input type="checkbox" class="item-checkbox" [(ngModel)]="useIndexCategories" /> Use Index Categories</label>
            </div>
                <div class="modal-footer">
                    <button class="btn btn-success apply-filters" (click)="generateNewPaper()"   data-bs-dismiss="modal" >Generate</button>
                    <a class="apply-filters"><span  data-bs-dismiss="modal"  [style.marginLeft.px]="5">Cancel</span></a>
                </div>
                
            </div>
        </div>  
    </div>


    <div class="modal" #setCategoryModal id="setIndexCategoryModal" (click)="$event.stopPropagation()"  aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h1>Set Index Category</h1>
                </div>
                <div class="modal-body">
                <!-- <h4 class="newIndexItemCount" *ngIf="datagrid">
                    {{datagrid.subPrincipalIds.length}} selected items
                </h4> -->
                <h4>
                    <span>Category: </span><span><input class="form-control section-input inset-input" type="text" placeholder="e.g. 1. Client Documents" [(ngModel)]="setIndexCategoryName" id="setIndexCategoryInput"></span>
                </h4>        
                
            </div>
                <div class="modal-footer">
                    <button class="btn btn-success apply-filters" (click)="setIndexCategory()"   data-bs-dismiss="modal" >Ok</button>
                    <a class="apply-filters"><span  data-bs-dismiss="modal"  [style.marginLeft.px]="5">Cancel</span></a>
                </div>
            </div>
        </div>  
    </div>
    

    <div id="importDataModal" #importDataModal (click)="$event.stopPropagation()" class="modal importModal" aria-hidden="true">
        <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
                <div class="modal-header">
                    <h1>Import Data</h1>
                </div>
                <div class="modal-body">
                    <ngx-file-drop dropZoneLabel="Drag and drop files here" (onFileDrop)="fileover=false; calculatingDroppedFiles=true; droppedLooseFiles($event)" class="importDropZone" directory="true" (onFileOver)="fileover=true" (onFileLeave)="fileover=false" [ngClass]="{fileover: fileover}">
                        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                            <div class="importFields">
                                <div>
                                    <span>Import Name: </span><span><input class="form-control section-input inset-input" type="text" placeholder="e.g. Data from John Doe" [(ngModel)]="importName" id="importNameInput" defaultValue="Import {{nextImportName}}"></span>
                                </div>        
                                <div>
                                    <span>First Bates Id: </span><span><input class="form-control section-input inset-input" type="text" placeholder="e.g. DOC.0001.0001.0001" [(ngModel)]="firstBates" id="firstBatesInput" defaultValue="{{nextStartingBates}}"></span>
                                </div>      
                                <div class="importStages">
                                    <ul>
                                    <li>Un-Nest Documents</li>
                                    <li>Assign Bates IDs</li>
                                    <li>OCR</li>
                                    <li>Extract Metadata</li>
                                    <li>Remove Junk Items (Containers & System Files)</li>
                                    <li>Generate PDF Previews</li>
                                    <li>Remove Duplicates</li>
                                    </ul>
                                </div>        
                            </div>  
                                

                                <div class="uploadIcon">
                                    <div *ngIf="calculatingDroppedFiles"  class="uploadIcon calculatingDroppedFiles">Calculating dropped files...</div>
                                    <div *ngIf="!calculatingDroppedFiles" class="uploadIcon">
                                        <span class="vertpos">&nbsp;</span>
                                        <span class="bi bi-box-arrow-in-down droplogo"></span>
                                        <span>Drop Files Here</span>
                                        <span>or</span>
                                        <button type="button" (click)="openFileSelector()">Browse Files</button>
                                        <span class="vertpos">&nbsp;</span>
                                    </div>
                                </div>
                                
                                <div class="filesFlex" [ngStyle]="{'flex': droppedFiles.length == 0 ? 'none' : '1'}">
                                    <div class="filesTableContainer" [ngStyle]="{'flex': droppedFiles.length == 0 ? 'none' : '1'}">
                                        <table class="filesTable">
                                            <tr *ngFor="let item of droppedFiles; let i=index" class="filesEntry">
                                                <td><i class="bi bi-file-earmark"></i>{{ item.relativePath }}</td>
                                                <td>{{ item.sizeHumanReadable }}</td>
                                            </tr >
                                        </table>
                                    </div>
                                    <div class="droppedFilesSummary" *ngIf="droppedFiles.length > 0">{{droppedFiles.length | number}} files {{droppedFilesSizeHumanReadable}}</div>
                                </div>


        
                        </ng-template>
                    </ngx-file-drop>


                    <!-- <table class="uploaderTable">
                        <tr>
                            <td  class="importFields">
                                <div>
                                    <span>Import Name: </span><span><input class="form-control section-input" type="text" placeholder="e.g. Data from John Doe" [(ngModel)]="importName" id="importNameInput" defaultValue="Import {{nextStartingBates}}"></span>
                                </div>        
                                <div>
                                    <span>First Bates Id: </span><span><input class="form-control section-input" type="text" placeholder="e.g. DOC.0001.0001.0001" [(ngModel)]="firstBates" id="firstBatesInput" defaultValue="{{nextStartingBates}}"></span>
                                </div>           
                            </td>
                            <td>
                                {{projectId}}
                                <my-uploader class="myUploader" #uploaderElement [visible]="uploaderElementVisible" [projectId]="projectId"></my-uploader>
                            </td>
                        </tr>
                    </table> -->


                </div>
                <div class="modal-footer">
                    <button class="btn btn-success apply-filters" [ngClass]="{'disabled': droppedFiles.length <= 0}"  (click)="importFiles(importName, firstBates, droppedFiles) ;" data-bs-dismiss="modal">Import</button>
                    <a class="apply-filters"><span  [style.marginLeft.px]="5" data-bs-dismiss="modal">Cancel</span></a>

                </div>
            </div>
        </div>
    </div>


    <div class="modal" #shareProjectModal (click)="$event.stopPropagation()">
        <div class="modal-header">
            <h1><span class="bi bi-share"></span>&nbsp;Share Case</h1>
        </div>
        <div class="modal-content">
        <h4>
            <span>Share case with (email address): </span><span><input class="form-control section-input" type="text" placeholder="e.g. Relevant docs for custodian" [(ngModel)]="shareProjectEmail" id="shareEmailInput"></span>
        </h4>        
        <h4 style="visibility: collapse">
            <span>Message (optional): </span><span><input class="form-control section-input" type="text" placeholder="e.g. Relevant docs for custodian" [(ngModel)]="shareProjectMessage" id="shareEmailMessage"></span>
        </h4>        
            



    </div>
        <div class="modal-footer">
            <button class="btn btn-success apply-filters" (click)="shareProject(); shareProjectModal.close(); linkSharedModal.open();" >Share</button>
            <a class="apply-filters"><span (click)="shareProjectModal.close()" [style.marginLeft.px]="5">Cancel</span></a>
        </div>
    </div>
    <div class="modal" #linkSharedModal (click)="$event.stopPropagation()">
        <div class="modal-header">
            <h1><span class="bi bi-send"></span>&nbsp;Message Sent!</h1>
        </div>
        <div class="modal-content">
        <h4>
            <span>Case has been shared with {{shareProjectEmail}}</span>
        </h4>        
            
    </div>
        <div class="modal-footer">
            <a class="apply-filters"><span (click)="linkSharedModal.close()" [style.marginLeft.px]="5">Close</span></a>
        </div>
    </div>
