import { Component, Input, Output, EventEmitter, Injectable, Pipe, OnInit, ViewChild } from '@angular/core';
import { Http } from '@angular/http';
import { ProjectService } from "../../../services/project.service";
//import 'rxjs/Rx';
import { TreeComponent, TreeModel, TreeNode} from '@circlon/angular-tree-component';
import { map } from 'rxjs/operators';

@Component({
    selector: 'lrw-filter-path',
    templateUrl: './filter.path.component.html',
    styleUrls: ['./filter.path.component.css']
})


export class FilterPathComponent implements OnInit { 

    @ViewChild('tree', { static: true }) treeComponent: TreeComponent;
    copyArray(source, dest) //copy contents of doctyles list to checkedTypes, keep reference intact
    {
        dest.length = 0;
        [].push.apply(dest, source);
    }
    
    get treeSelectedItems():any {
//        if(this._querySelectedItems.length == 0)
//        {
//            return this.getRootNodeIds();
//        }
//        else
//        {
            return this._querySelectedItems
//        }
    }
    
    @Input('data') set querySelectedItems(value: any) {
        this._querySelectedItems = value;
        this.updateUIFromData();
    }

    @Output() data:any =  function() {
        return this._querySelectedItems;
    }
    
    @Output() filterChange: EventEmitter<any> = new EventEmitter();
    
    @Input() public projectId: string;
    rootNodes: any = [];
    _querySelectedItems: any = [];
    treeNodeData = [];
    Object = Object;
    options = {
        getChildren: (node:TreeNode) => {
            return this.getChildrenFromServer(node.data.guid, node.data.checked)
        }
    }
    
    constructor(private projectService: ProjectService)
    {
    }

    ngOnInit() {
        //TODO get the root nodes here and set up the data structure so that angular-tree-component knows there are children, and, when clicking to expand the root nodes, fetches the children
        this.populateRootNodesFromServer();
    }
    
    getRootNodeIds() {
        var ret = [];
        for(var i = 0; i < this.rootNodes.length; i++)
        {
            ret.push(this.rootNodes[i].id);
        }
        return ret;
    }
    
    checkRootNodes() {
            console.log("Getting the roots");
            var roots = this.treeComponent.treeModel.roots;
            console.log("Here are the roots");
            console.log(roots);
            if(roots)
            {
                for(var i = 0; i < roots.length; i++)
                {
                    console.log("Checking a root node");
                    this.checkNoGeneratePaths(roots[i], true);
                }
            }
    }
    
    getChildrenFromServer(parentId, checked)
    {
        console.log("ABOUT TO GET CHILDREN")
        return this.projectService.getChildNodes(this.projectId, parentId).toPromise().then(
            res => {
                console.log("IN THE GET CHILDREN MAP FUNC")
                var children = new Array(res.length);
                console.log(children);
                for(var i = 0; i < res.length; i++)
                {
                    children[i] = {};
                    children[i].title = res[i].title;
                    children[i].type = res[i].type;
                    children[i].guid = res[i].id;
                    children[i].checked = checked;
                    children[i].hasChildren = res[i].has_children;
                }
                console.log(children);
                return children;
            }
        );
    }
    
    
    populateRootNodesFromServer() {
        if(this.projectId)
        {
            this.projectService.getRootNodes(this.projectId).subscribe(
                res => {
                    this.copyArray([], this.treeSelectedItems);
                    this.treeNodeData = new Array(res.length);
                    this.rootNodes = [];
                    for(var i = 0; i < res.length; i++)
                    {
                        this.treeNodeData[i] = {};
                        this.treeNodeData[i].title = res[i].title;
                        this.treeNodeData[i].type = res[i].type;
                        this.treeNodeData[i].guid = res[i].id;
                        this.treeNodeData[i].checked = true;
                        this.treeNodeData[i].hasChildren = res[i].has_children;
                        this.rootNodes.push(res[i].id);
                    }
                    console.log("Calling the function to check the roots");
                    this.checkRootNodes();
                },
                err => {
                    console.log(err);
                }                
            )
        }
    }
    
    getNodesFromSelectedItems(items)
    {
        if(this.projectId)
        {
            this.projectService.getNodesFromSelectedItems(this.projectId, items).subscribe(
                res => {
                    
                    this.treeNodeData = res;

                    for(var i = 0; i < items.length; i++)
                    {
                        console.log("iterating " + items[i]);
                        var node = this.treeComponent.treeModel.getNodeById(items[i])                        
                        if(node)
                        {
                            console.log("Checking the node! " + items[i]);
                            this.check(node, false);
                            this.check(node, true);
                        }
                        else
                        {
                            console.log("didnt find node " + items[i]);
                        }
                    }
                }
            ),
            err => {
                console.log(err);
            }
        }
    }
    
    flatToHierarchy(flat)
    {
        var roots = []; //things without parent
        
        var all = {}
        
        Object.keys(flat).forEach(function(guid) {
            all[guid] = flat[guid];
        })
        
        // connect children to its parent, and split roots apart
        Object.keys(all).forEach(function (guid) {
            var item = all[guid];
            item.name = item['summary/title'];
            item.type = item['summary/type'];
            item.isChecked = true;
            if(item['application/parent_id'].length < 1) {
                roots.push(item);
            }
            else if (item['application/parent_id'] in all) {
                var p = all[item['application/parent_id']];
                if(!('children' in p)) {
                    p.children = [];
                }
                p.children.push(item);
            }
        })
        
        return roots;
    }

  public checkNoGeneratePaths(node, checked) {
    this.updateChildNodeCheckbox(node, checked);
    this.updateParentNodeCheckbox(node.realParent);
      if(this.treeSelectedItems.sort() == this.getRootNodeIds().sort())
      {
            this.copyArray([], this._querySelectedItems );
      }
  }
    
  public check(node, checked) {
      console.log("updating checkbox to " + checked);
    this.updateChildNodeCheckbox(node, checked);
    this.updateParentNodeCheckbox(node.realParent);
    this.generatetreeSelectedItems();
      if(this.treeSelectedItems.sort() == this.getRootNodeIds().sort())
      {
            this.copyArray([], this._querySelectedItems );
      }      
  }
    
    uncheckAll()
    {
        for(var i = 0; i < this.treeNodeData.length; i++)
        {
            var node = this.treeNodeData[i];
            node.checked = false;
            node.indeterminate = false;
            if (node.children) {
                node.children.forEach((child) => this.uncheckAllRecurse(child));
            }
        }
    }
    
  public uncheckAllRecurse(node) {
    node.checked = false;
    node.indeterminate = false;
    if (node.children) {
      node.children.forEach((child) => this.uncheckAllRecurse(child));
    }
  }

  public updateChildNodeCheckbox(node, checked) {
    node.data.checked = checked;
    node.data.indeterminate = checked ? false : node.data.intermediate;
    if (node.children) {
      node.children.forEach((child) => this.updateChildNodeCheckbox(child, checked));
    }
  }
    
  public updateParentNodeCheckbox(node) {
    if (!node) {
      return;
    }

    let allChildrenChecked = true;
    let noChildChecked = true;

    for (const child of node.children) {
      if (!child.data.checked || child.data.indeterminate) {
        allChildrenChecked = false;
      }
      if (child.data.checked || child.data.indeterminate) {
        noChildChecked = false;
      }
    }

    if (allChildrenChecked) {
      node.data.checked = true;
      node.data.indeterminate = false;
    } else if (noChildChecked) {
      node.data.checked = false;
      node.data.indeterminate = false;
    } else {
      node.data.checked = false;
      node.data.indeterminate = true;
    }
    this.updateParentNodeCheckbox(node.parent);
  }

    public generatetreeSelectedItems()
    {
        this.copyArray([], this.treeSelectedItems);
        for(var i = 0; i < this.treeNodeData.length; i++)
        {
            this.generatetreeSelectedItemsRecursor(this.treeNodeData[i], this.treeSelectedItems);
        }
        
        if(this.treeSelectedItems.sort() == this.rootNodes.sort())
        {
            this.copyArray([], this.treeSelectedItems);
        }

        
        if(this.treeSelectedItems.sort().toString() == this.rootNodes.sort().toString()) //If this is the default (roots selected), clean treeSelectedItems
        {
            console.log("Emptying treeSelectedItems");
            this.copyArray([], this.treeSelectedItems);
        }
        else if(this.treeSelectedItems.length == 0) //nothing selected, add a 'none' entry
        {
            this.treeSelectedItems.push("none");
        }
        
        this.filterChange.emit(this.treeSelectedItems);
    }

    public generatetreeSelectedItemsRecursor(node, treeSelectedItems)
    {
        if(node.checked)
        {
            treeSelectedItems.push(node.guid);
        }
        else if(node.indeterminate)
        {
            for(const child of node.children)
            {
                this.generatetreeSelectedItemsRecursor(child, treeSelectedItems);
            }
        }
    }

    public updateUIFromData()
    {
        console.log("About to check the selected items");
        console.log(this.treeSelectedItems.length);

        this.uncheckAll();
        
        if(this.treeSelectedItems && this.treeSelectedItems.length > 0)
        {
            console.log("About to loop through the items")
            for(var i = 0; i < this.treeSelectedItems.length; i++)
            {
                console.log("Trying to check a node");
                var guid = this.treeSelectedItems[i];
                console.log(guid)
                var node = this.treeComponent.treeModel.getNodeBy(function(node) {
                    return node.data.guid == guid
                });
                if(node)
                {
                    console.log("Found node " + guid);
                    this.checkNoGeneratePaths(node, true);
                    console.log(this.treeSelectedItems.length);
                }
                else
                {
                    console.log("Didnt find node " + guid);
                }
            }
        }
        else
        {
            this.checkRootNodes();
        }
    }
    
//    public removeNoneEntry() {
//        if(this.treeSelectedItems.indexOf("none") != -1) //Clear out the "none" entry of the query
//        {
//            this.treeSelectedItems.splice(this.treeSelectedItems.indexOf("none"), 1) ;
//        }
//    }
}