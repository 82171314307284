import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sortedTags',
    pure: false
})
export class SortedTagsPipe implements PipeTransform {
    transform(tagIds, tags): any {
        var ret = [];
        if(!tagIds || !tags)
        {
            return ret;
        }
        
        for(var i = 0; i < tagIds.length; i++)
        {
            if(tags.hasOwnProperty(tagIds[i]))
            {
                ret.push(tags[tagIds[i]]);
            }
        }
        ret.sort(function(a,b){
            return a.title > b.title ? 1 : -1;
        })
        return ret;
    }
}