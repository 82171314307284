import { Component, AfterViewInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../../../auth.service';
import { UserService } from '../../services/user.service';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'new-user-form',
    templateUrl: './user.new.component.html',
    styleUrls: ['./user.new.component.css']
})

export class NewUserFormComponent implements AfterViewInit {
    errorMsg: string;
    hasError: boolean;
    componentForm: any;
    email: string;
    invitationcode: string = "";
    signupRestriction: any;
    submitted:boolean = false;
    @ViewChild('autofocus', { static: true }) autofocus: ElementRef;
    constructor(public authService: AuthService, private router: Router, private route: ActivatedRoute, public userService: UserService, private renderer: Renderer2) {
        
    }

    ngAfterViewInit() {
        //this.renderer.invokeElementMethod(this.autofocus.nativeElement, 'focus');
        this.autofocus.nativeElement.focus();

        this.userService.getSignupRestrictionSetting().subscribe(setting =>
            {
                this.signupRestriction = setting;
            });
    }
    async onSubmit(form: any) {
        console.log("in on submit")
        this.submitted = true;
        this.userService.newUser(form.email, form.invitationcode).subscribe();
        console.log("We've sent the new user request");
    }
}