<div class="accounteditcomponent">
    <div class="link-light">
        <a  href="/">⬅ Back to Dashboard </a>
    </div>
    <div class="nameDeleteWrapper">
        <h1 class="heading-light">{{account.name}}</h1>
        <div class="deleteAccount link-light"><a data-bs-toggle="modal" data-bs-target="#deleteAccountModal">Delete Workspace</a></div>
    </div>
    <h2 class="heading-light">Workspace Administrators</h2>
    <table class="table account-table-style table-striped">
        <thead>
            <tr>
                <td>Email</td>
                <td>Role</td>
                <td>Remove</td>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let user of users">
                <td>{{user.email}}</td>
                <td>{{user.role_name}}<button class="btn btn-primary btn-xs" (click)="changeAccountUserRoleUser = user; changeAccountUserRoleSelectedRoleId = user.role_id; loggo(changeAccountUserRoleSelectedRoleId); changeAccountUserRoleModal.open();" *ngIf="current_user.user_id != user.id" style="float: right">Change Role</button></td>
                <td><button class="btn btn-danger btn-xs" (click)="removeUser(user);" *ngIf="current_user.user_id != user.id">Remove User</button></td>
            </tr>
            <tr>
                <td colspan="3"><a class="link-primary" data-bs-toggle="modal" data-bs-target="#newAccountUserModal">+ Add New User</a></td>
            </tr>
        </tbody>
    </table>
    <h2 class="heading-light">Projects</h2>
    <table class="table account-table-style table-striped">
        <thead>
            <tr>
                <td>Name</td>
                <td>Created</td>
                <td>Time Zone</td>
                <td>Users</td>
                <td>Delete</td>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let project of projects" class="projectsList">
                <td>
                        <div class="projectEntryContainer">
                        <div class="projectImageContainerContainer">
                            <div class="projectImageContainer">
                                    <div class="projectImage" [style.background-image]="project.backgroundImage">
                                    </div>
                            </div>
                        </div>
                      
                    <a href="accounts/{{account.id}}/projects/{{project.id}}">{{project.name}}</a>
                    </div>
                </td>
                <td>{{project.created | date: 'yyyy-MM-dd'}}</td>
                <td>{{project.timezone}}</td>
                <td>
                    <div class="section-chips">
                        <div class="chip" *ngFor="let user of project.users">
                            <span class="chipText">{{user.email}}</span>
                            <div class="bi bi-x" (click)="removeUserFromProject(project, user)"></div>
                        </div>
                        <div>
                            <a class="link-primary" (click)="addUserProjectId = project.id; addUserToProjectName = project.name" data-bs-toggle="modal" data-bs-target="#newProjectUserModal">
                                + Add new user
                            </a>
                        </div>
                    </div>   
                </td>
                <td>
                    <button class="btn btn-danger btn-xs button-shadow" (click)="deleteProject(project);">Delete Project</button>
                </td>
            </tr>
        </tbody>
    </table>
</div>



<div class="modal" #newProjectUserModal id="newProjectUserModal" (click)="$event.stopPropagation()" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">    
            <div class="modal-header">
                <h1>Add user to project: {{addUserToProjectName}}</h1>
            </div>
            <div class="modal-body">
        
            <div class="inputControl">
                <span>User's Email Address: </span>
                <span>
                <input class="form-control section-input inset-input" type="text" placeholder="e.g. john.doe@email.com" [(ngModel)]="newUserEmail" id="newUserInput" defaultValue="">
                </span>
            </div>        
        
            </div>
            <div class="modal-footer">
                <button class="btn btn-success apply-filters button-shadow" (click)="addUserToProject(addUserProjectId, newUserEmail); newUserEmail = '';" data-bs-dismiss="modal" >Add</button>
                <a class="apply-filters"><span data-bs-dismiss="modal" [style.marginLeft.px]="5">Cancel</span></a>
            </div>
        </div>
    </div>
</div>





  <div class="modal" #changeAccountUserRoleModal (click)="$event.stopPropagation()">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h2>Change user role</h2>
                <h1>{{changeAccountUserRoleUser.email}}</h1>
            </div>
            <div class="modal-body">
        
            <div class="inputControl">
                <span>Select Role: </span>
                <span>
                    <select class="form-control inset-input" id="role" (change)="changeAccountUserRoleSelectChangeHandler($event)" [(ngModel)]="changeAccountUserRoleSelectedRoleId">
                        <option *ngFor="let role of accountRoles" value="{{role.id}}">{{role.name}}</option>
                    </select>
                </span>
            </div>
        
            </div>
            <div class="modal-footer">
                <button class="btn btn-success apply-filters" (click)="changeAccountUserRoleModal.close(); changeAccountUserRole(account.id, changeAccountUserRoleUser.id, changeAccountUserRoleSelectedRoleId);">Apply</button>
                <a class="apply-filters"><span (click)="changeAccountUserRoleModal.close()" [style.marginLeft.px]="5">Cancel</span></a>
            </div>
        </div>
    </div>
</div>



  <div class="modal" #newAccountUserModal id="newAccountUserModal" (click)="$event.stopPropagation()" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h1>Add user to account: {{addUserToProjectName}}</h1>
            </div>
            <div class="modal-body">
        
            <div class="inputControl">
                <span>User's Email Address: </span>
                <span>
                <input class="form-control section-input inset-input" type="text" placeholder="e.g. john.doe@email.com" [(ngModel)]="newUserEmail" id="newUserInput" defaultValue="">
                </span>
            </div>   

            <div class="inputControl">
                <span>Role: </span>
                <span>
                    <select class="form-control inset-input" id="role" (change)="addUserRoleSelectChangeHandler($event)">
                        <option *ngFor="let role of accountRoles" value="{{role.id}}">{{role.name}}</option>
                    </select>
                </span>
            </div>      
            </div>
            <div class="modal-footer">
                <button class="btn btn-success apply-filters button-shadow" (click)="addUserToAccount(account.id, newUserEmail, addUserSelectedRoleId); newUserEmail = ''" data-bs-dismiss="modal">Add</button>
                <a class="apply-filters"><span data-bs-dismiss="modal" [style.marginLeft.px]="5">Cancel</span></a>
            </div>
        </div>
    </div>
</div>


  <div class="modal" #deleteAccountModal id="deleteAccountModal" (click)="$event.stopPropagation()" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h1><span class="glyphicon glyphicon-warning-sign"></span>Delete Workspace: {{account.name}}</h1>
            </div>
            <div class="modal-body">
        
            <div class="deleteAccountMessage">
                <h2>Are you sure you want to delete this workspace?</h2>
                <ul>
                    <li>All projects in this workspace will be permanently deleted</li>
                    <li>All workspace data will be permanently deleted</li>
                    <li>This action cannot be undone</li>
                </ul>
            </div>
            <div class="inputControl">
                <span>Type the exact name of the workspace to confirm:</span>
                <span>
                <input class="form-control section-input inset-input" type="text" placeholder="Type the name of the workspace here" [(ngModel)]="deleteAccountConfirmation" defaultValue="">
                </span>
            </div>   

            </div>
            <div class="modal-footer">
                <button *ngIf="deleteAccountConfirmation==account.name" class="btn btn-danger apply-filters" (click)="deleteAccount(account.id); deleteAccountConfirmation=''" data-bs-dismiss="modal" >Delete Workspace</button>
                <a class="apply-filters"><span data-bs-dismiss="modal" [style.marginLeft.px]="5">Cancel</span></a>
            </div>
        </div>
    </div> 
  </div>
        