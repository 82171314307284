import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { Account } from '../../model/account';
import { User } from '../../../user/model/user';
import { Role } from '../../../role/model/role';
import { Project } from '../../../project/model/project';
import { AccountService } from '../../services/account.service';
import { ProjectService } from '../../../project/services/project.service';
import { AuthService } from '../../../auth.service';
import { RoleService } from '../../../role/services/role.service';
import { EmitterService } from '../../../emitter.service';
import { SocketService } from '../../../socket.service';
import { ValidationManager } from "ng2-validation-manager";
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
var GeoPattern = require('geopattern');

@Component({
  selector: 'edit-account',
  templateUrl: './account.edit.component.html',
  styleUrls: ['./account.edit.component.css'],
    providers: [
        SocketService
    ]
})

export class AccountEditComponent implements OnInit {
    account = new Account('', null);
    users: Array<User>;
    projects: Array<Project>;
    roles: Array<Role>;
    accountRoles: Array<Role>;
    account_id: string;
    sub: any;
    current_user: any;
    newProjectName: any;
    newProjectTimezone: any;
    newUserEmail: any;
    newProjectUsers: any;
    socket: any;

    addUserToProjectName: any;
    addUserProjectId: any;

    deleteAccountConfirmation: any;
    
    addUserForm;
    createProjectForm;

    addUserSelectedRoleId;
    changeAccountUserRoleSelectedRoleId;
    changeAccountUserRoleUser = {
        id: null,
        email: null
    };
    
    constructor(private accountService: AccountService, private projectService: ProjectService, private roleService: RoleService, private route: ActivatedRoute, private socketService: SocketService, private authService: AuthService, private _sanitizer: DomSanitizer,  private router: Router) { 
        
        this.current_user = this.authService.getCurrentUser();
        this.socket = this.socketService.getSocket();


        var self = this;
        self.socket.on('connect', function() {
            console.log("Socket Connected!");

            self.socket.emit('room', self.account);
            if(self.projects)
            {
                for(let i = 0; i < self.projects.length; i++)
                {
                    self.socket.emit('room', self.projects[i].id);
                }
            }
        });
        this.socket.connect();


        this.socket.on('account.user.added', (data) => { console.log("user added"); this.loadUsers();  });
        this.socket.on('account.user.removed', (data) => { console.log("user removed"); this.loadUsers(); });
        this.socket.on('account.user.role_updated', (data) => {  
            console.log("USER ROLE UPDATED")    
            this.loadUsers(); 
        });
        this.socket.on('project.added', (data) => { this.loadProjects(); });
        this.socket.on('project.removed', (data) => { this.loadProjects(); });

        this.socket.on('project.user.added', (data) => { this.loadProjects();  });
        this.socket.on('project.user.removed', (data) => { this.loadProjects(); });

        this.addUserForm = new ValidationManager({
            user: 'required',
            role: 'required'
        })        
        
        this.createProjectForm = new ValidationManager({
            name: 'required'
        })        
        
        this.sub = this.route.params.subscribe(params => {
            this.account_id = params['id'];
            console.log("Going to try to load the account");
            this.loadAccount();
        })

        this.socket.emit('room', this.account_id);
        console.log("Joining room " + this.account_id);
        this.socket.on('project.deleted', (data) => {
            this.loadProjects();
        });


        console.log("AccountEditComponentConstructor");
    }
    
    ngOnInit() {
        console.log("AccountListComponent OnInit");
    }
    
    loadAccount() {
        this.accountService.get(this.account_id).subscribe(
            account => {
                this.account = account;
                this.socket.emit('room', this.account);
                
                this.accountService.getUsers(this.account_id).subscribe(
                    data => {
                        this.users = data;
                        this.users.sort((a, b) => a.email < b.email ? -1 : (a.email > b.email ? 1 : 0))

                        this.roleService.list().subscribe(
                            data => {
                                console.log("Here is the data from the roleService list function");
                                console.log(data);
                                this.roles = data;
                                this.accountRoles = data.filter(role => role.name.startsWith("account") )
                                this.addUserSelectedRoleId = this.accountRoles[0].id;
                            },
                            err => {
                                console.log(err);
                            }
                        )
                    },
                    err => {
                        console.log(err);
                    }
                )

                this.accountService.getProjects(this.account_id).subscribe(
                    data => {
                        console.log("Here is the projects data");
                        console.log(data);
                        this.projects = data;
                        this.projects = this.projects.sort((a, b) => {return a.name.localeCompare(b.name)});

                        for(var i = 0; i < this.projects.length; i++)
                        {
                            this.socket.emit('room', this.projects[i].id);
                            this.projects[i].backgroundImage = this._sanitizer.bypassSecurityTrustStyle(GeoPattern.generate(this.projects[i].id).toDataUrl());
                        }
        
                    },
                    err => {
                        console.log(err);
                    }
                )
                
                console.log("Just set the account");
                console.log(this.account);
            },
            err => {
                console.log(err);
            }
        );
    }
    
    loadUsers() {
        this.accountService.getUsers(this.account_id).subscribe(
            data => {
                this.users = data;
                this.users.sort((a, b) => a.email < b.email ? -1 : (a.email > b.email ? 1 : 0))
            },
            err => {
                console.log(err);
            }
        );
    }

    loadProjects() {
        console.log("In Load Projects");
        this.projectService.listAccountProjects(this.account_id).subscribe(
            data => {
                console.log("Load Projects - Data Part");
                this.projects = data;
                this.projects = this.projects.sort((a, b) => {return a.name.localeCompare(b.name)});

                for(var i = 0; i < this.projects.length; i++)
                {
                    console.log("Joining Project Room: " + this.projects[i].id)
                    this.socket.emit('room', this.projects[i].id);
                    this.projects[i].backgroundImage = this._sanitizer.bypassSecurityTrustStyle(GeoPattern.generate(this.projects[i].id).toDataUrl());
                }
            },
            err => {
                console.log(err);
            }
        );
    }

    onSubmitAddUser()
    {
        console.log("OnSubmitAddUser");
        if(this.addUserForm.isValid()) {
            console.log("The form fields");
            console.log(this.addUserForm.formControls.user.value);
            console.log(this.addUserForm.formControls.role.value);

            this.accountService.addUser(this.account_id, this.addUserForm.formControls.user.value, this.addUserForm.formControls.role.value);
            this.addUserForm.reset();
        }
        else
        {
            console.log("form errors");
            console.log(this.addUserForm.getErrors());
        }
    }
    
    onSubmitCreateProject()
    {
        console.log("OnSubmitCreateProject");
            this.projectService.new(this.account_id, this.newProjectName, this.newProjectTimezone, this.newProjectUsers);

    }

    clearFormAddUser()
    {
        this.addUserForm.reset();
    }

    removeUser(user)
    {
        if(confirm("Are you sure you want to remove user " + user.email + "?"))
        {
            this.accountService.removeUserFromAccount(this.account_id, user.id);
        }
    }

    removeUserFromProject(project, user)
    {
        if(confirm("Are you sure you want to remove user     " + user.email + "     from project " + project.name + "?"))
        {
            this.projectService.removeUser(project.id, user);
        }
    }    

    deleteProject(project)
    {
        if(confirm("Are you sure you want to delete project " + project.name + "?"))
        {
            this.accountService.deleteProject(project.account_id, project.id);
        }
    }

    getProjectUserUUID()
    {
        for(var i = 0; i < this.roles.length; i++)
        {
            if(this.roles[i].name == 'project.user')
            {
                return this.roles[i].id;
            }
        }
        return null;
    }
    addUserToProject(projectId, user_email)
    {
        this.projectService.addUser(projectId, this.newUserEmail, this.getProjectUserUUID());
    }


    addUserToAccount(account_id, newUserEmail, newUserRoleId)
    {
        console.log("Adding user " + newUserEmail + " with role " + newUserRoleId + " to account " + account_id)
        this.accountService.addUser(account_id, newUserEmail, newUserRoleId);
    }

    onSubmitAddUserToAccount()
    {
        console.log("OnSubmitAddUser");
        if(this.addUserForm.isValid()) {
            console.log("The form fields");
            console.log(this.addUserForm.formControls.user.value);
            console.log(this.addUserForm.formControls.role.value);

            this.accountService.addUser(this.account_id, this.addUserForm.formControls.user.value, this.addUserForm.formControls.role.value);
            this.addUserForm.reset();
        }
        else
        {
            console.log("form errors");
            console.log(this.addUserForm.getErrors());
        }
    }

    addUserRoleSelectChangeHandler (event: any) {
        //update the ui

        this.addUserSelectedRoleId = event.target.value.id;
        console.log("Selected role: " + this.addUserSelectedRoleId)
    }

    changeAccountUserRoleSelectChangeHandler (event: any) {
        //update the ui

        this.changeAccountUserRoleSelectedRoleId = event.target.value;
        console.log("Selected role: " + this.changeAccountUserRoleSelectedRoleId)
    }

    changeAccountUserRole(account_id, user_id, role_id) {
        //update the ui
        this.accountService.changeAccountUserRole(account_id, user_id, role_id);
    }    

    loggo(val)
    {
        console.log(val)
    }

    async deleteAccount(account_id)
    {
        await this.accountService.deleteAccount(account_id);
        this.router.navigate(['/']);
    }
}