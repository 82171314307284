import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { Project } from '../../model/project';
import { User } from '../../../user/model/user';
import { Role } from '../../../role/model/role';
import { ProjectService } from '../../services/project.service';
import { RoleService } from '../../../role/services/role.service';
import { EmitterService } from '../../../emitter.service';
import { SocketService } from '../../../socket.service';
import { ValidationManager } from "ng2-validation-manager";
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'edit-project',
  templateUrl: './project.edit.component.html',
  styleUrls: ['./project.edit.component.css'],
    providers: [
        SocketService
    ]
})

export class ProjectEditComponent implements OnInit {
    project = new Project('', '', '', '');
    users: Array<User>;
    roles: Array<Role>;
    project_id: string;
    private sub: any;
    
    addUserForm;
    
    constructor(private projectService: ProjectService, private roleService: RoleService, private route: ActivatedRoute, private socketService: SocketService) { 
        
        this.socketService.getSocket().on('project.user.added', (data) => {
            this.loadProject();
        });
        
        this.addUserForm = new ValidationManager({
            user: 'required',
            role: 'required'
        })        
        
        this.sub = this.route.params.subscribe(params => {
            this.project_id = params['project_id'];
            console.log("Going to try to load the project");
            this.loadProject();
        })
        console.log("ProjectEditComponentConstructor");
    }
    
    ngOnInit() {
        console.log("ProjectListComponent OnInit");
    }
    
    loadProject() {
        this.projectService.get(this.project_id).subscribe(
            project => {
                this.project = project;
                
                this.projectService.getUsers(this.project_id).subscribe(
                    data => {
                        this.project.users = data;
                        
                        this.roleService.list().subscribe(
                            data => {
                                console.log("Here is the data from the roleService list function");
                                console.log(data);
                                this.roles = data;
                            },
                            err => {
                                console.log(err);
                            }
                        )
                    },
                    err => {
                        console.log(err);
                    }
                )
            },
            err => {
                console.log(err);
            }
        );        
    }

    onSubmitAddUser()
    {
        console.log("OnSubmitAddUser");
        if(this.addUserForm.isValid()) {
            console.log("The form fields");
            console.log(this.addUserForm.formControls.user.value);
            console.log(this.addUserForm.formControls.role.value);

            this.projectService.addUser(this.project_id, this.addUserForm.formControls.user.value, this.addUserForm.formControls.role.value);
            this.addUserForm.reset();
        }
        else
        {
            console.log("form errors");
            console.log(this.addUserForm.getErrors());
        }
    }

    clearForm()
    {
        this.addUserForm.reset();
    }
}
