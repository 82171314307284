<div class="messageContainer">
    <div *ngIf="messageName=='resetlinksent'">
            <h2 class="form-signin-heading"><span class="bi bi-envelope-paper-heart messageIcon"></span> Password reset link sent</h2>
            <div>
                We have sent a password reset link to your email address. <b>Please check your email</b> and follow the instructions provided.
            </div>        
    </div>    
    <div *ngIf="messageName=='resetdone'">
            <h2 class="form-signin-heading"><span class="bi bi-check-circle messageIcon success"></span> Password has been reset</h2>
            <div>
                Your password has been reset. You will recieve an email confirming that the password has been reset.
            </div>       
            <br>
            <div>
                <a href="login">Click here</a> to log in.
            </div>   
    </div>
    <div *ngIf="messageName=='reseterror'">
            <h2 class="form-signin-heading"><span class="bi bi-exclamation-circle messageIcon error"></span>Error generating password reset link</h2>
            <div>
                Please check the email address and try again.
            </div>
    </div>
    <div *ngIf="messageName=='useractivationlinksent'">
            <h2 class="form-signin-heading"><span class="bi bi-envelope-paper-heart messageIcon"></span>Activation link sent</h2>
            <div>
                We have sent an activation link to your email address. <b>Please check your email</b> and follow the instructions provided.
            </div>
    </div>
    <div *ngIf="messageName=='useractivationlinkerror'">
            <h2 class="form-signin-heading"><span class="bi bi-exclamation-circle messageIcon error"></span>Error generating activation link</h2>
            <div>
                Please check the email address and try again. The email address may be incorrect or the user may already be activated.
            </div>        
    </div>
    <div *ngIf="messageName=='useractivationdone'">
            <h2 class="form-signin-heading"><span class="bi bi-check-circle messageIcon success"></span>Activation completed</h2>
            <div>
                Your access has been activated. You will recieve an email confirming activation.
            </div>
            <br>
            <div>
                <a href="login">Click here</a> to log in.
            </div>        
    </div>    
</div>