import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';


@Component({
    selector: 'lrw-spinner',
    template: `
<div class="spinnerContainer">
    <div class="spinner">
        <div class="circle">&nbsp;</div>
        <div class="label">Loading...</div>
    </div>
</div>
`,
    styleUrls: ['./spinner.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class SpinnerComponent {    
    
}
