
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Administrator } from '../model/administrator';
import { map } from 'rxjs/operators';


import { AuthService } from '../../auth.service'
//var config = require('../../config');

import {configSettings} from '../../config';
let config = new configSettings();

@Injectable()
export class AdministratorService {

    constructor(private http: Http, private authService: AuthService) { }

    private administratorsUrl = config.api.urls.administrators;

    //Get all users
    list() : Observable<Administrator[]>
    {
        console.log("We are in the administrator service list function");
        
        return this.http.get(this.administratorsUrl, this.authService.getAuthorisedRequestOptions())
            // ...and calling .json() on the response to return the data
            .pipe(map((res) => {
                let body = res.json();
        console.log("WE are returniing the administrator list data");
        console.log(body.data);
                return body.data || {};
            }))
    }
    
    get(id: string) : Observable<Administrator>
    {
        console.log("We are in the administrator service get function");
        
        return this.http.get(this.administratorsUrl + "/" + id, this.authService.getAuthorisedRequestOptions())
            // ...and calling .json() on the response to return the data
            .pipe(map((res) => {
                let body = res.json();
                return body.data[0] || {};
            }))
    }

    change_password(existingPassword: string, newPassword1: string, newPassword2: string) : Observable<any>
    {
        var id = this.authService.getCurrentUser().id;
        
        console.log("We found the current user id");
        if(newPassword1 == newPassword2)
        {
            console.log("The passwords match, we're sending through a PUT request")
            console.log(existingPassword);
            let administrator = {id: id, existing_password: existingPassword, new_password: newPassword1};
            return this.http.put(config.api.urls.change_password.replace(":id", id), JSON.stringify(administrator), this.authService.getAuthorisedRequestOptions())
        }
        else
        {
            console.log("Passwords didn't match")
        }
    }

    private handleError (error: Response | any) 
    {
        console.log("Administrator Service: There has been an error");
        let errMsg: string;
        if(error instanceof Response) {
            const body = error.json() || '';
            const err = body.error || JSON.stringify(body);
            console.log("First Part");
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        }
        else
        {
            console.log("Second Part");
            errMsg = error.message ? error.message : error.toString();
        }
        console.error(errMsg);
        return observableThrowError(errMsg);
    }

    newAdministrator(username: string, password1: string, password2: string)
    {
        if(password1 == password2)
        {
            console.log("The passwords match, we're sending through a POST request")
            let administrator = {username: username, password: password1};
            this.http.post(this.administratorsUrl, JSON.stringify(administrator), this.authService.getAuthorisedRequestOptions()).subscribe()
        }
        else
        {
            console.log("Passwords didn't match")
        }
    }
}
