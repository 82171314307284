
<!--<div class="filterPanelControls">-->
<!--
    <div class="entry tagEntry" (click)="toggleUntagged()">
        <div class="glyphicon lrwCheckbox" [ngClass]="{'glyphicon-ok': untagged.state == 'checked'}" (click)="checkUntagged($event)">&nbsp;</div>
        <span class="glyphicon glyphicon-ban-circle" [ngClass]="{'set': untagged.state == 'banned'}" (click)="banUntagged($event)"></span>
        <span>Untagged</span>
    </div>    
-->
<table class="tagControlTable">
    <thead>
        <tr class="filterPanelHint">
            <td >
                    Item has
                    <div class="dropdown">
                        <button class="filterHintInlineButton dropdown-toggle" type="button" data-bs-toggle="dropdown">
                            <span>
                                <img *ngIf="anyOrAll == 'Any'" src='/assets/images/booleanany.svg'>
                                <img *ngIf="anyOrAll=='All'" src='/assets/images/booleanall.svg'>
                                {{anyOrAll}}</span>
                            <span class="caret"></span>
                        </button>
                        <ul class="dropdown-menu">
                            <li (click)="setAnyOrAll('Any')"><a><img src='/assets/images/booleanany.svg'>Any</a></li>
                            <li (click)="setAnyOrAll('All')"><a><img src='/assets/images/booleanall.svg'>All</a></li>
                        </ul>
                    </div>  
                    of these tags
            </td>
            <td id="tagExclusionHeader" class="collapse">
                    but none of
            </td>            
        </tr>
    </thead>
    <tbody>
    <tr>
        <td>
            <div class="entry tagEntry allnone">
                <div class="bi lrwCheckbox" [ngClass]="{'bi-check': masterCheckStatus == 'all', 'bi-stop-fill': masterCheckStatus == 'partial'}" (click)="allnoneCheck($event)" >&nbsp;</div>
                <span>Select</span>
            </div>
            <div *ngFor="let tag of tagIds | sortedTags: tags"  (click)="setChecked($event, tag.id)" class="entry tagEntry">
                <div class="bi lrwCheckbox" [ngClass]="{'bi-check': data.includeIds.indexOf(tag.id) != -1}" (click)="setChecked($event, tag.id)">&nbsp;</div>
                <lrw-tagicon [tag]="tag"></lrw-tagicon>
            </div>            
        </td>
        <td class="excludeSection collapse" id="tagExclusionContent">
            <div class="entry tagEntry allnone">
                <div class="bi lrwCheckbox" [ngClass]="{'bi-check': masterBanStatus == 'all', 'bi-stop-fill': masterBanStatus == 'partial'}" (click)="allnoneBan($event)" >&nbsp;</div>
                <span>Select</span>
            </div>
            <div *ngFor="let tag of tagIds | sortedTags: tags"  (click)="setBanned($event, tag.id)" class="entry tagEntry">
                <div class="bi lrwCheckbox" [ngClass]="{'bi-check': data.bannedIds.indexOf(tag.id) != -1}" (click)="setBanned($event, tag.id)">&nbsp;</div>
                <lrw-tagicon [tag]="tag"></lrw-tagicon>
            </div>            
        </td>        
    </tr>
</tbody>        
</table>    
            <div id="tagExclusionToggle" class="exclusionToggle panel-collapse collapse in" data-target="#tagExclusionToggle,#tagExclusionHeader,#tagExclusionContent" data-toggle="collapse">
                Advanced...
            </div>
    

<!--</div>-->
