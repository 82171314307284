<div class="filterPanelHint panel-collapse collapse" id="keywordsHint">
    Item has 
    
    <div class="dropdown">
        <button class="filterHintInlineButton dropdown-toggle" type="button" data-bs-toggle="dropdown">
            <span>
                <img *ngIf="anyOrAll == 'Any'" src='/assets/images/booleanany.svg'>
                <img *ngIf="anyOrAll=='All'" src='/assets/images/booleanall.svg'>
                {{anyOrAll}}</span>
            <span class="caret"></span>
        </button>
        <ul class="dropdown-menu">
            <li><a (click)="setAnyOrAll('Any')"><img src='/assets/images/booleanany.svg'>Any</a></li>
            <li><a (click)="setAnyOrAll('All')"><img src='/assets/images/booleanall.svg'>All</a></li>
        </ul>
    </div>    
    
    of these keywords
</div>
<div class="filterPanelControls">
    <div class="section">
        <div class="section-contents">
            <div class="input-group input-group-sm">
                <input class="form-control section-input inset-input" type="text" placeholder="e.g. fraud" [(ngModel)]="filterInputInclude" (keydown)="onKeyDownInclude($event)">
                <span class="input-group-btn">
                    <button class="btn btn-default filterButton button-hard-left-corners" type="button" (click)="addFilterInclude(filterInputInclude)">Add</button>
                </span>
            </div>
            <div class="section-chips">
                <div class="chip" *ngFor="let chip of data.includeKeywords">
                    <span class="chipText">{{chip}}</span>
                    <div class="bi bi-x" (click)="removeFilterInclude(chip)"></div>
                </div>
            </div>
        </div>
    </div>    
</div>
<div class="exclusion panel-collapse collapse" id="keywordsExclusion">
    <div class="filterPanelHint">
        but none of these keywords
    </div>
    <div class="filterPanelControls excludeKeywords">
        <div class="section">
            <div class="section-contents">
                <div class="input-group input-group-sm">
                    <input class="form-control section-input inset-input" type="text" placeholder="e.g. fraud" [(ngModel)]="filterInputExclude" (keydown)="onKeyDownExclude($event)">
                    <span class="input-group-btn">
                        <button class="btn btn-default filterButton button-hard-left-corners" type="button" (click)="addFilterExclude(filterInputExclude)">Add</button>
                    </span>
                </div>
                <div class="section-chips">
                    <div class="chip" *ngFor="let chip of data.excludeKeywords">
                        <span class="chipText">{{chip}}</span>
                        <div class="bi bi-x" (click)="removeFilterExclude(chip)"></div>
                    </div>
                </div>            
            </div>
        </div>    
    </div>
</div>
<div class="exclusionToggle panel-collapse hide-me" data-bs-target="#keywordsExclusion,#keywordsHint" data-bs-toggle="collapse" id="keywordsExclusionToggle" aria-expanded="false" >
    Advanced...
</div>