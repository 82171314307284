<div class="loginComponent">
<div class="formContainer">
<img class="dialog-logo" src="./assets/images/logo-dark.svg"/>
<h2 class="form-signin-heading">Welcome</h2>
<div><img src='/assets/images/user.svg' class='usericon-small'/>{{email}}</div>
    <form  #f="ngForm">
    <div class="form-group" [class.invalid]="hasError">
        <label for="email">Enter password</label>
        <input #autofocus  id="password" name="password" ngModel #password="ngModel" class="form-control inset-input" placeholder="password" required type="password" (keydown.enter)="onLogin(f.value)">
        <div>{{errorMsg}}</div>
    </div>
    <button class="btn btn-lg btn-primary btn-block submit" type="button" (click)="onLogin(f.value)">Sign in</button>
    <br>
    <a class="forgotpassword" href="users/password_reset">Forgot password?</a>
</form>
</div>
</div>