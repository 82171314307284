
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { Account } from '../model/account';
import { User } from '../../user/model/user';
import { Project } from '../../project/model/project';



import { AuthService } from '../../auth.service'
import { UserService } from '../../user/services/user.service'

import {configSettings} from '../../config';
let config = new configSettings();

@Injectable()
export class AccountService {

    constructor(private http: Http, private authService: AuthService, private userService: UserService, private httpClient: HttpClient) { 

    }
    
    list() : Observable<Account[]>
    {
        console.log("We are in the account service list function");
        var url = config.api.urls.user_accounts.replace(":id", this.authService.getCurrentUser().user_id);
        return this.http.get(url, this.authService.getAuthorisedRequestOptions())
            // ...and calling .json() on the response to return the data
            .pipe(map((res) => {
                let body = res.json();
                console.log("Here is the body data");
                console.log(body.data);
                return body.data || {};
            })
            // ...deal with any errors
           )
    }

    get(account_id) : Observable<Account>
    {
        console.log("We are in the account service list function");
        
        return this.http.get(config.api.urls.accounts + "/" + account_id, this.authService.getAuthorisedRequestOptions())
            // ...and calling .json() on the response to return the data
            .pipe(map((res) => {
                let body = res.json();
                return body.data || {};
            })
            // ...deal with any errors
            )
    }

    deleteAccount(account_id: string)
    {
        this.http.post(
            config.api.urls.account_do_delete.replace(":account_id", account_id),
            {},
            this.authService.getAuthorisedRequestOptions()
        )
        .subscribe();
    }
    
    getUsers(account_id) : Observable<User[]>
    {
        console.log("We are in the account service getUsers function");
        
        return this.http.get(config.api.urls.account_users.replace(":id", account_id), this.authService.getAuthorisedRequestOptions())
            // ...and calling .json() on the response to return the data
            .pipe(map((res) => {
                let body = res.json();
                console.log(body.data)
                return body.data || {};
            })
            // ...deal with any errors
            )
    }

    getProjects(account_id) : Observable<Project[]>
    {
        console.log("We are in the account service getUsers function");
        
        return this.http.get(config.api.urls.account_projects.replace(":id", account_id), this.authService.getAuthorisedRequestOptions())
            // ...and calling .json() on the response to return the data
            .pipe(map((res) => {
                let body = res.json();
                return body.data || {};
            })
            // ...deal with any errors
            )
    }
    
    
    getProject(account_id, project_id) : Observable<Project>
    {
        console.log("We are in the project service list function");
        
        return this.http.get(config.api.urls.account_projects.replace(":id", account_id) + "/" + project_id, this.authService.getAuthorisedRequestOptions())
            // ...and calling .json() on the response to return the data
            .pipe(map((res) => {
        
                let body = res.json();
                return body.data || {};
            })
            // ...deal with any errors
            )
    }
    
    private handleError (error: Response | any) 
    {
        console.log("Account Service: There has been an error");
        let errMsg: string;
        if(error instanceof Response) {
            const body = error.json() || '';
            const err = body.error || JSON.stringify(body);
            console.log("First Part");
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        }
        else
        {
            console.log("Second Part");
            errMsg = error.message ? error.message : error.toString();
            console.log(errMsg);
        }
        console.error(errMsg);
        return observableThrowError(errMsg);
    }

    newAccount(name)
    {
        let account = {name: name};
        this.http.post(config.api.urls.accounts, JSON.stringify(account), this.authService.getAuthorisedRequestOptions()).subscribe()
    }

    addUser(account_id: string, userEmail: string, roleId: string)
    {
        this.http.post(config.api.urls.account_users.replace(":id",  account_id), {"user_email": userEmail, "role_id": roleId}, this.authService.getAuthorisedRequestOptions()).subscribe()
    }
    
    addUserToProject(account_id: string, project_id: string, userEmail: string, roleId: string)
    {
        this.http.post(config.api.urls.account_project_users.replace(":id",  account_id).replace(":project_id", project_id), {"user_email": userEmail, "role_id": roleId}, this.authService.getAuthorisedRequestOptions()).subscribe()
    }

    changeAccountUserRole(account_id: string, user_id: string, role_id: string)
    {
        this.http.post(config.api.urls.do_change_account_user_role.replace(":account_id",  account_id), {"user_id": user_id, "role_id": role_id}, this.authService.getAuthorisedRequestOptions()).subscribe()
    }

    removeUserFromProject(account_id: string, project_id: string, user_id: string)
    {
        this.http.delete(config.api.urls.account_project_user.replace(":id",  account_id).replace(":project_id", project_id).replace(":user_id", user_id), this.authService.getAuthorisedRequestOptions()).subscribe()
    }
    
    removeUserFromAccount(account_id: string, user_id: string)
    {
        this.http.delete(config.api.urls.account_user.replace(":id",  account_id).replace(":user_id", user_id), this.authService.getAuthorisedRequestOptions()).subscribe()
    }

    deleteProject(account_id: string, project_id: string)
    {
        this.http.post(
            config.api.urls.account_project_do_delete.replace(":account_id", account_id).replace(":project_id", project_id),
            {},
            this.authService.getAuthorisedRequestOptions()
        )
        .subscribe();
    }
}