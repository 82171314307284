import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
    constructor(private router: Router, private authService: AuthService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      console.log("WE ARE INTERCEPTING A REQUEST")
      return next.handle(req).pipe(
          catchError((error: HttpErrorResponse) => {
              if (error.status === 401) {
                  // Check if the token is expired but reissuable
                  if (error.error.startsWith("Token Expired_Reissuable")) {
                      console.log("Token Expired_Reissuable. Getting a new token");
                      
                      // Request a new token
                      return this.authService.refreshToken().pipe(
                          switchMap(() => {
                              let refreshedToken = this.authService.getToken();
                              const clonedRequest = req.clone({
                                  setHeaders: {
                                      'x-access-token': refreshedToken
                                  },
                              });
                              
                              // Retry the original request with the new token
                              console.log("Retrying the original request with the new token");
                              return next.handle(clonedRequest);
                          }),
                          catchError((refreshError) => {
                              console.log("Error refreshing token:", refreshError);
                              return throwError(refreshError);
                          })
                      );
                  }
                  
                  // Check if the token is expired and non-reissuable
                  else if (error.error.startsWith("Token Expired_NonReissuable")) {
                       this.authService.logoutNoRedirect();
                      this.router.navigate(['/login']);
                      return of();
                  }
              }

              // Return the error to be handled by the calling code
              return throwError(error);
          })
      );
  }
}