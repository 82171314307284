var syncrequest = require('sync-request');
var project_server_proxy_prefix_standard = ""; // "_project_server_proxy_standard/";
var project_server_proxy_prefix_binary = ""; // "_project_server_proxy_binary/";

export class configSettings {
    constructor()
    {
    }
    
    get host():any {
        if(process.env.LRW_CONTROL_SERVER_API == undefined || process.env.LRW_CONTROL_SERVER_SOCKETHOST ==  undefined)
        {
            console.log("Getting the settings from file");
            var res = syncrequest('GET', './assets/environments/environment.json');
            console.log("Got it!");
            var settings = JSON.parse(res.getBody('utf8')).settings;
            process.env.LRW_CONTROL_SERVER_API = settings.LRW_CONTROL_SERVER_API;
            process.env.LRW_CONTROL_SERVER_SOCKETHOST = settings.LRW_CONTROL_SERVER_SOCKETHOST;
        }
        return process.env.LRW_CONTROL_SERVER_API;
    };

    get sockethost():any {
        if(process.env.LRW_CONTROL_SERVER_API == undefined || process.env.LRW_CONTROL_SERVER_SOCKETHOST ==  undefined)
        {
            console.log("Getting the settings from file");
            var res = syncrequest('GET', './assets/environments/environment.json');
            console.log("Got it!");
            var settings = JSON.parse(res.getBody('utf8')).settings;
            process.env.LRW_CONTROL_SERVER_API = settings.LRW_CONTROL_SERVER_API;
            process.env.LRW_CONTROL_SERVER_SOCKETHOST = settings.LRW_CONTROL_SERVER_SOCKETHOST;
        }
        var ret = process.env.LRW_CONTROL_SERVER_SOCKETHOST;
        console.log("Returning the socket host");
        console.log(ret);
        return ret;
    }

    public api:any = {
        host : this.host,
        sockethost : this.sockethost,
        settings: {
            upload_chunk_size_in_bytes: 1e9 //1gb
            //upload_chunk_size_in_bytes: 1e7 //10mb
        },
        urls : {
            accounts : this.host + 'accounts',
            account_projects : this.host + 'accounts/:id/projects',
            account_project : this.host + 'accounts/:id/projects/:project_id',
            account_project_do_delete : this.host + 'accounts/:account_id/projects/:project_id/do_delete',
            account_project_users : this.host + 'accounts/:id/projects/:project_id/users',
            account_project_user : this.host + 'accounts/:id/projects/:project_id/users/:user_id',
            account_users : this.host + 'accounts/:id/users',
            account_user : this.host + 'accounts/:id/users/:user_id',
            account_do_delete : this.host + 'accounts/:account_id/do_delete',
            do_change_account_user_role: this.host + 'accounts/:account_id/do_change_account_user_role',
            users : this.host + 'users',
            do_create_user_by_email: this.host + 'users/do_create_user_by_email',
            users_public_status : this.host + 'users/public_status',
            user_accounts : this.host + 'users/:id/accounts',
            user_projects : this.host + 'users/:id/projects',
            unviewed_user_notifications : this.host + 'users/:id/unviewed_notifications',
            dismiss_user_notifications : this.host + 'users/:id/dismiss_notifications',
            user_account_projects : this.host + 'users/:id/account_projects',
            projects : this.host + 'projects',
            project_users : this.host + 'projects/:id/users',
            project_do_remove_user: this.host + 'projects/:project_id/do_remove_user',
            project_server : this.host + 'project_servers',
            adminauthenticate : this.host + 'administrators/authenticate',
            userauthenticate : this.host + 'users/authenticate',
            user_reissue_token : this.host + 'users/reissue_token',
            administrators : this.host + 'administrators',
            change_password : this.host + 'administrators/:id/change_password',
            roles : this.host + 'roles',
            password_reset_token_details : this.host + 'users/password_reset/:token',
            password_reset_execute : this.host + 'users/password_reset/:token',
            password_reset_initiate : this.host + 'users/password_reset',
            user_activation_initiate : this.host + 'users/activation',
            share_project : this.host + 'users/share_project',
            share_document : this.host + 'users/share_document',
            user_activation_execute : this.host + 'users/activation/:token',
            user_activation_token_details : this.host + 'users/activation/:token',
            get_signup_restriction_setting: this.host + 'settings/get_signup_restriction_setting'
        },
        project_server : {
            get_display_timezone :      this.host + project_server_proxy_prefix_standard + "projects/:id/get_display_timezone",
            get_items :                 this.host + project_server_proxy_prefix_standard + "projects/:id/get_items",
            get_ids :                   this.host + project_server_proxy_prefix_standard + "projects/:id/get_ids",
            get_item :                  this.host + project_server_proxy_prefix_standard + "projects/:project_id/get_items/:item_id",
            get_item_by_bates :         this.host + project_server_proxy_prefix_standard + "projects/:project_id/get_item_by_bates/:bates",
            get_items_jsonb :           this.host + project_server_proxy_prefix_standard + "projects/:id/get_items_jsonb",
            get_page_items_jsonb :      this.host + project_server_proxy_prefix_standard + "projects/:id/get_page_items_jsonb",
            get_query_items :           this.host + project_server_proxy_prefix_standard + "projects/:id/get_query_items",
            get_query_items_count :     this.host + project_server_proxy_prefix_standard + "projects/:id/get_query_items_count",
            get_lrws_query_items :      this.host + project_server_proxy_prefix_standard + "projects/:id/get_lrws_query_items",
            get_lrws_query_items_count : this.host + project_server_proxy_prefix_standard + "projects/:id/get_lrws_query_items_count",
            get_family_items :          this.host + project_server_proxy_prefix_standard + "projects/:project_id/get_family_items/:item_id",
            get_query_ids :             this.host + project_server_proxy_prefix_standard + "projects/:id/get_query_ids",
            get_root_nodes :            this.host + project_server_proxy_prefix_standard + "projects/:project_id/get_root_nodes",
            get_imports :               this.host + project_server_proxy_prefix_standard + "projects/:project_id/get_imports",
            get_child_nodes :           this.host + project_server_proxy_prefix_standard + "projects/:project_id/get_child_nodes/:item_id",
            get_parents_and_siblings_leading_up_to_paths : this.host + project_server_proxy_prefix_standard + "projects/:project_id/get_parents_and_siblings_leading_up_to_paths",
            get_doctypes :              this.host + project_server_proxy_prefix_standard + "projects/:id/get_doctypes",
            get_tags :                  this.host + project_server_proxy_prefix_standard + "projects/:id/get_tags",
            get_item_tags_report :      this.host + project_server_proxy_prefix_standard + "projects/:id/get_item_tags_report",
            get_excel_indexes :         this.host + project_server_proxy_prefix_standard + "projects/:project_id/get_excel_indexes",
            get_excel_url :             this.host + project_server_proxy_prefix_standard + "projects/:project_id/get_excel_url",
            do_tag_items :              this.host + project_server_proxy_prefix_standard + "projects/:id/do_tag_items",
            do_tag_items_new :          this.host + project_server_proxy_prefix_standard + "projects/:id/do_tag_items_new",
            do_untag_items :            this.host + project_server_proxy_prefix_standard + "projects/:id/do_untag_items",
            do_edit_tag :               this.host + project_server_proxy_prefix_standard + "projects/:id/do_edit_tag",
            do_delete_tag :             this.host + project_server_proxy_prefix_standard + "projects/:id/do_delete_tag",
            do_restore_junk_items :     this.host + project_server_proxy_prefix_standard + "projects/:project_id/do_restore_junk_items",
            do_mark_junk_items :        this.host + project_server_proxy_prefix_standard + "projects/:project_id/do_mark_junk_items",
            get_file :                  this.host + project_server_proxy_prefix_standard + "projects/:project_id/get_file/:item_id/:group_name/:display_filename",
            get_pdf_preview :           this.host + project_server_proxy_prefix_standard + "projects/:project_id/get_pdf_preview/:item_id",
            get_file_access_token:      this.host + project_server_proxy_prefix_standard + "projects/:project_id/get_file_access_token/:file_id",
            get_file_with_access_token: this.host + project_server_proxy_prefix_standard + "projects/:project_id/get_file/:file_id/:file_access_token/:display_filename",
            get_next_starting_bates:    this.host + project_server_proxy_prefix_standard + "projects/:project_id/get_next_starting_bates",
            get_next_import_name:       this.host + project_server_proxy_prefix_standard + "projects/:project_id/get_next_import_name",
            hyperlink:                  this.host + project_server_proxy_prefix_standard + "projects/:project_id/get_items/:item_id/hyperlink/:group_name",
            do_create_import_entry:     this.host + project_server_proxy_prefix_standard + "projects/:project_id/do_create_import_entry",
            do_complete_multipart_upload:       this.host + project_server_proxy_prefix_standard + "projects/:project_id/do_complete_multipart_upload",
            do_export_to_excel_from_filters:    this.host + project_server_proxy_prefix_standard + 'projects/:project_id/do_generate_excel_index_from_filters',
            do_ingest_loose_files:              this.host + project_server_proxy_prefix_binary + "projects/:project_id/:import_id/do_ingest/loose_files",
            notify_import_uploads_complete:     this.host + project_server_proxy_prefix_binary + "projects/:project_id/:import_id/notify_import_uploads_complete",
            do_submit_notes:                    this.host + project_server_proxy_prefix_binary + "projects/:project_id/do_submit_notes",
            do_set_index_category:              this.host + project_server_proxy_prefix_binary + "projects/:project_id/do_set_index_category",
            papers : {
                get_papers_list :               this.host + project_server_proxy_prefix_standard + "projects/:project_id/papers",
                get_paper :                     this.host + project_server_proxy_prefix_standard + "projects/:project_id/papers/:paper_id",
                generate_paper_from_ids :       this.host + project_server_proxy_prefix_standard + "projects/:project_id/papers/generate_paper_from_ids",
                generate_paper_from_filters :   this.host + project_server_proxy_prefix_standard + "projects/:project_id/papers/generate_paper_from_filters",
                delete_paper :                  this.host + project_server_proxy_prefix_standard + "projects/:project_id/papers/delete_paper",
                get_events :                    this.host + project_server_proxy_prefix_standard + "projects/:project_id/papers/:paper_id/get_events",
                do_submit_steps :               this.host + project_server_proxy_prefix_standard + "projects/:project_id/papers/:paper_id/do_submit_steps",
                do_export_to_docx :             this.host + project_server_proxy_prefix_standard + "projects/:project_id/papers/:paper_id/do_export_to_docx",
            }
        },
    }
}
