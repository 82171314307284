<div class="filterPanelHint">
    Item 'To' or 'From' contain any of these
</div>
<div class="filterPanelControls">
    <div class="section">
        <div class="section-label">Parties</div>
        <div class="section-contents">
            <div class="input-group input-group-sm">
                <input class="form-control section-input inset-input" type="text" placeholder="e.g. smith"[(ngModel)]="filterInputs.any" (keydown)="onKeyDown('any', $event)">
                <span class="input-group-btn">
                    <button class="btn btn-default button-hard-left-corners filterButton" type="button" (click)="addFilter('any', filterInputs.any)">Add</button>
                </span>
            </div>
            <div class="section-chips">
                <div class="chip" *ngFor="let chip of data.any">
                    <span class="chipText">{{chip}}</span>
                    <div class="bi bi-x" (click)="removeFilter('any', chip)"></div>
                </div>
            </div>            
        </div>
    <!--
        <div class="section-label">To</div>
        <div class="section-contents">
            <div class="section-chips">
                <div class="chip" *ngFor="let chip of data.to">
                    <span class="chipText">{{chip}}</span>
                    <div class="bi bi-trash-fill" (click)="removeFilter('to', chip)"></div>
                </div>
            </div>
            <div class="input-group input-group-sm">
                <input class="form-control section-input" type="text" placeholder="add value..."[(ngModel)]="filterInputs.to">
                <span class="input-group-btn">
                    <button class="btn btn-default" type="button" (click)="addFilter('to', filterInputs.to)">Add</button>
                </span>
            </div>
        </div>
        <div class="section-label">From</div>
        <div class="section-contents">
            <div class="section-chips">
                <div class="chip" *ngFor="let chip of data.from">
                    <span class="chipText">{{chip}}</span>
                    <div class="bi bi-trash-fill" (click)="removeFilter('from', chip)"></div>
                </div>
            </div>
            <div class="input-group input-group-sm">
                <input class="form-control section-input" type="text" placeholder="add value..."[(ngModel)]="filterInputs.from">
                <span class="input-group-btn">
                    <button class="btn btn-default" type="button" (click)="addFilter('from', filterInputs.from)">Add</button>
                </span>
            </div>
        </div>
    -->
    </div>    
</div>
