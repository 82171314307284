import { Component } from '@angular/core';

@Component({
    selector: 'error',
    template: `
        <h2>404</h2> Error: could not locate resource.
`,
})

export class ErrorComponent {
    constructor() {}
}