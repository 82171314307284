import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule, FormGroup } from '@angular/forms';
import { HttpModule, JsonpModule } from '@angular/http';
import { RouterModule } from '@angular/router';


import { AdministratorListComponent } from './components/list/administrator.list.component'
import { AdministratorEditComponent } from './components/edit/administrator.edit.component'
import { NewAdministratorFormComponent } from './components/new/administrator.new.component'
import { LoginComponent } from './components/login/administrator.login.component'
import { ChangePasswordComponent } from './components/change_password/administrator.change_password.component'
import { AdministratorComponent } from './index'
import { AdministratorService } from './services/administrator.service'

@NgModule({
  imports: [
      CommonModule,
      BrowserModule,
      HttpModule,
      FormsModule,
      ReactiveFormsModule,
      JsonpModule,
      RouterModule
  ],
  declarations: [
      AdministratorComponent,
      NewAdministratorFormComponent,
      AdministratorListComponent,
      AdministratorEditComponent,
      LoginComponent,
      ChangePasswordComponent
  ],
    providers: [
        AdministratorService
    ],
    exports: [
        AdministratorListComponent,
        AdministratorComponent
    ]
})
export class AdministratorModule { }
