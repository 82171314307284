import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaperService } from "../../services/paper.service";
import { Project_ServerService } from "../../../project_server.service";
import { ProjectService } from "../../services/project.service";
var moment = require('moment-timezone');

@Component({
    selector: 'lrw-projectinfo',
    templateUrl: 'project.info.component.html',
    styleUrls: ['./project.info.component.css'],
    providers: [
        Project_ServerService
    ]    
})

export class ProjectInfoComponent {    
    projectId: any;
    projectName: any;
    papers: any;
    serverDetails: any;
    imports: any;
    display_timezone;
    constructor
    (
        private route: ActivatedRoute, 
        private projectServerService: Project_ServerService, 
        private paperService: PaperService,
        private projectService: ProjectService
    )
    {
        this.route.params.subscribe
        (
            params => 
            {
                this.projectId = params['project_id'];

                this.populateImportsFromServer();
                this.getDisplayTimezone();

            }
        )
    }
    copyArray(source, dest) //copy contents of doctyles list to checkedTypes, keep reference intact
    {
        dest.length = 0;
        [].push.apply(dest, source);
    }



    getDisplayTimezone() {

        function toTitleCase(str) {
            return str.replace(
                /\w\S*/g,
                function(txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                }
            );
        }

        if(this.projectId)
        {
            this.projectService.getDisplayTimezone(this.projectId).subscribe(
                timezone => {
                    var offsetMinutes = moment.tz(timezone).utcOffset();
                    var m = offsetMinutes % 60;
                    var h = (offsetMinutes-m)/60;
                    var HHMM = (h<=0?"":"+") + h.toString() + ":" + (m<10?"0":"") + m.toString();
                    this.display_timezone = toTitleCase(timezone.replace("/", " - ").replace("_", " ")) + " (GMT" + HHMM + ")";
                },
                err => {
                    console.log(err);
                }                
            )
        }
    }


    populateImportsFromServer() {
        if(this.projectId)
        {
            console.log("We have the project id")
            this.projectService.getImports(this.projectId).subscribe(
                res => {
                    this.imports = [];
                    for(var i = 0; i < res.length; i++)
                    {
                        this.imports.push(res[i]);
                        console.log("Populating Node");
                        console.log(res[i]);
                    }
                },
                err => {
                    console.log(err);
                }                
            )
        }
        else
        {
            console.log("We didn't get the imports coz we didnt have the project id")
        }
    }
}
