import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { Administrator } from '../../model/administrator'
import { AdministratorService } from '../../services/administrator.service';
import { EmitterService } from '../../../emitter.service';
import { NewAdministratorFormComponent } from '../new/administrator.new.component';

@Component({
  selector: 'administrator-list',
  templateUrl: './administrator.list.component.html',
  styleUrls: ['./administrator.list.component.css']
})

export class AdministratorListComponent implements OnInit, OnChanges {

    administrators: Administrator[];
    listId: string;
    
    constructor(private administratorService: AdministratorService) { 
        console.log("AdministratorListComponentConstructor");
    }
    
    ngOnInit() {
        console.log("AdministratorListComponent OnInit");
        this.loadAdministrators();
    }
    
    loadAdministrators() {
        this.administratorService.list().subscribe(
            administrators => this.administrators = administrators,
            err => {
                console.log(err);
            }
        );
    }
    
    ngOnChanges(changes:any) {
        EmitterService.get(this.listId).subscribe((comments:Comment[]) => { this.loadAdministrators()})
    }
}