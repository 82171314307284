<!-- <div class="doceditor" (mousemove)="mouseMovedOnPage($event)"> -->
<div class="doceditor">
    <div class="menucontainer" id="menucontainer">
        <div class="menubar" id="menubar" *ngIf="!readOnly">
            <div class="menuSection">
                <div class="menuSectionControls">
                    <div class="line">
                        <span title="Bold" class="menuicon" id="cmd-strong">B</span>
                        <span title="Italic" class="menuicon" id="cmd-em">I</span>
                        <span title="Underline" class="menuicon" id="cmd-underline">U</span>
                        <span title="Strike Through" class="menuicon" id="cmd-strikethrough">abc</span>
                    </div>
                    <div class="line">
                        <span title="Align Left" class="menuicon bi bi-align-start" id="cmd-alignleft"></span>
                        <span title="Align Center" class="menuicon bi-align-middle" id="cmd-aligncenter"></span>
                        <span title="Align Right" class="menuicon bi bi-align-end" id="cmd-alignright"></span>
                        <span title="Bullet List" class="menuicon" id="cmd-bullet-list">
                            <img class="toolbar-small-image" src="/assets/images/toolbar/bulletlist.png" />
                            
                        </span>
                        <span title="Ordered List" class="menuicon" id="cmd-ordered-list">
                            <img class="toolbar-small-image" src="/assets/images/toolbar/orderedlist.png" />
                            
                        </span>
                    </div>
                </div>
                <div class="menuSectionTitle">Formatting</div>
            </div>
            <div class="menuSection">
                <div class="menuSectionControls">
                    <div class="textStylePalette">
                        <div class="textStylePaletteBlock" id="cmd-paragraph">
                            <div class="textStylePalettePreview">AaBbCcDdEe</div>
                            <div class="textStylePaletteTitle">Normal</div>
                        </div>
                        <div class="textStylePaletteBlock" id="cmd-h1">
                            <div class="textStylePalettePreview" >AaBbCcDdEe</div>
                            <div class="textStylePaletteTitle">Heading 1</div>
                        </div>
                        <div class="textStylePaletteBlock"  id="cmd-h2">
                            <div class="textStylePalettePreview">AaBbCcDdEe</div>
                            <div class="textStylePaletteTitle">Heading 2</div>
                        </div>
                        <div class="textStylePaletteBlock"  id="cmd-h3">
                            <div class="textStylePalettePreview">AaBbCcDdEe</div>
                            <div class="textStylePaletteTitle">Heading 3</div>
                        </div>
                    </div>    
                </div>
                <div class="menuSectionTitle">Style
                </div>
            </div>
            <div class="menuSection">
                <div class="menuSectionControls">
                    <div class="verticalList">
                        <span title="Insert Table" class="menuicon withlabel" id="cmd-new-table">
                            <img class="toolbar-small-image" src="/assets/images/toolbar/table.png" />
                            Table
                        </span>
                        <span title="Horizontal Line" class="menuicon withlabel" id="cmd-horizontal-rule">
                            <img class="toolbar-small-image" src="/assets/images/toolbar/hr.png" />
                             Line                            
                        </span>
                        <span title="Comment" class="menuicon" id="cmd-comment">
                            Comment
                        </span>                        
                    </div>
                </div>
                <div class="menuSectionTitle">Insert</div>
            </div>
            <!-- <div class="menuSection">
                <div class="menuSectionControls">
                    <div class="verticalList">
                        <span title="Show version number in footer" class="menuicon withlabel" (click)="showVersion = !showVersion">
                            <span class="glyphicon toolbarcheckbox" [ngClass]="{'glyphicon-ok': showVersion}"></span>
                            <span>Doc Version</span>
                        </span>
                        <span title="Show page number in footer" class="menuicon withlabel" (click)="showPageNum = !showPageNum">
                            <span class="glyphicon toolbarcheckbox" [ngClass]="{'glyphicon-ok': showPageNum}"></span>
                            <span>Page Num</span>
                        </span>
                    </div>
                </div>
                <div class="menuSectionTitle">Footer</div>
            </div>    -->
            <div class="menuSection">
                <div class="menuSectionControls">
                    <div class="line">
                        <span title="Zoom In" class="menuicon" (click)="zoomin()">+</span>
                        <span class="menuicon" id="cmd-reset-zoom" (click)="resetzoom()">{{zoomlevel}}%</span>
                        <span title="Zoom Out" class="menuicon" (click)="zoomout()">-</span>
                    </div>
                    <div class="line">
                        <span  *ngIf="this.generatingExport == false" class="menuicon" (click)="exportToDocx()">Export To MS Word</span>
                        <span  *ngIf="this.generatingExport == true" class="menuicon executing"><span class="spinner-border spinner-border-sm progress-spinner"></span>Exporting (Estimated Duration: {{estimated_export_duration}})</span>
                    </div>   
                    <!-- <div class="line">
                        <span title="Print Json" class="menuicon" id="cmd-print-json">Show Json</span>
                    </div>                  -->
                </div>
                <div class="menuSectionTitle">Zoom / Export</div>

            </div>            
            <!-- <div class="menuSection">
                <div class="menuSectionControls">
                    <div class="line">
                        <span title="Share document" class="menuicon withlabel" (click)="shareDocumentModal.open()">
                            <span class="glyphicon glyphicon-share-alt"></span>
                            <span>Share Document</span>
                        </span>
                    </div>
                    <div class="line">
                        <span title="Law Image Print" class="menuicon withlabel" onclick="window.open('https://live.lawimage.com.au', '_blank')">
                            <span class="glyphicon glyphicon-print"></span>
                            <span>Law Image Print</span>
                        </span>
                    </div>               
                </div>
                <div class="menuSectionTitle">Share</div>
            </div>    -->            
        </div>
    </div>
    <div class="scrollcontainer">
        <div class="page" id="page" (click)="clickedpage($event)">
            <div #prosemirrordoc class="prosemirrordoc"></div>
            <!-- <div class="footer" *ngIf="showPageNum ||showVersion">
                <div class="version"><span *ngIf="showVersion">Document Version 12937</span></div>
                <div class="pagenum"><span *ngIf="showPageNum">Page <span class="placeholder">#</span> of <span class="placeholder">#</span></span></div>
                <div class="dummyfootersection"></div>
            </div> -->
            <div id="itemreference-row-context-menu">
                <ul>
                    <li><i class="bi bi-caret-up-fill"></i> Move Up</li>
                    <li><i class="bi bi-scissors"></i>Cut</li>
                    <li><i class="bi bi-files"></i>Copy</li>
                    <li><i class="bi bi-clipboard"></i>Paste</li>
                    <li (click)="deleteRow()"><i class="bi bi-trash3-fill"></i>Delete</li>
                    <li><i class="bi bi-caret-down-fill"></i> Move Down</li>
                </ul>

            </div>
        </div>
    </div>
</div>

<div class="modal" #shareDocumentModal (click)="$event.stopPropagation()">
    <div class="modal-header">
        <h1><span class="glyphicon glyphicon-share-alt"></span>&nbsp;Share Document</h1>
    </div>
    <div class="modal-content">
    <h4>
        <span>Share document with (email address): </span><span><input class="form-control section-input" type="text" placeholder="e.g. email@company.com" [(ngModel)]="shareDocumentEmail" id="shareEmailInput"></span>
    </h4>        
    <h4 style="visibility: collapse">
        <span>Message (optional): </span><span><input class="form-control section-input" type="text" placeholder="short message" [(ngModel)]="shareDocumentMessage" id="shareEmailMessage"></span>
    </h4>        
        
    </div>
    <div class="modal-footer">
        <button class="btn btn-success" (click)="shareDocument(); shareDocumentModal.close(); docLinkSharedModal.open();" >Share</button>
        <a class="apply-filters"><span (click)="shareDocumentModal.close()" [style.marginLeft.px]="5">Cancel</span></a>
    </div>
</div>
<div class="modal" #docLinkSharedModal (click)="$event.stopPropagation()">
    <div class="modal-header">
        <h1><span class="glyphicon glyphicon-send"></span>&nbsp;Message Sent!</h1>
    </div>
    <div class="modal-content">
    <h4>
        <span>Document has been shared with {{shareDocumentEmail}}</span>
    </h4>        
        
</div>
    <div class="modal-footer">
        <a class="apply-filters"><span (click)="docLinkSharedModal.close()" [style.marginLeft.px]="5">Close</span></a>
    </div>
</div>