import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { Account } from '../../model/account'
import { AccountService } from '../../services/account.service';
import { EmitterService } from '../../../emitter.service';
import { NewAccountFormComponent } from '../new/account.new.component';
import { SocketService } from '../../../socket.service';

@Component({
  selector: 'account-list',
  templateUrl: './account.list.component.html',
  styleUrls: ['./account.list.component.css'],
    providers: [
        SocketService
    ]    
})

export class AccountListComponent implements OnInit, OnChanges {

    accounts: Account[];
    listId: string;
    
    constructor(private accountService: AccountService, private socketService: SocketService) { 
        console.log("AccountListComponentConstructor");
     
        this.socketService.getSocket().on('account.added', (data) => {
            this.loadAccounts();
        });
    }
    
    ngOnInit() {
        console.log("AccountListComponent OnInit");
        this.loadAccounts();
    }
    
    loadAccounts() {
        this.accountService.list().subscribe(
            accounts => {
                this.accounts = accounts;
                console.log("The Accounts!");
                console.log(this.accounts);
            },
            err => {
                console.log(err);
            }
        );
    }
    
    ngOnChanges(changes:any) {
        EmitterService.get(this.listId).subscribe((comments:Comment[]) => { this.loadAccounts()})
    }
}
