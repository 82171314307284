import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { Project } from '../../model/project'
import { ProjectService } from '../../services/project.service';
import { AuthService } from '../../../auth.service';
import { AccountService } from '../../../account/services/account.service';
import { UserService } from '../../../user/services/user.service';
import { EmitterService } from '../../../emitter.service';
import { NewProjectFormComponent } from '../new/project.new.component';
import { SocketService } from '../../../socket.service';
var GeoPattern = require('geopattern');
import { MomentTimezonePickerModule } from 'moment-timezone-picker';

@Component({
  selector: 'project-list',
  templateUrl: './project.list.component.html',
  styleUrls: ['./project.list.component.css'],
    providers: [
        SocketService
    ]    
})

export class ProjectListComponent implements OnInit, OnChanges {

    accounts: any;
    accountProjects: any = {};
//    groupedprojects:any;
//    projects: Array<any> = [];
    listId: string;
    newProjectAccountId: any;
    newProjectName: any;
    newProjectTimezone: any;
    dodgyEmail: any = false;
    newProjectUsers: Array<string> = [];
    newProjectUserInput: any;

    socket: any;
    pendingProjectAccountId = null;

    constructor
    (
        private projectService: ProjectService,
        private accountService: AccountService,
        private authService: AuthService, 
        private userService: UserService,
        private socketService: SocketService,
        private _sanitizer: DomSanitizer
    )
    { 
        console.log("ProjectListComponentConstructor");
        this.socket = this.socketService.getSocket();
        this.socket.on('project.added', (data) => {
            this.loadProjects();
        });

        this.socket.on('account.user.added', (data) => {
            this.loadProjects();
        });

        var self = this;
        self.socket.on('connect', function() {
            console.log("Socket Connected!");

            for(let account in self.accountProjects)
            {
                self.socket.emit('room', account);
                for(let i = 0; i < self.accountProjects[account].projects.length; i++)
                {
                    self.socket.emit('room', self.accountProjects[account].projects[i].project_id);
                }
            }

            self.socket.emit('room', self.authService.getCurrentUser().user_id);
        });
        this.socket.connect();

        this.newProjectUsers.push(this.authService.getCurrentUser().email);
    }
    
    ngOnInit() {
        console.log("ProjectListComponent OnInit");
        this.loadProjects();
    }
    
    loadProjects() {
        let self = this;
        // this.projectService.listUserProjects().subscribe(
        //     projects => 
        //     {
        //         self.pendingProjectAccountId = null;
        //         function copyArray(source, dest) //copy contents but keep refs intact
        //         {
        //             dest.length = 0;
        //             [].push.apply(dest, source);
        //         }

        //         copyArray(projects, this.projects);

        //         var accounts = [];
        //         var groupedprojects = {};
        //         for(var i = 0; i < this.projects.length; i++)
        //         {
        //             projects[i].backgroundImage = this._sanitizer.bypassSecurityTrustStyle(GeoPattern.generate(projects[i].id).toDataUrl());

        //             if(this.accounts.indexOf(projects[i].account_id) == -1)
        //             {
        //                 this.accounts.push(projects[i].account_id);
        //             }

        //             if(!groupedprojects.hasOwnProperty(projects[i].account_id))
        //             {
        //                 groupedprojects[projects[i].account_id] = [];
        //             }
        //             groupedprojects[projects[i].account_id].push(this.projects[i]);
        //             console.log("Sorting!!!");
        //             groupedprojects[projects[i].account_id] = groupedprojects[projects[i].account_id].sort((a, b) => 
        //             {
        //                 return a.created.localeCompare(b.created)
        //             });
        //             for(var j = 0; j < groupedprojects[projects[i].account_id].length; j++)
        //             {
        //                 console.log(groupedprojects[projects[i].account_id][j].name)
        //             }
        //         }

        //         copyArray(accounts, this.accounts);
        //         this.groupedprojects = groupedprojects;

        //         console.log("Here are the grouped projects");
        //         console.log(this.groupedprojects);
        //     },
        //     err => {
        //         console.log(err);
        //     }
        // );

        this.userService.listUserAccountProjects().subscribe(
            async accountProjects =>
            {
                self.pendingProjectAccountId = null;
                console.log("ACCOUNT PROJECTS");
                console.log(accountProjects);

                this.accountProjects = accountProjects;
                this.accounts = [];
                for(let account in this.accountProjects)
                {
                    this.accounts.push(accountProjects[account]);
                    console.log("JOINING ACCOUNT ROOM " + account);
                    self.socket.emit('room', account);
                    if(this.accountProjects[account].hasOwnProperty('projects'))
                    {
                        this.accountProjects[account].projects.sort((a, b) => 
                        {
                            return a.project_created.localeCompare(b.project_created)
                        });

                        for(let i = 0; i < this.accountProjects[account].projects.length; i++)
                        {
                            this.accountProjects[account].projects[i].backgroundImage = this._sanitizer.bypassSecurityTrustStyle(GeoPattern.generate(this.accountProjects[account].projects[i].project_id).toDataUrl());
                        }    
                    }


                    this.accountService.getUsers(account).subscribe(
                        users =>
                        {
                            this.accountProjects[account].users = users;
                            console.log("HERE IS THE ACCOUNTPROJETS")
                            console.log(this.accountProjects)
                        }
                    );

                }

                this.accounts.sort((a, b) => {
                    const compareType = (a.type || "z").localeCompare(a.type || "z");
                    const compareName = a.name.localeCompare(a.name);
                  
                    return compareType || compareName;
                  })


                  console.log("ACCOUNTS!")
                  console.log(this.accounts);

            },
            err =>
            {
                console.log("Error getting the account projects");
                console.log(err);
            }
        )
    }
    
    ngOnChanges(changes:any) {
        EmitterService.get(this.listId).subscribe((comments:Comment[]) => { this.loadProjects()})
    }

    createProject(account_id, newProjectName, timezone, users)
    {
        this.pendingProjectAccountId = account_id;
        this.projectService.new(account_id, newProjectName, timezone.nameValue, users);
        this.newProjectUserInput = "";
        this.newProjectUsers = [];
        this.newProjectUsers.push(this.authService.getCurrentUser().email);
    }

    onKeyDownNewProjectUser(event)
    {
        this.dodgyEmail = false;
        if(event.keyCode == 13)
         {
            if(this.validateEmail(this.newProjectUserInput))
            {
                this.addNewProjectUser(this.newProjectUserInput)
            }
            else
            {
                this.dodgyEmail = true;
            }
         }
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    addNewProjectUser(newProjectUserInput)
    {
        this.newProjectUsers.push(newProjectUserInput);
        this.newProjectUserInput = "";
    }

    removeNewProjectUser(val) {
        var index = this.newProjectUsers.indexOf(val)
        if( index != -1)
        {
            this.newProjectUsers.splice(index, 1);
        }
    }

}
