import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class EmitterService {
    // Event Store
    private static _emitters: { [ID: string]: EventEmitter<any> } = {};

    // Set a new event in the store with a given ID
    //As key
    static get(ID: string): EventEmitter<any> {
        if (!this._emitters[ID])
        {
            this._emitters[ID] = new EventEmitter();
        }
        return this._emitters[ID];
    }

    constructor() { }
}
