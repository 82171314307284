import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule, FormGroup } from '@angular/forms';
import { HttpModule, JsonpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { TreeModule } from '@circlon/angular-tree-component';
import { PdfViewerModule} from 'ng2-pdf-viewer';
import { MomentModule } from 'angular2-moment';
import { NgxPopperModule } from 'ngx-popper';
import { ModalDialogModule } from 'ngx-modal-dialog';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MomentTimezonePickerModule } from 'moment-timezone-picker'; 
import { NgSelectModule } from '@ng-select/ng-select';
import { TimeagoModule } from 'ngx-timeago';

import { ProjectListComponent } from './components/list/project.list.component';
import { ProjectEditComponent } from './components/edit/project.edit.component';
import { ProjectViewComponent } from './components/view/project.view.component';
import { PapersViewComponent } from './components/papersview/papersview.component';
import { ProjectInfoComponent } from './components/info/project.info.component';
import { DataGridComponent } from './components/datagrid/datagrid';
import { ItemViewerComponent } from './components/itemviewer/itemviewer';
import { FamilyTreeComponent } from './components/familytree/familytree.component';
import { IconComponent } from './components/icon/icon';
import { AttributeValueComponent } from './components/attribute-value/attribute-value';
import { TagiconComponent } from './components/tagicon/tagicon';
import { SpinnerComponent } from './components/spinner/spinner';
import { ColourpickerComponent } from './components/colourpicker/colourpicker';
import { DocEditorComponent } from './components/doceditor/doceditor';
import { FilterTypeComponent } from './components/filters/type/filter.type.component';
import { FilterTagsComponent } from './components/filters/tags/filter.tags.component';
import { FilterDateComponent } from './components/filters/date/filter.date.component';
import { FilterPartiesComponent } from './components/filters/parties/filter.parties.component';
import { FilterPathComponent } from './components/filters/path/filter.path.component';
import { FilterKeywordsComponent } from './components/filters/keywords/filter.keywords.component';
import { FilterModifiersComponent } from './components/filters/modifiers/filter.modifiers.component';
import { NewProjectFormComponent } from './components/new/project.new.component';
import { ProjectComponent } from './index';
import { ProjectService } from './services/project.service';
import { S3Service } from './services/s3.service';
import { PaperService } from './services/paper.service';
import { RoleService } from '../role/services/role.service';
import { SocketService } from '../socket.service';
import { AuthGuard } from '../auth-guard.service';
//import { VirtualScrollModule } from '../../../src/forked_npm_modules/angular2-virtual-scroll';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { AngularSplitModule } from 'angular-split';
import { SortPipe } from "./pipes/sortPipe";
import { SortedTagsPipe } from "./pipes/sortedTagsPipe";
import { AttributeNamePipe } from "./pipes/attributeNamePipe";
import { LinkTooltipComponent } from './components/doceditor/components/linktooltip.component';
import { PdfViewerComponent} from 'ng2-pdf-viewer';


@NgModule({
  imports: [
      CommonModule,
      BrowserModule,
      BrowserAnimationsModule,
      HttpModule,
      HttpClientModule,
      FormsModule,
      ReactiveFormsModule,
      JsonpModule,
      VirtualScrollerModule,
      AngularSplitModule,
      TreeModule,
      PdfViewerModule,
      MomentModule,
      NgxPopperModule.forRoot(),
      ModalDialogModule.forRoot(),
      RouterModule,
      NgxFileDropModule,
      MomentTimezonePickerModule,
      NgSelectModule,
      TimeagoModule.forRoot()
  ],
  declarations: [
      ProjectListComponent,
      ProjectViewComponent,
      PapersViewComponent,
      ProjectInfoComponent,
      ProjectEditComponent,
      NewProjectFormComponent,
      ProjectComponent,
      DataGridComponent,
      IconComponent,
      AttributeValueComponent,
      TagiconComponent,
      ItemViewerComponent,
      FamilyTreeComponent,
      SpinnerComponent,
      ColourpickerComponent,
      DocEditorComponent,
      FilterTypeComponent,
      FilterTagsComponent,
      FilterDateComponent,
      FilterPartiesComponent,
      FilterPathComponent,
      FilterKeywordsComponent,
      FilterModifiersComponent,
      SortPipe,
      SortedTagsPipe,
      AttributeNamePipe,
      LinkTooltipComponent,
  ],
    providers: [
        ProjectService,
        S3Service,
        PaperService,
        RoleService,
        SocketService,
        AuthGuard
    ],
    exports: [
        ProjectListComponent,
        ProjectComponent
    ]
})

export class ProjectModule 
{ 

}