import { Component } from '@angular/core';
import {EmitterService} from '../emitter.service';

@Component({
    selector: 'project-widget',
    template: `
        <div>
            <project-list></project-list>
        </div>
        `
})

export class ProjectComponent {
    private listId = 'USER_COMPONENT_LIST';
}