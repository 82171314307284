
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Role } from '../model/role';



import { AuthService } from '../../auth.service'

//var config = require('../../config');
import {configSettings} from '../../config';
let config = new configSettings();

@Injectable()
export class RoleService {

    constructor(private http: Http, private authService: AuthService) { 

    }

    private rolesUrl = config.api.urls.roles;

    //Get all accounts
    list() : Observable<Role[]>
    {
        console.log("We are in the role service list function");
        
        return this.http.get(this.rolesUrl, this.authService.getAuthorisedRequestOptions())
            // ...and calling .json() on the response to return the data
            .pipe(map((res) => {
                let body = res.json();
                return body.data || {};
            })
            // ...deal with any errors
            ,catchError(this.handleError)
            );
    }
    
    private handleError (error: Response | any) 
    {
        console.log("Role Service: There has been an error");
        let errMsg: string;
        if(error instanceof Response) {
            const body = error.json() || '';
            const err = body.error || JSON.stringify(body);
            console.log("First Part");
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        }
        else
        {
            console.log("Second Part");
            errMsg = error.message ? error.message : error.toString();
        }
        console.error(errMsg);
        return observableThrowError(errMsg);
    }
}