    <div class="colourPickerTable">
    	<ul>
      	<li *ngFor="let colour of basicColours">
        	<div [style.background]="colour" [style.color]="generateTextColour(colour)" (click)="setFillColour(colour)"></div>
        </li>
      </ul>
    	<ul>
      	<li *ngFor="let colour of moreColours">
        	<div [style.background]="colour" [style.color]="generateTextColour(colour)"  (click)="setFillColour(colour)"></div>
        </li>
      </ul>  
    </div>