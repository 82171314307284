import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { Account } from '../../model/account'
import { AccountService } from '../../services/account.service';
import { EmitterService } from '../../../emitter.service';
import {ValidationManager} from "ng2-validation-manager";
import { User } from '../../../user/model/user';
@Component({
  selector: 'new-account-form',
  templateUrl: './account.new.component.html',
  styleUrls: ['./account.new.component.css']
})

export class NewAccountFormComponent implements OnInit {

    model = new Account('', '');
    submitted = false;
    form;
    
    ngOnInit()
    {
        this.form = new ValidationManager({
            name: 'required',
        })
    }
    
    constructor(private accountService: AccountService) {

    }
    
    onSubmit()
    {
        if(this.form.isValid()) {
            this.accountService.newAccount(this.form.getValue('name'));
            this.form.reset();
        }
    }

    clearForm()
    {
        this.form.reset();
    }

    get diagnoistics() { return JSON.stringify(this.model);}
}
