import { Component, Input } from '@angular/core';

@Component({
    selector: 'lrw-docedit-link-tooltip',
    templateUrl: './linktooltip.component.html',
    styleUrls: ['./linktooltip.component.css'],
})

export class LinkTooltipComponent {    

}