import { Component, Input, Output, EventEmitter } from '@angular/core';


@Component({
    selector: 'lrw-filter-type',
    templateUrl: './filter.type.component.html',
    styleUrls: ['./filter.type.component.css']
})

export class FilterTypeComponent {
    
    copyArray(source, dest) //copy contents of doctyles list to checkedTypes, keep reference intact
    {
        dest.length = 0;
        [].push.apply(dest, source);
    }
    
    private _doctypes: any = [];
    
    @Input('doctypes') 
    set doctypes(val: any) { this._doctypes = val; }; 
    get doctypes(): any { return this._doctypes };
    
    masterCheckStatus = "all";
    default = this.doctypes.slice();
    
    private _checkedTypes: any;
    get checkedTypes():any {
        return this._checkedTypes;
    }
    
    @Input('data') 
    set checkedTypes(value: any) {
        this._checkedTypes = value;
        this.refreshMasterCheckStatus();

    }
    
    @Output() filterChange: EventEmitter<string> = new EventEmitter();
    
    constructor() {
    }
    
    onCheckboxClick(event, doctype)
    {
        this.toggleItem(doctype);
        this.refreshMasterCheckStatus();
        this.filterChange.emit(this.checkedTypes);
    }
    
    toggleItem(doctype)
    {
        if(this.isChecked(doctype))
        {
            this.removeItem(doctype);
        }
        else
        {
            this.addItem(doctype);
        }
    }
    
    addItem(doctype)
    {
        if(this.checkIfEmpty())
        {
            this.copyArray([], this.checkedTypes);
        }
        if(this.checkedTypes.indexOf(doctype) == -1)
        {
            this.checkedTypes.push(doctype);
        }
        if(JSON.stringify(this.checkedTypes.sort()) === JSON.stringify(this.doctypes.sort()))
        {
            this.setToAll();
        }
    }
    
    removeItem(doctype)
    {
        if(this.checkIfAll())
        {
            this.copyArray(this.doctypes, this.checkedTypes);
        }
        var index = this.checkedTypes.indexOf(doctype);
        if(index != -1)
        {
            this.checkedTypes.splice(index, 1);
        }
        if(this.checkedTypes.length == 0)
        {
            this.setToEmpty();
        }
    }
    
    isChecked(doctype) {
        return this.checkIfAll() || this.checkedTypes.indexOf(doctype) != -1;
    }
    
    setToEmpty() {
        this.copyArray(["none"], this.checkedTypes);
    }
    
    checkIfEmpty() {
        return JSON.stringify(this.checkedTypes) === JSON.stringify(['none']);
    }
    
    setToAll() {
        this.copyArray([], this.checkedTypes);
    }
    
    checkIfAll() {
        return this.checkedTypes.length == 0;
    }
    
    allnone(event)
    {
        if(!this.checkIfAll())
        {
            this.setToAll();
        }
        else
        {
            this.setToEmpty();
        }
        this.refreshMasterCheckStatus();
        this.filterChange.emit(this.checkedTypes);
    }
    
    getMasterCheckStatus() {
        return this.checkIfEmpty() ? "none" : this.checkIfAll() ? "all" : "partial";
    }
    
    refreshMasterCheckStatus() {
        this.masterCheckStatus = this.getMasterCheckStatus();
    }
    
    @Output() isDefault = function() {
        return this._checkedTypes == this.default;
    }
}
