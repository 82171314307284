<div class="papersView projectPage">
    <h2 class="papersListTitle">
        Court Book Indexes
    </h2>
    <ul class="papersList">
        <li *ngFor="let paper of papers" class="papersLi">
            <a routerLink="{{paper.id}}">
                <div class="linkDiv">
                    <img class="paperIcon" src="assets/images/icons/paper.svg"> 
                    <div class="paperTitleSubtitleHolder">
                        <div class="paperTitle">
                            {{paper.title}}
                        </div>
                        <div class="paperSubtitle">
                            Last Active: {{paper.last_active | date:'fullDate'}} ({{paper.last_active | timeago:true}})
                        </div>
                    </div>
                </div>
            </a>
            <span class="deleteLink" (click)=deletePaper(paper.id)>
                <span class="bi bi-trash"></span>
            </span>
        </li>
    </ul>    

    <h2 class="papersListTitle">
        Excel Indexes
    </h2>
    <ul class="papersList">
        <li *ngFor="let index of excelIndexes" class="papersLi">
            <div class="fakeLink" (click)="getExcel(index.filename)">
                <div class="linkDiv">
                    <img class="paperIcon" src="assets/images/icons/spreadsheet-list.svg"> 
                    <div class="paperTitleSubtitleHolder">
                        <div class="paperTitle">
                            {{index.filename}}
                        </div>
                        <div class="paperSubtitle">
                            Created: {{index.created | date:'fullDate'}} ({{index.created | timeago:true}})
                        </div>
                    </div>
                </div>
            </div>
            <span class="deleteLink" (click)=deletePaper(paper.id)>
                <span class="bi bi-trash"></span>
            </span>
        </li>
    </ul>    
</div>