// from https://itnext.io/cache-components-with-angular-routereusestrategy-3e4c8b174d5f

import { ComponentRef, Injectable } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router/';
import { ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';
@Injectable()
export class CacheRouteReuseStrategy implements RouteReuseStrategy 
{
    storedRouteHandles = new Map<string, DetachedRouteHandle>();

    allowRetriveCache = 
    {
        'evidence': true
    };

    shouldReuseRoute(before: ActivatedRouteSnapshot, curr:  ActivatedRouteSnapshot): boolean 
    {

        if (this.getPath(before) === 'papers' && this.getPath(curr) === 'evidence') 
        {    
            this.allowRetriveCache['evidence'] = true;
        } else 
        {
            this.allowRetriveCache['evidence'] = false;
        }

        return before.routeConfig === curr.routeConfig;
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null 
    {
        var handle =  this.storedRouteHandles.get(this.getPath(route)) as DetachedRouteHandle;
        
        console.log("HANDLE");
        console.log(handle);
        if(handle && (handle as any).componentRef.instance["ngOnAttach"])
        {
            console.log("Has attach function");
            (handle as any).componentRef.instance.ngOnAttach()
        }

        return handle;
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean 
    {
        const path = this.getPath(route);
        if (this.allowRetriveCache[path]) 
        {
            return this.storedRouteHandles.has(this.getPath(route));
        }

        return false;
    }

    shouldDetach(route: ActivatedRouteSnapshot): boolean 
    {
        const path = this.getPath(route);
        if (this.allowRetriveCache.hasOwnProperty(path)) 
        {
            return true;
        }

        return false;
    }

    store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void 
    {
        this.storedRouteHandles.set(this.getPath(route), detachedTree);
    }

    private getPath(route: ActivatedRouteSnapshot): string 
    {
        if (route.routeConfig !== null && route.routeConfig.path !== null) 
        {
            return route.routeConfig.path;
        }

        return '';
    }
}