import { Component, OnInit, Input, Output, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, ElementRef,  ViewChild,  OnChanges,  SimpleChange, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {keymap} from 'prosemirror-keymap'
import {history} from 'prosemirror-history'
import {baseKeymap} from 'prosemirror-commands'
import {dropCursor} from 'prosemirror-dropcursor'
import {gapCursor} from 'prosemirror-gapcursor'
import {EditorView, Decoration, DecorationSet}    from "prosemirror-view"
import {Selection}    from "prosemirror-state"
import {schema} from './schema'
import {tableNodes, tableEditing } from "prosemirror-tables"
import { Observable, from, interval } from 'rxjs';
import { switchMap, takeWhile, startWith } from 'rxjs/operators';
import { uuid } from 'uuidv4';
import {Step} from "prosemirror-transform"
import {EditorState} from "prosemirror-state"
import {EditorStateConfig} from "prosemirror-state"
import {collab, receiveTransaction, sendableSteps, getVersion} from "prosemirror-collab"
import crel from "crel"
import {Reporter} from "./reporter"

import { Project_ServerService } from "../../../project_server.service";
import menu from "./menu";
import {buildKeymap} from "./keymap";
import {buildInputRules} from "./inputrules";
import {commentPlugin, commentUI, addAnnotation, annotationIcon} from "./plugins/comment";
import {linkTooltipPluginContainer} from "./plugins/linkTooltip";
import { AuthService } from "../../../auth.service";

import { ProjectService } from "../../services/project.service";
import { PaperService } from "../../services/paper.service";
import { SocketService } from "../../../socket.service";

export {buildKeymap, buildInputRules}

const report = new Reporter()

function badVersion(err) {
    return err.status == 400 && /invalid version/i.test(err)
}

class State {
    edit: any;
    comm: any;
    constructor(edit, comm) {
        this.edit = edit
        this.comm = comm
    }
}

class ItemReferenceView {
    dom: any;
    constructor(node, view, getPos) {
      this.dom = document.createElement("lrw-paper-itemreference")
      this.dom.setAttribute("projectid", node.attrs.projectid)
      this.dom.setAttribute("bates", node.attrs.bates)
      this.dom.setAttribute("id", node.attrs.id)
      this.dom.setAttribute("displayattribute", node.attrs.displayattribute)
      this.dom.setAttribute("linktogroup", node.attrs.linktogroup)
      this.dom.setAttribute("page", node.attrs.page)

      this.dom.addEventListener("replacedoc", e => {
        view.dispatch(view.state.tr.setNodeAttribute(getPos(), "bates", e.detail.newBates)); 
      })

    }
  
    stopEvent() { return true }
  }

@Component({
    selector: 'lrw-doceditor',
//    templateUrl: './doceditor.editoronly.html',
    templateUrl: './doceditor.html',
    styleUrls: ['./doceditor.css', './doceditor.css'],
    providers: [
        Project_ServerService
    ]    
})


export class DocEditorComponent implements AfterViewInit, OnDestroy
{
    @Input() public readOnly: boolean = false;
    @Input() public projectId: string;
    @Input() public paperId: string;
    @Input() public papertitle: string;
//    @Input('serverDetails')
    // set serverDetails (val: any) { 
    //     console.log("Setting server details");
    //     this._serverDetails = val; 
    //     this.start();
    // }
    // get serverDetails(): any
    // {
    //     return this._serverDetails;
    // }


    @Input() config;
    @Output() search = new EventEmitter();

    @ViewChild('prosemirrordoc', { static: true }) host;

    @Output() instance = null;
    projectName: any;
    _socket: any;
    plugins: any = [];
    zoomlevel: any =  100;
    showPageNum: any = true;
    showVersion: any = true;
    comments: any = {"comments": []};
    clientId = "";
    allIds: any;
    report: any;
    url: any;
    state: any;
    request: any;
    backOff: any;
    view: any;
    socket: any;
    shareDocumentEmail: any = "";
    shareDocumentMessage: any = "";
    generatingExport = false;
    editableRow: any;
    estimated_export_duration: any = "";

    /** 
   * Find Function, mapped to Mod-f
   * EditorState, EditorView is passed
   */
    findFunc(state, instance) 
    {
        this.search.emit({state, instance});
    }

    getPlugins(options) 
    {
        let plugins = 
            [
                buildInputRules(options.schema, this.projectService, this.projectId, this.allIds),
                keymap(buildKeymap(options.schema, options.mapKeys)),
                keymap(baseKeymap),
                dropCursor(),
                gapCursor(),
                collab(options.collabConfig),
                menu(document.getElementById("menucontainer")),
                tableEditing(),
                history(),
//                keymap({"Mod-f": () => this.findFunc(this.props, this.instance)}),
                commentPlugin,
                new linkTooltipPluginContainer(this.projectId, this.projectService).getPlugin()
            ]
        ;
        return plugins;
    }

    /**
   * Constructor
   */
    constructor(private projectService: ProjectService, private paperService: PaperService,  private projectServerService: Project_ServerService, private route: ActivatedRoute, public authService: AuthService, private socketService: SocketService) 
    {
        console.log("In the doceditor constructor");
        
        this.report = new Reporter();
        this.url = report, "/collab-backend/docs/3d457405-146e-41dc-8ee8-47e76c06d6e9" //TODO find out of this gets used. looks like it should go
        this.state = new State(null, "start")
        this.request = null
        this.backOff = 0
        this.view = null
        this.dispatch = this.dispatch.bind(this)
        this.clientId = uuid();
//        this.start();
        
        this.route.params.subscribe
        (
            params => 
            {
                console.log("Paper subscribed to route params");
                console.log(params);
                this.projectId = params['project_id'];
                this.paperId = params['paper_id'];
                
                console.log(this.projectId);
                console.log(this.paperId);
                        
                this.projectService.get(this.projectId).subscribe(
                    response => {
                        this.projectName = response.name;

                    }
                );
                
                
                this.paperService.getPapersList(this.projectId).subscribe
                (
                    data => 
                    {
                        console.log("We have the papers list")
                        console.log(data);
                        for(var i = 0; i < data.length; i++)
                        {
                            if(data[i].id == this.paperId)
                            {
                                this.papertitle = data[i].title;
                            }
                        }
                    }
                )


                this.start();
            }
        );
        
//        this.route.queryParams.subscribe
//        (
//            queryParams =>
//            {
//                if(queryParams.hasOwnProperty("readonly") && (queryParams["readonly"] == 'true' || queryParams["readonly"] == true))
//                {
//                    this.readOnly = true;
//                }
//            }
//        )
    }

    // All state changes go through this
    dispatch(action) {
        console.log("In dispatch. Type: " + action.type)
        let newEditState = null
        if (action.type == "loaded") {
//            info.users.textContent = userString(action.users) // FIXME ewww
            var pluginOptions = {schema: schema, collabConfig: {version: action.version, clientID: this.clientId}}; //action.version is the version retrieved from the server from getPaper

            type EditStateConfigWithComments = EditorStateConfig & {comments: any}

            let myConfig: EditStateConfigWithComments;
            myConfig = {
                doc: action.doc,
                plugins: this.getPlugins(pluginOptions),
                comments: action.comments
            };

            let editState = EditorState.create(myConfig)
            this.state = new State(editState, "poll")
            this.poll()
        } else if (action.type == "restart") {
            this.state = new State(null, "start")
            this.start()
        } else if (action.type == "poll") {
            this.state = new State(this.state.edit, "poll")
            this.poll()
        } else if (action.type == "recover") {
            if (action.error.status && action.error.status < 500) {
                this.report.failure(action.error)
                this.state = new State(null, null)
            } else {
                this.state = new State(this.state.edit, "recover")
                this.recover(action.error)
            }
        } else if (action.type == "transaction") {
            console.log("Applying transaction");

            for(let i = 0; i < action.transaction.steps.length; i++)
            {
                console.log(JSON.stringify(action.transaction.steps[i]))
            }

            console.log(action.transaction);
            newEditState = this.state.edit.apply(action.transaction)
        }

        if (newEditState) {
            console.log("We have a new edit state");
            let sendable
            sendable = this.sendable(newEditState);
            console.log("Here is the sendable");
            console.log(sendable);
            if (newEditState.doc.content.size > 40000) {
                console.log("in large doc part");
                if (this.state.comm != "detached") this.report.failure("Document too big. Detached.")
                this.state = new State(newEditState, "detached")
            } else if ((this.state.comm == "poll" || action.requestDone) && (sendable)) {
                console.log("!!!!! IN SEND PART!!!!!!")
                console.log("in send part");
                this.closeRequest()
                this.state = new State(newEditState, "send")
                this.send(newEditState, sendable)
                this.poll()
            } else if (action.requestDone) {
                console.log("in request done part");
                this.state = new State(newEditState, "poll")
                this.poll()
            } else {
                console.log("in last. The comm is: " + this.state.comm + " and the action.requestDone is " + action.requestDone);

                this.state = new State(newEditState, this.state.comm)
            }
        }

        // Sync the editor with this.state.edit
        if (this.state.edit) 
        {
            if (this.view)
            {
                this.view.updateState(this.state.edit)
            }
            else
            {

                    let newView = new EditorView
                    (
                        this.host.nativeElement, 
                        {
                            state: this.state.edit,
                            nodeViews: {
//                                itemreference(node, view, getPos) { return new ItemReferenceView(node, view, getPos) }
                            },
                            editable: 
                                state => 
                                {
                                    return !this.readOnly; 
                                },
                            
                            dispatchTransaction: 
                                transaction => 
                                {
                                    console.log("Apples!");
                                    this.dispatch
                                    (
                                        {
                                            type: "transaction", 
                                            transaction: transaction
                                        }
                                    )
                                }
                        }
                    );
                    this.setView(newView);
            }
        }
        else
        {
            this.setView(null)
        }
    }

    // Load the document from the server and start up
    start() {
        console.log("Starting PM")
        console.log("Paper Id Is " + this.paperId )
        
        this.setRequest
        (
            this.paperService.getPaper(this.projectId, this.paperId)
            .subscribe
            (
                data =>
                {
                    this.report.success()
                    this.backOff = 0
                    this.dispatch
                    (
                        {
                            type: "loaded",
                            doc: schema.nodeFromJSON(data.doc),
                            version: data.version,
                            users: data.users,
                            comments: {version: data.commentVersion, comments: data.comments}
                        }
                    )

                },
                err =>
                {
                    this.report.failure(err)
                }

            )
        )
    }

    // Send a request for events that have happened since the version
    // of the document that the client knows about. This request waits
    // for a new version of the document to be created if the client
    // is already up-to-date.
    poll() {
        console.log("In the poll function")
        var version = getVersion(this.state.edit);
        console.log(version);
        var commentState = commentPlugin.getState(this.state.edit);
        console.log(commentState);
        var commentVersion = commentState.version || 0;
        console.log(commentVersion);
        this.setRequest(
            this.paperService.getEvents(this.projectId, this.paperId, version, commentVersion, this.clientId)
            .subscribe
            (
                data =>
                {
                    console.log("In the poll subscribe success function")
                    this.report.success()
                    this.backOff = 0
                    if (data.steps && (data.steps.length || data.comment.length)) 
                    {
                        console.log("in the data response part");

                        console.log("Here are the steps:")
                        let receivedSteps = data.steps.map(j => Step.fromJSON(schema, j));
                        console.log(receivedSteps);
                        console.log("Here are the client ids")
                        console.log(data.clientIDs);
                        console.log("Here is our client id")
                        console.log(this.clientId);

                        if(data.version == getVersion(this.state.edit))
                        {
                            console.log("We have the correct version to apply the steps to (" + data.version + "). Appying the steps now")
                            let tr = receiveTransaction(this.state.edit, receivedSteps, data.clientIDs)
                            tr.setMeta(commentPlugin, {type: "receive", version: data.commentVersion, events: data.comment, sent: 0})
                            console.log("Oranges!");
                            this.dispatch({type: "transaction", transaction: tr, requestDone: true})
                        }
                        else
                        {
                            console.log("The version we have isn't the same as the one from the server (ours: " + getVersion(this.state.edit) + ", received: " + data.version + "). Not doing anything");

                        }
                    } else {
                        console.log("In the re-poll part")
                        this.poll()
                    }
                    //TODO: Update user count report
                    //info.users.textContent = userString(data.users)
                },
                err =>
                {
                    console.log("in the poll subscribe error function");
                    if (err.status == 410 || badVersion(err)) {
                        // Too far behind. Revert to server state
                        this.report.failure(err)
                        this.dispatch({type: "restart"})
                    } else if (err) {
                        this.dispatch({type: "recover", error: err})
                    }
                }
            )
        )
    }

    sendable(editState) {
        let steps = sendableSteps(editState)
        let comments = commentPlugin.getState(editState).unsentEvents()
        if (steps || comments.length) return {steps, comments}
    }

    // Send the given steps to the server
    send(editState, sendable) 
    {
        console.log(">>>>>>>>>################In the send function");
        //Submit Parameters
//                version: getVersion(editState),
//                steps: steps ? steps.steps.map(s => s.toJSON()) : [],
//                clientID: steps ? steps.clientID : 0,
//                comment: comments || []
        
        var steps = sendable.steps ? sendable.steps.steps.map(s => s.toJSON()) : [];
        var clientID =  sendable.steps ? sendable.steps.clientID : 0;
        var version = getVersion(editState);
        
        
        console.log("Current version is " + version);
        var comments = sendable.comments;

        this.paperService.submitSteps(this.projectId, this.paperId, version, steps, this.clientId, comments)
    }

    // Try to recover from an error
    recover(err) {
        let newBackOff = this.backOff ? Math.min(this.backOff * 2, 6e4) : 200
        if (newBackOff > 1000 && this.backOff < 1000) this.report.delay(err)
        this.backOff = newBackOff
        setTimeout(() => {
            if (this.state.comm == "recover") this.dispatch({type: "poll"})
        }, this.backOff)
    }

    closeRequest() {
        if (this.request) {
            this.request.unsubscribe()
            this.request = null
        }
    }

    setRequest(request) {
        this.request = request;
        return this.request;
    }

    close() {
        this.closeRequest()
        this.setView(null)
    }

    setView(view) {
        if (this.view) this.view.destroy()
        this.view = /*window.view =*/ view
    }
    
    /**
       * On component view init
       */
    ngAfterViewInit()
    {
        this.config = this.config || {};
        document.execCommand("enableObjectResizing", false, "false");
        document.execCommand("enableInlineTableEditing", false, "false");    
        
        
        this.socket = this.socketService.getSocket(this.projectId);

        this.socket.on("export.export_ready_for_download", (data) => {

                console.log("Export ready for download!")
                this.generatingExport = false;
                data = JSON.parse(data);
                console.log(data);
                //alert("Export " + data.exportId + " is ready for download from " + data.url);

                window.open(data.url);

                // self.projectService.downloadFromS3(data.url).subscribe(
                //     response => {
                //         //self.projectService.downloadDataAsFile(response, "application/zip", "export.zip");
                //     }
                // )

                console.log(data.url)

        });
    }

    ngOnDestroy()
    {
       console.log("DESTROYING PARENT!!");
       if (this.view) this.view.destroy();
       this.dispatch = null;
       this.state = null;
       this.socket.removeListener('export.export_ready_for_download');
    }

    zoomin() 
    {
        this.zoomlevel += 10;
        document.getElementById("page").style.transform = "scale(" + this.zoomlevel / 100 + ")";
    }

    zoomout() 
    {
        this.zoomlevel -= 10;
        document.getElementById("page").style.transform = "scale(" + this.zoomlevel / 100 + ")";
    }

    resetzoom() 
    {
        this.zoomlevel = 100;
        document.getElementById("page").style.transform = "scale(" + this.zoomlevel / 100 + ")";
    }
    
    shareDocument()
    {
        console.log("We are sharing the project");
        this.projectService.shareDocument(this.projectId, this.projectName, this.paperId, this.papertitle, this.readOnly, this.shareDocumentEmail, this.shareDocumentMessage).subscribe();
    }

    exportToDocx()
    {
        let self = this;
        console.log("exporting to Docx");
        this.generatingExport = true;
        var htmlString = document.getElementById("page").innerHTML;


        function applyStylesInline(html) {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = html;
        
            // Find all <tr> elements and iterate over them
            const trElements = tempDiv.querySelectorAll('tr');


            //Estimate the export time
            let estimatedDurationInMinutes = trElements.length / 1000 * 3; //3 min per 1000 docs
            estimatedDurationInMinutes = estimatedDurationInMinutes < 1 ? 1 : estimatedDurationInMinutes;
            estimatedDurationInMinutes = Math.round(estimatedDurationInMinutes);
            function formatMinutes(minutes) {
                if (minutes < 60) {
                    // For minutes less than 60, just display "X mins"
                    return `${minutes} min${minutes === 1 ? '' : 's'}`;
                } else {
                    // For 60 minutes or more, convert to hours and minutes
                    let hours = Math.floor(minutes / 60);
                    let remainingMinutes = minutes % 60;
                    if (hours === 1) {
                        return remainingMinutes === 0 ? '1 hr' : `1 hr ${remainingMinutes} min${remainingMinutes === 1 ? '' : 's'}`;
                    } else {
                        return remainingMinutes === 0 ? `${hours} hr${hours === 1 ? '' : 's'}` : `${hours} hr${hours === 1 ? '' : 's'} ${remainingMinutes} min${remainingMinutes === 1 ? '' : 's'}`;
                    }
                }
            }

            let estimatedDurationString = formatMinutes(estimatedDurationInMinutes)

            self.estimated_export_duration = estimatedDurationString;

        
            trElements.forEach((trElement) => {
            // Check if the <tr> has a child <td> with the class "nogridlines"
            const hasNogridlinesChild = Array.from(trElement.querySelectorAll('td.nogridlines')).length > 0;
        
            if (hasNogridlinesChild) {
                // Modify the style attribute of the <tr> element
                const currentStyle = trElement.getAttribute('style') || '';
                trElement.setAttribute('style', currentStyle + 'border: 0px;');
            }
            });
        
            // Find all <table> elements and iterate over them
            const tableElements = tempDiv.querySelectorAll('table');
        
            tableElements.forEach((tableElement) => {
            // Check if the <table> has a child <td> with the class "nogridlines"
            const hasNogridlinesChild = Array.from(tableElement.querySelectorAll('td.nogridlines')).length > 0;
        
            if (hasNogridlinesChild) {
                // Modify the style attribute of the <table> element
                const currentStyle = tableElement.getAttribute('style') || '';
                tableElement.setAttribute('style', currentStyle + 'border: 0px solid white; width:100%');
            }
            });
        

            //Set all of the normal borders to 1px solid #dddddd
            tableElements.forEach((tableElement) => {
                // Check if the <table> has a child <td> without the class "nogridlines"
                const tds = Array.from(tableElement.querySelectorAll('td:not(.nogridlines)'));

                if(tds)
                {
                    const currentStyle = tableElement.getAttribute('style') || '';
                    tableElement.setAttribute('style', currentStyle + 'border: 0.25pt solid #dddddd; width: 100%');
                }
                tds.forEach((td) => {
                    // Modify the style attribute of the <table> element
                    const currentStyle = td.getAttribute('style') || '';
                    td.setAttribute('style', currentStyle + 'border: 0.25pt solid #dddddd;');
                });
            });


            //Change the HR to a bottom border on the containing element
            let hrs = tempDiv.querySelectorAll('hr');
            hrs.forEach((hr) =>
            {
                let parent = hr.parentElement;
                parent.removeChild(hr);
                parent.innerHTML = '<table style="font-size: 1pt; border: 0px solid white; width: 100%"><tr><td style="line-height: 0px; border-bottom: 0.25pt solid #aaaaaa; border-left: 0px solid #aaaaaa; border-top: 0px solid #aaaaaa; border-right: 0px solid #aaaaaa; font-size: 1pt;"></td></tr></table>';
                // let parent = hr.parentElement;
                // let parentStyle = parent.getAttribute('style') || '';
                // parent.setAttribute('style', parentStyle + 'border-bottom: 0.25pt solid #aaaaaa; width: 100%');
            });


            let ret = tempDiv.innerHTML; 
            return ret;
        }



    function splitBrInStrong(htmlString) {
        const regex = /<strong>(.*?)<\/strong>/g;
        let modifiedHTML = htmlString.replace(regex, (match, innerContent) => {
        const brs = innerContent.split('<br>');
        const strongTags = brs.map((content) => `<strong>${content}</strong>`);
        return strongTags.join('<br>');
        });
    
        return modifiedHTML;
    }
    function splitBrInEm(htmlString) {
        const regex = /<em>(.*?)<\/em>/g;
        let modifiedHTML = htmlString.replace(regex, (match, innerContent) => {
        const brs = innerContent.split('<br>');
        const strongTags = brs.map((content) => `<em>${content}</em>`);
        return strongTags.join('<br>');
        });

        return modifiedHTML;
    }
    function splitBrInU(htmlString) {
        const regex = /<u>(.*?)<\/u>/g;
        let modifiedHTML = htmlString.replace(regex, (match, innerContent) => {
        const brs = innerContent.split('<br>');
        const strongTags = brs.map((content) => `<u>${content}</u>`);
        return strongTags.join('<br>');
        });

        return modifiedHTML;
    }





        function deleteNodeById(htmlString, nodeId) {
            // Create a temporary div element to parse the HTML string
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = htmlString;

            // Find the element with the specified ID
            const nodeToDelete = tempDiv.querySelector(`#${nodeId}`);

            // Check if the element with the given ID exists
            if (nodeToDelete) {
            // Remove the element if it exists
            nodeToDelete.parentNode.removeChild(nodeToDelete);
            }

            // Return the modified HTML as a string
            return tempDiv.innerHTML;
        }

        function deleteNodeByClass(htmlString, className) {
            // Create a temporary div element to parse the HTML string
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = htmlString;
            
            // Find all elements with the specified class
            const nodesToDelete = tempDiv.querySelectorAll(`.${className}`);
            
            // Iterate through the found elements and remove each of them
            nodesToDelete.forEach((node) => {
                node.parentNode.removeChild(node);
            });
            
            // Return the modified HTML as a string
            return tempDiv.innerHTML;
        }




        htmlString = applyStylesInline(htmlString);
        htmlString = splitBrInStrong(htmlString);
        htmlString = splitBrInEm(htmlString);
        htmlString = splitBrInU(htmlString);
        htmlString = deleteNodeById(htmlString, "itemreference-row-context-menu");
        htmlString = deleteNodeByClass(htmlString, "footer");



        this.paperService.exportToDocx(htmlString, this.projectId, this.paperId);
    }

    clickedpage(event)
    {
        console.log("Clicked Page")
        console.log(event)

        let target = event.target;

        console.log(target);

        console.log(target.classList);
        if(target.classList.contains('lrw-itemreference-anchor'))
        {
            console.log(target.getAttribute("id"))

            this.projectService.getFile(
                    target.getAttribute("projectid"), 
                    target.getAttribute("id"), 
                    target.getAttribute("linktogroup"), 
                    target.getAttribute("bates")
            ).subscribe(
                response => {
                    console.log("RESPONSE")
                    this.projectService.openDataInNewTab(response, "application/pdf", target.getAttribute("bates"));
                }
            )
        } 
        else
        {
            console.log("Didnt click an id")
            console.log(target)
        }
    }

    mouseMovedOnPage(event)
    {
        let el = document.elementFromPoint(event.pageX, event.pageY);
        let tr = getClosestParent(el, "tr");
        if(tr)
        {
            if(tr.hasAttribute("itemid"))
            {

                let trTopWithinPage = tr.getBoundingClientRect().top - document.getElementById("page").getBoundingClientRect().top


                let markerNewOffset = trTopWithinPage;
                document.getElementById("itemreference-row-context-menu").style.top = markerNewOffset + "px"
                document.getElementById("itemreference-row-context-menu").style.left = tr.getBoundingClientRect().width  + tr.getBoundingClientRect().left -  document.getElementById("page").getBoundingClientRect().left - 5 + "px"
                document.getElementById("itemreference-row-context-menu").style.visibility = "visible"
                this.editableRow = tr;
            }
        }
        else
        {
            if(!(el.id == "itemreference-row-context-menu" || getClosestParent(el, "#itemreference-row-context-menu")))
            {
                if(document.getElementById("itemreference-row-context-menu").style.visibility == "visible")
                {
                    console.log("Closing")
                    console.log(el)
                    document.getElementById("itemreference-row-context-menu").style.visibility = "hidden";
                    this.editableRow = null;
                }
            }
        }
    }

    deleteRow()
    {
        if(this.editableRow)
        {
            this.editableRow.setAttribute("command", "delete")
            // console.log("WE ARE ABOUT TO DELETE AN EDITABLE ROW")
            // console.log(this.editableRow);
            // this.editableRow.remove();
        }
    }
}


var getClosestParent = function (elem, selector) {


    // Get the closest matching element
    for ( ; elem && elem !== document; elem = elem.parentNode ) {
        if ( elem.matches( selector ) ) return elem;
    }
    return null;

};



function repeat(val, n) {
    let result = []
    for (let i = 0; i < n; i++) result.push(val)
    return result
}

let info = {
    name: document.querySelector("#docname"),
    users: document.querySelector("#users")
}

//document.querySelector("#changedoc").addEventListener("click", e => {
//    GET("/collab-backend/docs/").then(data => showDocList(e.target, JSON.parse(data)),
//                                      err => report.failure(err))
//})

function userString(n) {
    return "(" + n + " user" + (n == 1 ? "" : "s") + ")"
}




