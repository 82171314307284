import { Injectable } from '@angular/core';
//var config = require('./config');
import {configSettings} from './config';
let config = new configSettings();

@Injectable()
export class SocketService {
    socket: SocketIOClient.Socket;

    constructor() {
        console.log("constructing the socket service using the following host");
        var sockethost = config.api.sockethost;

        var self = this;

        console.log("Here is the sockethost:")
        console.log(sockethost);
        this.socket = require('socket.io-client')(sockethost, {withCredentials: true}).connect(sockethost);
        this.socket.on('connect', () => {
            console.log('SOCKET socket connected!')
            console.log(this.socket);
          })
          this.socket.on('disconnect', () => {
            console.log('SOCKET socket DISCONNECTED!')
          })

       this.socket.on('connect_error', (err) => {
            console.log('SCOCKET socket connected error --> ' + err);
          })        
    }

    public getSocket(projectId = null):SocketIOClient.Socket {
        console.log("Getting the socket");
        
        if(projectId)
        {
          console.log("emitting room join request for project id " + projectId);
          this.socket.emit('room', projectId);
        } 

        return this.socket;
    }
}