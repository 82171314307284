import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'lrw-filter-keywords',
    templateUrl: './filter.keywords.component.html',
    styleUrls: ['./filter.keywords.component.css']
})

export class FilterKeywordsComponent {
    anyOrAll = "Any";
    includeKeywords = [];
    excludeKeywords = [];
    @Output() filterChange: EventEmitter<any> = new EventEmitter();
    
    get data():any {
        var ret =
        {
            'includeKeywords': this.includeKeywords || [], 
            'includeMethod': this.anyOrAll,
            'excludeKeywords': this.excludeKeywords || [],
        }
        return ret;
    }

    @Input('data') set data(value: any) {
        this.anyOrAll = value.includeMethod;
        this.includeKeywords = value.includeKeywords;
        this.excludeKeywords = value.excludeKeywords;
    }
    
    constructor() {

    }
    
    filterInputInclude = ""
    filterInputExclude = ""
    
    addFilterInclude(newVal) {
        this.data.includeKeywords.push(newVal.toLowerCase());
        this.filterInputInclude = "";
        this.filterChange.emit(this.data);
    }
    
    removeFilterInclude(val) {
        var index = this.data.includeKeywords.indexOf(val)
        if( index != -1)
        {
            this.data.includeKeywords.splice(index, 1);
        }
        this.filterChange.emit(this.data);
    }

    addFilterExclude(newVal) {
        this.data.excludeKeywords.push(newVal.toLowerCase());
        this.filterInputExclude = "";
        this.filterChange.emit(this.data);
    }

    removeFilterExclude(val) {
        var index = this.data.excludeKeywords.indexOf(val)
        if( index != -1)
        {
            this.data.excludeKeywords.splice(index, 1);
        }
        this.filterChange.emit(this.data);
    }

    onKeyDownInclude(event)
    {
        if(event.keyCode == 13)
         {
            this.addFilterInclude(this.filterInputInclude);
         }
    }
    
    onKeyDownExclude(event)
    {
        if(event.keyCode == 13)
         {
            this.addFilterExclude(this.filterInputExclude);
         }
    }
    
    setAnyOrAll(val)
    {
        this.anyOrAll = val;
        if(this.includeKeywords.length > 0)
        {
            this.filterChange.emit(this.data);
        }
    }
}