import { Component,  Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'lrw-family-tree',
    templateUrl: './familytree.component.html',
    styleUrls: ['./familytree.component.css']
})

export class FamilyTreeComponent { 
    _item: any = {};
    _baseSelectionId: string = "";
    _secondarySelectionId: string = "";
    
    @Input('familyitem') set setitem(value: any) {
        this._item = value;
        console.log("This is my familyitem");
        console.log(this._item);
    }  
    
    @Input('baseSelectionId') set setBaseSelectionId(value: any) {
        this._baseSelectionId = value;
    }

    @Input('secondarySelectionId') set setSecondarySelectionId(value: any) {
        this._secondarySelectionId = value;
        console.log("The secondary selection id is:")
        console.log(this._secondarySelectionId);
    }  

    @Output() familyitem:any = function() {
        return this._item;
    }    

    @Output() selectionChange: EventEmitter<string> = new EventEmitter();
    
    onClick(item)
    {
        this.selectionChange.emit(item);
    }
    
    onSelectionChanged(event)
    {
        this.selectionChange.emit(event);
    }
}