import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
    
    constructor(private authService: AuthService, private router: Router){}
    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if(this.authService.loggedIn() && this.authService.isCurrentUserAuthenticated()) {
            console.log("User is logged in");
            return true;
        }
        else
        {
            this.router.navigate(['/login'], {queryParams: { returnUrl: state.url }});
            return false;
        }
    }
}