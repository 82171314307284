<div class="infoView projectPage">
    <h2>Timezone</h2>
    <div>{{display_timezone}}</div>
    <h2 class="importsListTitle">
        Imports
    </h2>

    <ul class="importsList">
        <li *ngFor="let import of imports" class="importsLi">
                <div class="linkDiv">
                    <lrw-icon [itemType]='import.type'></lrw-icon>
                    <div class="importTitleSubtitleHolder">
                        <div class="importTitle">
                            {{import.title}}
                        </div>
                        <div class="importSubtitle">
                            Imported {{import.created | date:'fullDate'}} ({{import.created | timeago: true}})
                        </div>
                    </div>
                </div>
        </li>
    </ul>    




</div>