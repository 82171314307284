import { Component, ElementRef, ViewChild, AfterViewInit, OnInit, Injector } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AdministratorComponent } from './administrator/index';
import { AuthGuard } from './auth-guard.service';
import { AuthService } from './auth.service';
import { Router, ActivatedRoute, Params, NavigationStart, NavigationEnd, Event, UrlTree } from '@angular/router';
import { createCustomElement } from '@angular/elements';

import { Project_ServerService } from "./project_server.service";
import { ProjectService } from "./project/services/project.service";
import { PaperService } from "./project/services/paper.service";
import { UserService } from "./user/services/user.service";
import { SocketService } from "./socket.service";
import { ItemReferenceComponent } from './project/components/doceditor/components/itemreference.component';


declare var jQuery:any;
var GeoPattern = require('geopattern');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
    providers: [
        Project_ServerService
    ]      
})

export class AppComponent implements AfterViewInit, OnInit {

    projectId: any = "";
    projectName: any = "";
    projectImage: any = "";
    paperId: any = "";
    paperName: any = "";
    current_route: any = "";
    notifications: any = [];
    current_user_email: any = "";
    current_user_id: any = "";
    notificationsTimer: any;
    socket: any;

    constructor
    (
        public authService: AuthService, 
        private router: Router, 
        private route: ActivatedRoute,
        private projectServerService: Project_ServerService, 
        public projectService: ProjectService,
        public userService: UserService,
        private paperService: PaperService,
        private elementRef:ElementRef,
        private socketService: SocketService,
        private _sanitizer: DomSanitizer,
        injector: Injector
    ) 
    {
        router.events.subscribe
        (
            (event: Event) =>
            {
                if(event instanceof NavigationEnd)
                {
                    this.current_user_email = this.authService.getCurrentUser() ? this.authService.getCurrentUser().email : "";
                    this.current_user_id = this.authService.getCurrentUser() ? this.authService.getCurrentUser().user_id : "";
                    this.updateCurrentRoute(this.router.url.toString());
                    this.populateNotifications();
                }
            }
        )
        console.log("Here is the current user");
        let currentUser = authService.getCurrentUser();
        let currentUserId = currentUser ? currentUser.user_id : null;
        console.log(currentUserId);

        console.log("GETTING NOTIFICATIONS")

        this.populateNotifications();

        this.socket = this.socketService.getSocket();
        this.socket.on('connect', () => {
            console.log("Socket Connected!");
            this.socket.emit('room', currentUserId);
        });
        this.socket.connect();

        this.socket.on("user.notifications.updated", () => {
            this.populateNotifications();
        })

//        const itemReferenceComponent = createCustomElement(ItemReferenceComponent, {injector});
//        customElements.define('lrw-paper-itemreference', itemReferenceComponent);

    }

    populateNotifications()
    {
        console.log("Populating Notifications")
        function copyArray(source, dest) //copy contents but keep refs intact
        {
            dest.length = 0;
            [].push.apply(dest, source);
        }                            
        
        this.userService.getUnviewedUserNotifications().subscribe
        (
            response => 
            {
                copyArray(response, this.notifications);
                this.notifications.sort((a,b) => (a.created > b.created) ? 1 : ((b.created > a.created) ? -1 : 0))
            }
        );
        console.log(this.notifications)
    }

    ngAfterViewInit() {

    }

    ngOnInit() {
    }

    logout() {
        this.current_user_email = "";
        this.authService.logout();
        this.router.navigate(['/login']);
    }

    dismissAllNotifications()
    {
        let notificationIds = [];
        for(let i = 0; i < this.notifications.length; i++)
        {
            console.log(this.notifications[i]);
            notificationIds.push(this.notifications[i].id);
        }

        this.userService.dismissUserNotifications(notificationIds).subscribe
        (
            response =>
            {
                this.populateNotifications();
            }

        );
    }

    notificationsShown()
    {
        console.log("Setting timer...");
   //     this.notificationsTimer = setTimeout(() => {this.clearNotifications();}, 3000);
    }

    notificationsHidden()
    {
        console.log("Clearing Timer");
    //    clearTimeout(this.notificationsTimer);
    }

    onActivate(event)
    {
        this.updateCurrentRoute(this.router.url.toString());
    }
    updateCurrentRoute(url)
    {
        this.current_route = "";
        var urlParts = url.toString().split("/");
        console.log(urlParts);

        if(urlParts.length == 1)
        {
            this.current_route = "projects";
        }
        if(urlParts.length == 2 && urlParts[1] == "projects")
        {
            this.current_route = "projects";
        }
        if(urlParts.length == 3 && urlParts[1] == "projects")
        {
            this.current_route = "project";
        }
        if(urlParts.length == 4 && urlParts[1] == "projects" && urlParts[3] == "info")
        {
            this.current_route = "info";
        }          
        if(urlParts.length == 4 && urlParts[1] == "projects" && urlParts[3] == "evidence")
        {
            this.current_route = "evidence";
        }                    
        if(urlParts.length == 4 && urlParts[1] == "projects" && urlParts[3] == "papers")
        {
            this.current_route = "papers";
        }                    
        if(urlParts.length == 5 && urlParts[1] == "projects" && urlParts[3] == "papers")
        {
            this.current_route = "paper";
        }                    


        console.log("Routed to " + this.current_route);

        //Get projet Id
        if(urlParts[1] == "projects" && urlParts.length > 1)
        {
            if(this.projectId != urlParts[2])
            {
                this.projectId = urlParts[2];
                this.projectName = "";
                this.projectImage = "";
            }

            console.log("Project Id is: " + this.projectId);
            this.projectService.get(this.projectId).subscribe
            (
                response => 
                {
                    this.projectName = response.name;
                    this.projectImage = this._sanitizer.bypassSecurityTrustStyle(GeoPattern.generate(this.projectId).toDataUrl());
                    console.log("Project name is: " + this.projectName);
                }
            );
        }
        else
        {
            this.projectId = "";
            this.projectName = "";
        }

        //Get paper id
        if(this.current_route == "paper")
        {
            this.paperId = urlParts[4];
            this.paperName = "Loading ...";
            this.paperService.getPapersList(this.projectId).subscribe
            (
                response => 
                {
                    for(var i = 0; i < response.length; i++)
                    {
                        if(this.paperId == response[i].id)
                        {
                            this.paperName = response[i].title;
                        }
                    }
                }
            );
        }
        else
        {
            this.paperId = "";
            this.paperName = "";
        }
    }

    reload()
    {
        document.location.reload();
    }


}