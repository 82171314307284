import { Component } from '@angular/core';
import { AuthService } from '../../../auth.service';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'login',
    templateUrl: './administrator.login.component.html',
    styleUrls: ['./administrator.login.component.css']
})

export class LoginComponent {
    constructor(public authService: AuthService, private router: Router, private route: ActivatedRoute) {}
    
    onLogin(credentials: NgForm) {
        console.log(credentials);
        this.authService.login(credentials, this.route.snapshot.queryParams['returnUrl'] || '/');
    }
}