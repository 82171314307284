import { Component, OnInit, Input, Output, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter } from '@angular/core';

@Component({
    selector: 'lrw-colourpicker',
    templateUrl: './colourpicker.html',
    styleUrls: ['./colourpicker.css'],
})

export class ColourpickerComponent{    
    
    @Input() fillColour: string;
    @Input() textColour: string;
    @Output() fillColourChange = new EventEmitter<string>();
    @Output() textColourChange = new EventEmitter<string>();
    @Output() close = new EventEmitter();

    setFillColour(colour) {
        this.fillColour = colour;
        this.fillColourChange.emit(this.fillColour);
        this.textColour = this.generateTextColour(this.fillColour);
        this.textColourChange.emit(this.textColour);
        this.close.emit();
    }
    
    hex2rgb(hex) {
        hex = hex.replace('#', '');
        return [
            parseInt(hex.substring(0,2), 16), 
            parseInt(hex.substring(2,4), 16),
            parseInt(hex.substring(4,6), 16)
        ]
    }
    
    getLuma(col)
    {
        var rgb = this.hex2rgb(col);
        //return (rgb[0] + rgb[0] + rgb[1] + rgb[2] + rgb[2] + rgb[2]) / 6;
        return ((rgb[0] * 299) + (rgb[1] * 587) + (rgb[2] * 144)) / 1000;
    }
    
    generateTextColour(colour)
    {
        return this.getLuma(colour) < 160 ? 'rgba(255,255,255,0.7)' : 'rgba(0,0,0,0.6)';
    }
    
    basicColours = 
    ["#970000",
    "#FB0000",
    "#FB9700",
    "#FDFD00",
    "#64FF64",
    "#00FFFF",
    "#4687E7",
    "#0001FB",
    "#9900FF",
    "#FE00FC"]

    moreColours = 
    ["#E9B5AC",
    "#F7CACC",
    "#FFE2CA",
    "#FFF1C9",
    "#D8EACE",
    "#D1DEE0",
    "#C5DBFB",
    "#CBE3F6",
    "#DCD1F0",
    "#EBCDD7",
    "#DD7D6A",
    "#E99998",
    "#F9C999",
    "#FFE598",
    "#B7D7A8",
    "#A3C1C6",
    "#A3C0F3",
    "#9FC6E7",
    "#B3A6D4",
    "#D9A4BE",
    "#CF421E",
    "#E1636C",
    "#FCAF71",
    "#FBDB69",
    "#99C184",
    "#7AA1B0",
    "#64A2E4",
    "#68A9E2",
    "#8C7AC8",
    "#C278A1",
    "#A71A00",
    "#CC0001",
    "#E79137",
    "#EFC332",
    "#6BA64F",
    "#43828D",
    "#3A78D7",
    "#3E84C7",
    "#674DA6",
    "#A24F79",
    "#85210A",
    "#980000",
    "#B35E04",
    "#BE9100",
    "#39751D",
    "#11505A",
    "#1054CC",
    "#0C5395",
    "#341C74",
    "#701C47",
    "#581000",
    "#670000",
    "#723F05",
    "#845D00",
    "#264E09",
    "#093739",
    "#1B4884",
    "#063A5D",
    "#1C114D",
    "#4F0C32",]
}