import { Component } from '@angular/core';
import { AuthService } from '../auth.service';
import { NgForm } from '@angular/forms';
import { AccountService } from 'app/account/services/account.service';

@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})

export class HomeComponent {
    current_user_email: any = "";
    newWorkspaceName: any = "";
    constructor
    (
        private authService: AuthService,
        private accountService: AccountService
    ) 
    {
        this.current_user_email = this.authService.getCurrentUser() ? this.authService.getCurrentUser().email : "";
    }

    createWorkspace(newWorkspaceName)
    {
        this.accountService.newAccount(newWorkspaceName);
        newWorkspaceName = "";
    }
}