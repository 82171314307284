<!--
<div class="itemheader">
    <lrw-icon id="icon" *ngIf='item && item.attributes' [itemType]='item && item.attributes && item.attributes["summary/type"]'></lrw-icon><span>{{item && item.attributes && item.attributes['summary/title']}}</span>
</div>
-->
<as-split direction="vertical" [gutterSize]="1" class="itemviewer-item-splitter" *ngIf="previewStatus != 'itemEmpty'" (dragEnd)="onDragEnd($event)">
    <as-split-area [size]="8" class="familyTreeSplitArea">
        <!-- <div class="categoryheader">
            Family
        </div> -->
        <div class="familyTreeContainer" [ngClass]="{hScrollbar: familyTreeHScrollbar}" #familyTreePane>
            <div class="relativeContainer" [style.width.px]="familyTreeWidth" [style.height.px]="familyTreeHeight">
                <lrw-family-tree  *ngIf="itemFamilyNested" [familyitem]="itemFamilyNested" [secondarySelectionId]="familyTreeSelectedItemId" [baseSelectionId]="item.id" (selectionChange)="onFamilyTreeSelectionChanged($event)"></lrw-family-tree>
                <div class="svgContainer">
                    <svg class="linkSvg">
                        <g class="links"></g>
                        <g class="dots"></g>
                    </svg>
                </div>
            </div>
        </div>
    </as-split-area>
    <as-split-area [size]="69" class="pdfViewerSplitArea">
        <div class="itemViewer">
            <lrw-spinner *ngIf="previewStatus == 'checkingIfPreviewAvailable' || previewStatus == 'loadingPreview'" [@enterAnimation] ></lrw-spinner>
            <div class="pdfViewerContainer" *ngIf="thumbnailUrl != ''" [xxhidden]="previewStatus != 'previewLoaded'">
                <div class="pageNavStrip"  *ngIf="pdfNumPages > 0 && previewStatus == 'previewLoaded'" [xxhidden]="previewStatus != 'previewLoaded'">
                    <!-- <span class="categoryheader">
                        Preview
                    </span> -->
                    <div class="prev" *ngIf="pdfNumPages > 1" (click)="prevPage()"><span class="bi bi-chevron-left">&nbsp;</span></div>
                    <div class="pageNum">Page {{pdfCurrentPage}} of {{pdfNumPages}}</div>
                    <div class="next" *ngIf="pdfNumPages > 1" (click)="nextPage()"><span class="bi bi-chevron-right">&nbsp;</span></div>
                    <a *ngIf="thumbnailUrl" target="_blank" (click)="openPreview()" class="fullScreenLink"><div class="bi bi-arrows-fullscreen">&nbsp;</div></a>
                </div>
                <div class="pdfViewerSubContainer" [ngClass]="{invisible: previewStatus != previewLoaded}" >
                    <pdf-viewer 
                    #pdfViewer 
                    [src]="pdfData" 
                    [page]="pdfCurrentPage" 
                    [render-text]="true" 
                    [original-size]="false" 
                    [autoresize]="true" 
                    [fit-to-page]="true" 
                    [show-all]="false" 
                    (after-load-complete)="afterPdfLoaded($event, thumbnailUrl)" 
                    (after-load-complete)="onProgress($event, thumbnailUrl)" 
                    (page-rendered)="pageRendered" 
                    (text-layer-rendered)="textLayerRendered"
                    (error)="onPdfError($event, thumbnailUrl)"
                    (on-progress)="onProgress($event)"
                    >
                     &nbsp;</pdf-viewer>
                </div>
            </div>

            <div class="pdfViewerPlaceholder" *ngIf="previewStatus == 'previewNotAvailable'">
                <img src='/assets/images/no-image.png'/>
                <div>No preview available</div>
            </div>

            <div class="pdfViewerPlaceholder" *ngIf="previewStatus == 'error'">
                <img src='/assets/images/no-image.png'/>
                <div>Error displaying preview</div>
            </div>

        </div>
    </as-split-area>
    <as-split-area [size]="23" class="itemViewerSplitArea">
        <ul class="category" *ngIf="previewStatus != 'itemEmpty'">
            <li class="category" *ngIf="'summary'; let category">
                <span class="categoryheader" data-bs-toggle="collapse" [attr.data-bs-target]="'#attributeTable' + category">{{category}}</span>
                <div class="attributeTableContainer collapse show in" [id]="'attributeTable' + category">
                    <table class='attribute' *ngIf="getKeys(groupedAttributes[category]).length > 0">
                            <tr class="attribute">
                                <td><span>tags</span></td>
                                <td>
                                    <span>
                                        <lrw-tagicon *ngFor="let tag of subItem.tag_ids | sortedTags: tags" [tag]="tag"></lrw-tagicon>
                                    </span>
                                </td>
                            </tr>    
                            <tr class="attribute">
                                <td><span>files</span></td>
                                <td>
                                    <span>
                                    <a *ngFor="let file of subItem.files" (click)="downloadFile(subItem, file.group_name)" class="fileLink" target="_blank">{{file.group_name}}</a>
                                </span>
                                </td>
                            </tr>                                                
                            <tr class="attribute">
                                <td><span>title</span></td>
                                <td><span>{{groupedAttributes[category]['title']}}</span></td>
                            </tr>
                            <tr class="attribute">
                                <td><span>bates Id</span></td>
                                <td><span>{{groupedAttributes[category]['bates']}}</span></td>
                            </tr>                           
                            <tr class="attribute">
                                <td><span>type</span></td>
                                <td><span>{{groupedAttributes[category]['type']}}</span></td>
                            </tr>                        
                            <tr class="attribute">
                                <td><span>date</span></td>
                                <td>
                                    <lrw-attribute-value 
                                        [attribute-name]="'date[date]'" 
                                        [attribute-value]="groupedAttributes[category]['date[date]']" 
                                        [display-timezone]="displayTimezone"
                                    >
                                    </lrw-attribute-value>
                                </td>
                            </tr>                        
                            <tr class="attribute">
                                <td><span>from</span></td>
                                <td><span>{{groupedAttributes[category]['from']}}</span></td>
                            </tr>                        
                            <tr class="attribute">
                                <td><span>to</span></td>
                                <td><span>{{groupedAttributes[category]['to']}}</span></td>
                            </tr>
                            <tr class="attribute">
                                <td><span>notes</span></td>
                                <td>
                                    <textarea id="notesinput" class="form-control section-input inset-input inline-editing" type="text" placeholder="enter notes" [(ngModel)]="notesText"  (ngModelChange)="onNotesChange()">
                                        </textarea>
                                </td>
                            </tr>
                    </table>
                </div>
            </li>
            <li class="category" *ngIf="'application'; let category">
                <span class="categoryheader" data-bs-toggle="collapse" [attr.data-bs-target]="'#attributeTable' + category">{{category}}</span>
                <div class="attributeTableContainer collapse" [id]="'attributeTable' + category">
                    <table class='attribute'>
                            <tr *ngFor="let partialAttributeName of getKeys(groupedAttributes[category])" class="attribute">
                                <td>{{partialAttributeName | AttributeNamePipe}}</td>
                                <td>
                                    <lrw-attribute-value [attribute-name]="partialAttributeName" [attribute-value]="groupedAttributes[category][partialAttributeName]" [display-timezone]="displayTimezone"></lrw-attribute-value>
                                </td>
                            </tr>
                    </table>
                </div>
            </li>            
            <li class="category" *ngIf="'attributes'; let category">
                <span class="categoryheader" data-bs-toggle="collapse" [attr.data-bs-target]="'#attributeTable' + category">{{category}}</span>
                <div class="attributeTableContainer collapse" [id]="'attributeTable' + category">
                    <table class='attribute'>
                            <tr *ngFor="let partialAttributeName of getKeys(groupedAttributes[category])" class="attribute">
                                <td>{{partialAttributeName | AttributeNamePipe}}</td>
                                <td>
                                    <lrw-attribute-value [attribute-name]="partialAttributeName" [attribute-value]="groupedAttributes[category][partialAttributeName]" [display-timezone]="displayTimezone"></lrw-attribute-value>
                                </td>
                            </tr>
                    </table>
                </div>
            </li>            
            
<!--
            <li *ngFor="let category of getKeys(groupedAttributes)" class="category">
                <span class="categoryheader" data-bs-toggle="collapse" [attr.data-bs-target]="'#attributeTable' + category">{{category}}</span>
                <div class="attributeTableContainer collapse in" [id]="'attributeTable' + category">
                    <table class='attribute'>
                            <tr *ngFor="let partialAttributeName of getKeys(groupedAttributes[category])" class="attribute">
                                <td>{{partialAttributeName}}</td>
                                <td>{{groupedAttributes[category][partialAttributeName]}}</td>
                            </tr>
                    </table>
                </div>
            </li>
-->
        </ul>
    </as-split-area>
</as-split>