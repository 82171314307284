import { User } from '../../user/model/user';
import { SafeStyle } from '@angular/platform-browser';

export class Project {
    public users: User[];
    constructor (
        public id: string,
        account_id: string,
        public name: string,
        public backgroundImage: SafeStyle
    ){}
}