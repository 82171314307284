import { Component } from '@angular/core';
import { EmitterService } from '../emitter.service';

@Component({
    selector: 'administrator-widget',
    template: `
        <div>
            AdministratorComponent
        </div>

        `
})

export class AdministratorComponent {
}