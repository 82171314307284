<div class="itemContainer">
    <div class="itemSelf" *ngIf="_item.children || _item.parent" [ngClass]="{'baseSelection': _item.id == _baseSelectionId, 'secondarySelection': _item.id == _secondarySelectionId}" (click)="onClick(_item)">
        <lrw-icon [itemType]='_item.attributes["summary/type"]'></lrw-icon><span class="documentTitle">{{_item.attributes["summary/title"]}}</span>
    </div>
    <ul class="itemChildren" *ngIf="_item.children && _item.children.length > 0">
        <li class="childrenListItem" *ngFor="let child of _item.children">
            <lrw-family-tree [familyitem]="child" [baseSelectionId]="_baseSelectionId" [secondarySelectionId]="_secondarySelectionId" (selectionChange)="onSelectionChanged($event)"></lrw-family-tree>
        </li>
    </ul>
</div>
