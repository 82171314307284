import {schema} from "./schema";
import {Fragment} from "prosemirror-model"
import {addAnnotation} from "./plugins/comment"

function typeSupportsAlign(type)
{
    if("attrs" in type)
    {
        if("align" in type.attrs)
        {
            return true;
        }
    }
    return false;
}

export function setAlign(align) {
    return function(state, dispatch) 
    {
        var pm = state;
        let tr = pm.tr;
        pm.doc.nodesBetween(pm.selection.from, pm.selection.to, (node, pos) => {
            if(typeSupportsAlign(node.type) && node.attrs.align != align)
            {
                if(dispatch)
                {
                    var newAttrs = Object.assign({}, node.attrs);
                    newAttrs.align = align;
                    tr.setNodeMarkup(pos, node.type, newAttrs);
                }
            }
        });
        if(dispatch)
        {
            console.log("Sending dispatch from setAlign");
            dispatch(tr);   
        }
        
        return true;
    }
}

export function insertHorizontalRule() {
    return function(state, dispatch) 
    {
        var pm = state;
        let tr = pm.tr;
        tr.replaceSelectionWith(schema.nodes.horizontal_rule.create());
        if(dispatch)
        {
            console.log("Sending dispatch from insertHorizontalRule");
            dispatch(tr);   
        }
        
        return true;
    }
}

export function addComment() {
    return function(state, dispatch) 
    {
        addAnnotation(state, dispatch);
        return true;
    }
}


//Copied from prosemirror-utils and modified to take attributes
var tableNodeTypes = function tableNodeTypes(schema) {
  if (schema.cached.tableNodeTypes) {
    return schema.cached.tableNodeTypes;
  }
  var roles = {};
  Object.keys(schema.nodes).forEach(function (type) {
    var nodeType = schema.nodes[type];
    if (nodeType.spec.tableRole) {
      roles[nodeType.spec.tableRole] = nodeType;
    }
  });
  schema.cached.tableNodeTypes = roles;
  return roles;
};

var createTable = function createTable(schema, rowsCount, colsCount, attributes, withHeaderRow) {

  var _tableNodeTypes = tableNodeTypes(schema),
      tableCell = _tableNodeTypes.cell,
      tableHeader = _tableNodeTypes.header_cell,
      tableRow = _tableNodeTypes.row,
      table = _tableNodeTypes.table;

  var cells = [];
  for (var i = 0; i < colsCount; i++) {
    cells.push(tableCell.createAndFill(attributes));
  }

  var headerCells = [];
  if (withHeaderRow) {
    for (var _i2 = 0; _i2 < colsCount; _i2++) {
      headerCells.push(tableHeader.createAndFill(null));
    }
  }

  var rows = [];
  for (var _i3 = 0; _i3 < rowsCount; _i3++) {
    rows.push(tableRow.createChecked(null, withHeaderRow && _i3 === 0 ? headerCells : cells));
  }

  return table.createChecked(attributes, rows);
};

export function insertNewTable(rows, columns, attrs) {
    return function(state, dispatch) 
    {
        var nodeType = schema.nodes.table;
        var pm = state;
        let tr = pm.tr;
        var table = createTable(state.schema, rows, columns, attrs, false);
        tr.replaceSelectionWith(table);
        
        
        if(dispatch)
        {
            console.log("Sending dispatch from insertNewTable");
            dispatch(tr);   
        }
        
        return true;
    }
}

export function printJson() {
    return function(state, dispatch) 
    {
        console.log(state);
        console.log(JSON.stringify(state.selection));
        return true;
    }
}