import { Component, OnInit, Input, Output, EventEmitter, HostListener, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, Pipe, ElementRef } from '@angular/core';
import { Observable } from 'rxjs';
import { Project_ServerService } from "../../../project_server.service";
import { ProjectService } from "../../services/project.service";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { trigger, style, animate, transition } from '@angular/animations';
import * as _ from "lodash";
import * as d3 from "d3";
import { AuthService } from '../../../auth.service'
import { HttpHeaders } from '@angular/common/http';
import { Headers } from '@angular/http';
import { PdfViewerComponent, PDFProgressData, PDFSource } from 'ng2-pdf-viewer';
import { fileURLToPath } from 'url';
let moment = require('moment-timezone');

@Component({
    selector: 'lrw-item-viewer',
    animations: [
        trigger(
            'enterAnimation', [
                transition(':enter', [
                    style({opacity: 0}),
                    animate('200ms 500ms', style({opacity: 1}))
                ])
            ]
        )
    ],
    templateUrl: './itemviewer.html',
    styleUrls: ['./itemviewer.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ItemViewerComponent {
    _tags: any;
    _item: any;
    _subItem: any;
    itemFamily: any = {};
    itemFamilyNested: any = {data: {}};
    familyTreeWidth: any = 0;
    familyTreeHeight: any = 0;
    selectedItemHPos: any = 0;
    nodeHeight:any = 24; //SHould be in sync with the CSS style for the node
    nodeWidth:any = 180;
    d3nodes:any = {};
    thumbnailUrl: any = "";
    pdfNumPages: any;
    pdfCurrentPage: any;
    socket: any;
    familyTreeHScrollbar: boolean = false;
    familyTreeVScrollbar: boolean = false;
    @ViewChild('familyTreePane', { static: true }) familyTreePane: ElementRef;
    @ViewChild('pdfViewer', { static: false }) pdfViewer: PdfViewerComponent;
    familyTreeSelectedItemId: any;
    srcOptions = {};
    pdfData = {};
    previewLoaded:any = "previewLoaded";
    notesText = "";

    previewStatus = "itemEmpty"; //itemEmpty, checkingIfPreviewAvailable, previewNotAvailable, loadingPreview, previewLoaded
    groupedAttributes = {};

    constructor(
        private projectService: ProjectService, 
        private sanitizer: DomSanitizer, 
        private ref: ChangeDetectorRef,  
        private authService: AuthService
        ) {

    }

    @Input() public projectId: string;
    @Input() public displayTimezone: string;
    @Input() public keywords: string; //Space separated list of keywords. Any multi-word keyword need a %20 as the space. E.g. "red%20apples recipes"
    private _serverDetailsObservable: any;
    private _serverDetails: any;
    @Input('serverDetails')
    set serverDetails (val: any) { 


        console.log("Item Viewer: Setting the server details");

        this._serverDetails = val;

        console.log(this._serverDetails);

        //        if(this._serverDetails)
        //        {
        //            this.socket = this.projectService.establishSocket(this._serverDetails);
        //            let self = this;
        //            self.socket.on('connect', function() {
        //                console.log("Socket Connected!");
        //                self.socket.emit('room', self.projectId);
        //            });
        //            this.socket.connect();
        //
        //            this.socket.on("tag.added", function(data) {
        //                this.ref.markForCheck();
        //            });
        //        }
    }

    get serverDetails():any {
        return this._serverDetails;
    }


    @Output() principalChange: EventEmitter<string> = new EventEmitter();

    @Input('item') set item(value: any) {
        console.log ("!!!!We have selected a new item " + Date.now())
        console.log(value)
        this._item = {};
//        this.refreshFamilyTree();
//        this.refreshSubItem();
        this.debouncedSetItem(value);
    }
    
    setItem(value)
    {
        this._item = value;
        this.pdfData = {};
        this.refreshFamilyTree();

        this._subItem = value;
        this.notesText = this._subItem.attributes['summary/notes'];
        this.refreshSubItem();
    }
    
    debouncedSetItem=_.debounce(this.setItem, 150, {leading: true, trailing: true});

    set subItem(value: any) {
        this._subItem = value;
        this.refreshSubItem();
    }

    copyArray(source, dest) //copy contents of doctyles list to checkedTypes, keep reference intact
    {
        dest.length = 0;
        [].push.apply(dest, source);
    }

    @Input('itemDataUpdate') set itemDataUpdate(itemId: any) {
        console.log("In ItemDataUpdate Check");

        this.refreshSubItemTags();

        this.ref.markForCheck();
        this.ref.detectChanges();
    

    }

    get item() : any
    {
        return this._item;
    }
    get subItem() : any
    {
        return this._subItem;
    }
    @Input('tags')
    set tags (val: any) { this._tags = val;}
    get tags() : any
    {
        return this._tags;
    }

    getKeys(item)
    {
        if(item)
        {
            return Object.keys(item);
        }
        else
        {
            return [];
        }
    }

    isEmptyObject(obj)
    {
        return (obj && (Object.keys(obj).length == 0));
    }

    isIdInArray(arr, id)
    {
        for(let i = 0; i < arr.length; i++)
        {
            if(arr[i].id == id)
            {
                return true;
            }
        }
        return false;
    }

    itemOrChildrenMatchId(item, id, ctx)
    {
        if(item.children && item.children.length > 0)
        {
            for(let i = 0; i < item.children.length; i++)
            {
                if(ctx.itemOrChildrenMatchId(item.children[i], id, ctx))
                {
                    return true;
                }
            }
        }

        if(item.id == id)
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    sortNestChildren(item, id, ctx)
    {
        if(item && item.children && item.children.length > 0)
        {
            for(let i = 0; i < item.children.length; i++)
            {
                ctx.sortNestChildren(item.children[i], id, ctx);
            }
            
            item.children.sort(function (a, b) {
                return a.attributes["summary/title"].localeCompare(b.attributes["summary/title"]);
            });
            
            item.children.sort(function (a, b) {
                if(ctx.itemOrChildrenMatchId(b, id, ctx))
                {
                    return 1;
                }
                else if(ctx.itemOrChildrenMatchId(a, id, ctx))
                {
                    return -1;
                }
                else
                {
                    return 0;
                }
            });
        }
    }
    
    nestDocuments(array){
        console.log("nesting documents");
        let map = {}
        for(let i = 0; i < array.length; i++){
            let obj = array[i]
            if(!(obj.id in map)){
                map[obj.id] = obj
                map[obj.id].children = []
            }

            if(typeof map[obj.id].attributes == 'undefined'){
                map[obj.id].id = obj.id
                map[obj.id].attributes = obj.attributes
                map[obj.id].files = obj.files
                map[obj.id].index = obj.index
                map[obj.id].tag_ids = obj.tag_ids
            }

            let parent = (obj.attributes['application/parent_id'] && this.isIdInArray(array, obj.attributes['application/parent_id'])) ?  obj.attributes['application/parent_id'] : 'root_element';
            if(!(parent in map)){
                map[parent] = {}
                map[parent].children = []
            }
            console.log("pushing a child to parent")
            map[parent].children.push(map[obj.id])
        }
        this.sortNestChildren(map['root_element'], this.item.id, this);
        return map['root_element'].children[0];
    }

    refreshFamilyTree()
    {
        this.itemFamily = {};
        this.itemFamilyNested = {};
        this.familyTreeSelectedItemId = this.item.id;
        console.log("In Refresh Family Tree");

        //Clear out the existing family nodes
        let linksSelector = d3.select('svg g.links')
        .selectAll('path.link')
        .data([]).exit().remove();
        let dotsSelector = d3.select('svg g.dots')
        .selectAll('circle.dot')
        .data([]).exit().remove();

        if(this.projectId && this.item.id)
        {
            this.projectService.getFamilyItems(this.projectId, this.item.id).subscribe
            (
                response => {
                    console.log(this._item);
                    console.log(response.id);
                    if(!response.id || this._item.id != response.id)
                    {
                        return;
                    }

                    this.itemFamily = response.familyItems || [];
                    this.itemFamilyNested = this.nestDocuments(this.itemFamily);
                    console.log("Here is the item family nested");
                    console.log(this.itemFamilyNested);
    
                    function treemapDice(parent, x0, y0, x1, y1, nodeHeight, containerGutter) {
                        let nodes = parent.children,
                            node,
                            i = -1,
                            n = nodes.length;
    
                        while (++i < n) {
                            node = nodes[i];
                            node.y0 = y0;
                            node.y1 = y1;
                            x0 += i > 0 && nodes[i - 1].children ? containerGutter : 0;
                            node.x0 = x0;
                            x0 += node.value * nodeHeight;
                            node.x1 = x0;
                        }
                    }
    
                    function partition(root, padding, nodeHeight, nodeWidth, containerGutter, paddingX, paddingY) {
                        root.x0 = 0;
                        root.y0 = 0;
                        root.x1 = (root.value * nodeHeight);
                        root.y1 = nodeWidth;
                        root.eachBefore(positionNode(nodeWidth, padding, nodeHeight, containerGutter, paddingX, paddingY));
                        return root;
                    }
    
                    function positionNode(nodeWidth, padding, nodeHeight, containerGutter, paddingX, paddingY) {
                        return function(node) {
                            if (node.children) {
                                treemapDice(node, node.x0, (nodeWidth + paddingX) * (node.depth + 1), node.x1, (nodeWidth + paddingX) * (node.depth + 2) - paddingX, nodeHeight + paddingY, containerGutter);
                            }
                            let x0 = node.x0,
                                y0 = node.y0,
                                x1 = node.x1,
                                y1 = node.y1;
                            if (x1 < x0) x0 = x1 = (x0 + x1) / 2;
                            if (y1 < y0) y0 = y1 = (y0 + y1) / 2;
                            node.x0 = x0;
                            node.y0 = y0;
                            node.x1 = x1;
                            node.y1 = y1;
                        };
                    }
    
                    console.log("data");
                    console.log(this.itemFamilyNested);
                    this.itemFamilyNested
                    this.d3nodes = d3.hierarchy(this.itemFamilyNested);
                    this.d3nodes.count(function(d) {return d.value;});
                    console.log("rootnode");
                    console.log(this.d3nodes);
                    let nodeHeight = this.nodeHeight;
                    let padding = 0;
                    let paddingX = 10;
                    let paddingY = 10;
                    let containerGutter = 10; // Match this with css item container margin bottom
                    let partitionLayout = partition(this.d3nodes, padding, this.nodeHeight, this.nodeWidth, containerGutter, paddingX, paddingY);
    
                    let linksSelector = d3.select('svg g.links')
                    .selectAll('path.link')
                    .data(this.d3nodes.descendants())
                    ;
                    linksSelector.exit().remove();
                    linksSelector.enter().append('path')
                        .classed('link', true)
                        .attr('d', function(d) {
                        if (d.parent) {
                            let startX = d.parent.y1;
                            let startY = d.parent.x0 + nodeHeight / 2;
                            let elbow1X = startX;
                            let elbow1Y = startY;
                            let elbow2X = elbow1X;
                            let elbow2Y = d.x0 + nodeHeight / 2;
                            let endX = d.y0;
                            let endY = elbow2Y;
                            return "M" + /*startX + "," + startY + "," + */ elbow1X + "," + elbow1Y + "," + elbow2X + "," + elbow2Y + "," + endX + "," + endY;
                        } else {
                            return "M0,0"
                        }
                    }) 
                    
                    //Add dots
                    let dotsSelector = d3.select('svg g.dots')
                    .selectAll('circle')
                    .data(this.d3nodes.descendants());
                    dotsSelector.exit().remove();
                    dotsSelector.enter().append('circle')
                        .classed('dot', true)
                        .attr("cx", function(d) { return d.y1; })
                        .attr("cy", function(d) { return d.x0 + (nodeHeight / 2);})
                        .attr("r", function(d) { return (d.children) ? 4 : 0;})
    
                    //Calculate the width and the height and scroll position
                    this.familyTreeHeight = 0;
                    this.familyTreeWidth = 0;
                    this.d3nodes.descendants().forEach (d => {
                        let nodeEndX;
                        let nodeEndY;
                        let endPadding = 2;
    
                        nodeEndX = d.y1 + endPadding;
                        nodeEndY = d.x1 + endPadding;
    
                        this.familyTreeHeight = Math.max(nodeEndY, this.familyTreeHeight);
                        this.familyTreeWidth = Math.max(nodeEndX, this.familyTreeWidth);
    
                        if(d.data.id == this.item.id)
                        {
                            this.selectedItemHPos = d.y0;
                        }
                    })
                    this.familyTreeWidth += 10; //right 'padding'
                    console.log("New family tree width and height: " + this.familyTreeWidth + " " + this.familyTreeHeight);
                    this.ref.detectChanges();
                    let scrollLeft = this.selectedItemHPos + 90 - (this.familyTreePane.nativeElement.clientWidth / 2);
                    console.log(scrollLeft);
                    this.familyTreePane.nativeElement.scrollLeft = scrollLeft;
                }
            );
        }
    }
    
    refreshSubItem() 
    {
        console.log ("!!!!Start of refresh subitem  " + Date.now())
        let self = this;

        this.previewStatus = "checkingIfPreviewAvailable";
        this.groupedAttributes = {};
        this.thumbnailUrl = "";
        this.ref.detectChanges(); //force the thumbnailUrl="" change to get registered so that if we load the same URL (same md5) again, it get's loaded as per normal
        this.pdfNumPages = 0;
        this.pdfCurrentPage = 0;
        let itemKeys = this.getKeys(this.subItem ? this.subItem.attributes : {});
        for(let i = 0; i < itemKeys.length; i++)
        {
            let fullAttributeName = itemKeys[i];
            if(fullAttributeName.indexOf("/") != -1)
            {
                let split = [fullAttributeName.substring(0,fullAttributeName.indexOf("/")), fullAttributeName.substring(fullAttributeName.indexOf("/"))];
                let category = split[0].replace(" structure", "structure").replace("atttribute", "attribute"); //Only needed for my junky test data
                let partialAttributeName = split[1].substring(1); //get rid of the slash at the front
                partialAttributeName = partialAttributeName.replace(/\//g, " ▸ ");
                partialAttributeName = partialAttributeName.replace(/_/g, " ");

                if(!this.groupedAttributes.hasOwnProperty(category))
                {
                    this.groupedAttributes[category] = {};
                }

                this.groupedAttributes[category][partialAttributeName] = this.subItem.attributes[fullAttributeName];
            }
        }

        if(this.isEmptyObject(this.subItem)) {
            this.previewStatus = "itemEmpty";
        }
        else if(this.subItem.files)
        {
            console.log("Before Sort");
            console.log(this.subItem.files);
            this.subItem.files = this.subItem.files.sort(function(a, b) { 
                return a.group_name.localeCompare(b.group_name);
            });
            console.log("after Sort");
            console.log(this.subItem.files);                     
            let hasPreview = false;

            for(let j = 0; j < this.subItem.files.length; j++)
            {
                if(this.subItem.files[j].group_name.toLowerCase() == "preview")
                {
                    hasPreview = true;
                    self.thumbnailUrl = "loading";

                   // console.log ("!!!!About to try to get the file access url  " + Date.now())

                    (function(self, projectId, itemId) {
                        self.projectService.getPdfData(projectId, itemId).subscribe(
                            response => {
                                console.log("Here's the data we got");
                                console.log(response);
                                function _base64ToArrayBuffer(base64) {
                                    let binary_string =  window.atob(base64);
                                    let len = binary_string.length;
                                    let bytes = new Uint8Array( len );
                                    for (let i = 0; i < len; i++)        {
                                        bytes[i] = binary_string.charCodeAt(i);
                                    }
                                    return bytes.buffer;
                                }
    
                                let newVal =  {data: _base64ToArrayBuffer(response)};
                                console.log("####" + self.subItem.id + " | " + itemId);
                                if(self.subItem.id == itemId)
                                {
                                    console.log("They match, setting the pdf data to the new value");
                                    self.pdfData = newVal;
                                    console.log("Setting the thumbnail url");
                                    self.thumbnailUrl = "/url/" + self.subItem.id;
                                    console.log(self.thumbnailUrl);
                                    console.log("Detecting changes");
                                    self.ref.detectChanges();
                                    console.log(self.previewStatus);

                                }
                                
                            }
                        );
                    })(self, self.projectId, self.subItem.id);

                }
            }
            if(!hasPreview)
            {
                this.previewStatus = "previewNotAvailable";
                self.ref.detectChanges();
            }
        }
        else
        {
            this.previewStatus = "previewNotAvailable";
            this.subItem.files = [];
            self.ref.detectChanges();
        }
        //this.ref.detectChanges();
    }

    refreshSubItemTags() 
    {
        console.log("Here is the sub item id:")
        console.log(this.subItem.id);
        console.log("here are the sub item tags:")
        console.log(this.subItem.tag_ids);

        var self = this; 
        self.projectService.getItemTagsReport(self.projectId, [this.subItem.id]).subscribe((data) => {
            console.log("Starting to update tags");
            console.log(data)
            for(var i = 0; i < data.length; i++)
            {
                if(data.id == this.subItem.item_id)
                {
                    if(this.subItem.tag_ids == null)
                    {
                        this.subItem.tag_ids = [];
                    }
                    self.copyArray(data[i].tag_ids, this.subItem.tag_ids);
                }
            }
            console.log("Finished updating tags");
            self.ref.markForCheck();
            self.ref.detectChanges();

            console.log("here are the sub item tags after the update:")
            console.log(this.subItem.tag_ids);

        });


    }

    pageRendered(e: CustomEvent) 
    {
        console.log('(page-rendered)', e);
    }    

    onProgress(progressData: PDFProgressData) {
        console.log("PROGERESS")
        console.log(progressData) 
        // do anything with progress data. For example progress indicator
      }

    onPdfError(event, thumbnailUrl)
    {
        console.log("ERROR IN PDF")
        console.log(thumbnailUrl);
        console.log(event);
        this.previewStatus = "error";
        this.thumbnailUrl = "error";
        this.pdfData = {};
        this.ref.detectChanges();
    }

    textLayerRendered(e: CustomEvent) 
    {
        console.log('(text-layer-rendered)', e);
    }

    afterPdfLoaded(event, thumbnailUrl) 
    {
        console.log("After PDF Loaded Event");
        console.log ("!!!!After PDF Loaded " + Date.now())

        // WARNING Code Copied in project.view.component.ts. Any changes need to be updated there too
        //Need to send resize event this way to satisfy IE. It is deprecated.
        if(navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
            let resizeevent = document.createEvent("UIEvents");
            resizeevent.initEvent("resize", true, false);
            window.dispatchEvent(resizeevent);
        }
        else
        {
            window.dispatchEvent(new Event('resize'));            
        }

        _.delay(function(window, event, ctx, thumbnailUrl) {
            if(thumbnailUrl == ctx.thumbnailUrl) //Check if the pdf that just got loaded is the one we are currently on
            {
                console.log("Thumbnail URLS Good: " + thumbnailUrl + " | " + ctx.thumbnailUrl);

                //Trigger the pdf viewer to update to the correct size
                ctx.previewStatus = "previewLoaded";
                ctx.pdfNumPages = event.numPages;
                ctx.pdfCurrentPage = 1; 
                ctx.ref.detectChanges();
                console.log("here is the afterPDFLoadEvent");
                console.log(event);
                ctx.pdfSearch(ctx.keywords);
            }
            else
            {
                console.log("Thumbnail URLS BAD: " + thumbnailUrl + " | " + ctx.thumbnailUrl);
            }
        }, 500, window, event, this, thumbnailUrl);
    }

    pdfSearch(query)
    {
        console.log("We are searching in the PDF for the following string");
        console.log(query);
        query = query.replace('%20', " ")
        if(this.pdfViewer)
        {
            let ctx = this;
            
            (this.pdfViewer.pdfFindController as any)._calculateWordMatch = function(query, pageIndex, pageContent, entireWord) 
            {
                if(pageIndex != ctx.pdfCurrentPage - 1)
                {
                    return;
                }

                console.log("We are in the pdfFindController Calculate Word Match function")
                console.log(query);
                console.log(pageIndex);
                console.log(pageContent);
                console.log(entireWord);

                const matchesWithLength = [];
            
                // Divide the query into pieces and search for text in each piece.
                const queryArray = query.match(/\S+/g);
                console.log("Here is the query array");
                console.log(queryArray);

                for (let i = 0, len = queryArray.length; i < len; i++) 
                {
                    const subquery = queryArray[i].replace('%20', ' ');
                    const subqueryLen = subquery.length;
                
                    let matchIdx = -subqueryLen;
                    while (true) {
                        matchIdx = pageContent.indexOf(subquery, matchIdx + subqueryLen);
                        if (matchIdx === -1) {
                        break;
                        }
                        if (entireWord &&
                            !this._isEntireWord(pageContent, matchIdx, subqueryLen)) {
                        continue;
                        }
                        // Other searches do not, so we store the length.
                        matchesWithLength.push({
                            match: matchIdx,
                            matchLength: subqueryLen,
                            skipped: false,
                        });
                    }
                }

                this._pageMatchesLength[pageIndex] = [];
                this._pageMatches[pageIndex] = [];
          
                this._prepareMatches(matchesWithLength, this._pageMatches[pageIndex], this._pageMatchesLength[pageIndex]);
            }

          //  this.pdfViewer.pdfFindController.executeCommand('find', {caseSensitive: false, findPrevious: undefined, highlightAll: true, phraseSearch: false, query: query});


            this.pdfViewer.eventBus.dispatch('find', {
                query: query, type: 'again', caseSensitive: false, findPrevious: undefined, highlightAll: true, phraseSearch: false
              });



            console.log("Searching complete");
        }
        else
        {
            console.log("There is no pdfViewer view child");
        }
        
    }

    prevPage()
    {
        this.pdfCurrentPage = this.pdfCurrentPage - 1;
        if(this.pdfCurrentPage <= 0) 
        {
            this.pdfCurrentPage = this.pdfNumPages;
        }
        this.pdfSearch(this.keywords);
    }

    nextPage() 
    {
        this.pdfCurrentPage = this.pdfCurrentPage + 1;
        if(this.pdfCurrentPage > this.pdfNumPages) 
        {
            this.pdfCurrentPage = 1;
        }
        this.pdfSearch(this.keywords);
    }

    removeTag(tagId)
    {
        this.subItem.tag_ids.removeItem(tagId);
    }

    onDragEnd(event)
    {
        console.log(event);
        let newFamilyTreeHeight = this.familyTreePane.nativeElement.offsetHeight;
        this.familyTreeHScrollbar = newFamilyTreeHeight > 40 //Magic Number is the height below which h-scroll bar should be hidden;
        let newFamilyTreeW = this.familyTreePane.nativeElement.offsetHeight;
        this.familyTreeHScrollbar = newFamilyTreeHeight > 40 //Magic Number is the height below which h-scroll bar should be hidden;
        console.log("Family tree H SCrollbar");
        console.log(newFamilyTreeHeight);
        console.log(this.familyTreeHScrollbar);
    }
    
    onFamilyTreeSelectionChanged(item)
    {
        this.familyTreeSelectedItemId = item.id;
        this.subItem = item;
        this.notesText = item.attributes['summary/notes'];
    }

    downloadFile(item, groupName)
    {
        console.log("Download item")
        console.log(item)
        console.log(item.id);
        console.log("With group name: " + groupName)
        console.log(item.attributes);


        //get the native file attributes
        let nativeFileAttributes:any = {}
        item.files.forEach(file => {
            if(file.group_name == "native")
            {
                nativeFileAttributes = file;
            }
        });
        if(!nativeFileAttributes)
        {
            nativeFileAttributes = 
            {
                filename: "",
                mime_type: "application/octet-stream"
            }
        }

        let self = this;
        let display_filename = 
            item.attributes["summary/bates"] + "_" + 
            groupName + "." + 
            (
                groupName == "preview" ? 
                "pdf" : 
                this.projectService.getExtension(nativeFileAttributes.filename, nativeFileAttributes.mime_type)
            );
            
        console.log("SUBSCRIBING");
        this.projectService.getFile(this.projectId, item.id, groupName, display_filename).subscribe(
            response => {
                console.log("RESPONSE")
                self.projectService.downloadDataAsFile(response, "application/unknown", display_filename);
            }
        )
    }

    openPreview()
    {
        let self = this;
        let display_filename = this.subItem.attributes["summary/bates"] + "_preview.pdf";

        this.projectService.getFile(this.projectId, this.subItem.id, "preview", display_filename).subscribe(
            response => {
                console.log("RESPONSE")
                self.projectService.openDataInNewTab(response, "application/pdf", display_filename);
            }
        )

        // console.log("We are in Open Preview");
        // for(let i = 0; i < this.subItem.files.length; i++)
        // {
        //     console.log("iterating over the files");
        //     if(this.subItem.files[i].group_name == "preview")
        //     {
        //         console.log("We found the preview. Getting the access url")
        //         let self = this;
        //         let file = this.subItem.files[i];
        //         let item = this.subItem;


        //         (function x(projectService, serverDetails, projectId, file, filename)
        //         {
        //             projectService.getFileAccessUrl(serverDetails, projectId, file, filename).subscribe
        //             (
        //                 url => 
        //                 {
        //                     window.open(url, '_blank');
        //                 }
        //             );
        //        })(this.projectService, this._serverDetails, this.projectId, file, filename);
        //     }
        // }
    }

    debouncedSubmitNotes=_.debounce(this.projectService.submitNotes, 1000, {leading: true, trailing: true});
    onNotesChange() {
        this.subItem.attributes['summary/notes'] = this.notesText;
        this.projectService.submitNotes(this.projectId, this.subItem.id, this.subItem.attributes['summary/notes']).subscribe();
        //this.debouncedSubmitNotes();
      }
}