<div  *ngFor="let account of accounts" class="accountCard">

  <div _ngcontent-c2="" class="sectionHeader">
    <span class="accountName">{{account.type == 'restricted' ? 'My Free Workspace' : account.name}}  <span *ngIf="account.type=='restricted'" class="restrictedNotice">
      (Max 2 projects, 10 GB limit)
    </span></span>
    <!-- <span *ngIf="account.value.permissions.includes('account.manage_account')" class="accountListStatus">showing all projects</span> -->
    <span *ngIf="account.permissions.includes('account.manage_account')" class="adminControls">
            <a  (click)="newProjectAccountId=account.id; newProjectName=''; newProjectUserInput=''"  data-bs-toggle="modal" data-bs-target="#newProjectModal" class="adminControl newProject button-shadow" *ngIf="account.projects?.length > 0 && !pendingProject && account.permissions.includes('account.manage_account') && !(account.type == 'restricted' && account.projects?.length >= 2)">
                + New Project
            </a>
            <a class="adminControl" [routerLink]="['/accounts', account.id]">
              <span class="bi bi-gear"></span>
              Admin
            </a>
    </span>

  </div>
  <!-- <H3 *ngIf="account.projects?.length > 0">
    Projects shared with me:
  </H3> -->
  <div class="list-group">
    <a *ngFor="let project of account.projects" [routerLink]="['projects', project.project_id, 'evidence']" class="projectCard">
        <div class="projectImageContainer" >
          <div class="projectImage" [style.background-image]="project.backgroundImage">
          </div>
        </div>
          <div class="projectDetailSection">
            <div class="projectName">
                {{project.project_name}}
            </div>
            <!-- <div class="projectDetail">
              Created: {{project.project_created | date:'mediumDate'}} {{project.project_created | date:'shortTime'}}
            </div> -->
          </div>
        </a>

        <div class="projectCard pendingProject" *ngIf="pendingProjectAccountId == account.id">
          <div class="projectImageContainer" >
            <div class="projectImage">
            </div>
          </div>
            <div class="projectDetailSection">
              <div class="projectName">
                  Loading...
              </div>
              <div class="projectDetail">
                
              </div>
            </div>
          </div>       
          
          
          <div *ngIf="account.projects?.length < 1 && !pendingProjectAccountId">
            You have no projects in this workspace yet. Why not create a 
              <a (click)="newProjectAccountId=account.id; newProjectName=''; newProjectUserInput=''"  data-bs-toggle="modal" data-bs-target="#newProjectModal" class="adminControl newProject button-shadow" *ngIf="!pendingProject && account.permissions.includes('account.manage_account')">
                + New Project
              </a>
          </div>

  </div>
</div>

<div class="modal" id="newProjectModal" #newProjectModal (click)="$event.stopPropagation()"  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
          <h1>New Project</h1>
      </div>
      <div class="modal-body">
        <div class="inputControl">
          <div class="inputLabel">Workspace:</div>
          <div class="inputControlControl">{{accountProjects[newProjectAccountId]?.name}}</div>
          <div class="inputLabel">Name: </div>
          <div class="inputControlControl">
            <input class="form-control section-input inset-input" type="text" placeholder="e.g. Project ABCD" [(ngModel)]="newProjectName" id="newProjectNameInput" defaultValue="">
          </div>
        </div>
        <div class="inputControl">
          <div class="inputLabel">Timezone: </div>
          <div class="inputControlControl inset-picker">
            <ng-moment-timezone-picker
            [(ngModel)]="newProjectTimezone"
            customPlaceholderText="Select timezone"
            getUserZone="true">
            </ng-moment-timezone-picker>
          </div>
          <div class="inputDescription">This timezone will be used when displaying and rendering documents. For example, email times will be shown according to this timezone.</div>
        </div>
        <div class="inputControl">
          <div class="inputLabel">Users: </div>
          <div class="inputControlControl">
            <div class="input-group">
              <input class="form-control section-input inset-input" type="text" placeholder="e.g. user@email.com" [(ngModel)]="newProjectUserInput" (keydown)="onKeyDownNewProjectUser($event)">
              <span class="input-group-btn">
                  <button class="btn btn-secondary" type="button" (click)="addNewProjectUser(newProjectUserInput)">Add</button>
              </span>
          </div>
          <div class="dodgy-email" *ngIf="dodgyEmail">Please enter a valid email address</div>
          <div class="section-chips">
              <div class="chip" *ngFor="let chip of newProjectUsers">
                  <span class="chipText">{{chip}}</span>
                  <div class="bi bi-x" (click)="$event.stopPropagation(); removeNewProjectUser(chip)"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
          <button class="btn btn-success button-shadow" (click)="createProject(newProjectAccountId, newProjectName, newProjectTimezone, newProjectUsers);" data-bs-dismiss="modal" >Create</button>
          <a class="apply-filters"><span [style.marginLeft.px]="5" data-bs-dismiss="modal">Cancel</span></a>
      </div>
    </div>
  </div>
</div>

