import {Schema} from "prosemirror-model";
import {tableNodes} from "prosemirror-tables";

// ::Schema Document schema for the data model used by CommonMark.
var schemaNoTables = new Schema({
  nodes: {
    doc: {
      content: "block+"
    },

    paragraph: {
      attrs: {align: {default: "left"}},
      content: "inline*",
      group: "block",
      parseDOM: [{tag: "p"}],
      toDOM(node) { return ["p", {style: `text-align: ${node.attrs.align};`}, 0] }
    },

    blockquote: {
      attrs: {align: {default: "left"}},
      content: "block+",
      group: "block",
      parseDOM: [{tag: "blockquote"}],
      toDOM(node) { return ["blockquote", {style: `text-align: ${node.attrs.align};`}, 0] }
    },

    horizontal_rule: {
      group: "block",
      parseDOM: [{tag: "hr"}],
      toDOM() { return ["div", ["hr"]] }
    },

    heading: {
      attrs: {level: {default: 1}, align: {default: "left"}},
      content: "inline*",
      group: "block",
      defining: true,
      parseDOM: [{tag: "h1", attrs: {level: 1}},
                 {tag: "h2", attrs: {level: 2}},
                 {tag: "h3", attrs: {level: 3}},
                 {tag: "h4", attrs: {level: 4}},
                 {tag: "h5", attrs: {level: 5}},
                 {tag: "h6", attrs: {level: 6}}],
      toDOM(node) { return ["h" + node.attrs.level, {style: `text-align: ${node.attrs.align};`}, 0] }
    },

    code_block: {
      content: "text*",
      group: "block",
      code: true,
      defining: true,
      attrs: {params: {default: ""}},
      parseDOM: [{tag: "pre", preserveWhitespace: true, getAttrs: node => ({params: node.getAttribute("data-params")})}],
      toDOM(node) { return ["pre", node.attrs.params ? {"data-params": node.attrs.params} : {}, ["code", 0]] }
    },

    ordered_list: {
      content: "list_item+",
      group: "block",
      attrs: {order: {default: 1}, tight: {default: false}},
      parseDOM: [{tag: "ol", getAttrs(dom) {
        return {order: dom.hasAttribute("start") ? +dom.getAttribute("start") : 1,
                tight: dom.hasAttribute("data-tight")}
      }}],
      toDOM(node) {
        return ["ol", {start: node.attrs.order == 1 ? null : node.attrs.order,
                       "data-tight": node.attrs.tight ? "true" : null}, 0]
      }
    },

    bullet_list: {
      content: "list_item+",
      group: "block",
      attrs: {tight: {default: false}},
      parseDOM: [{tag: "ul", getAttrs: dom => ({tight: dom.hasAttribute("data-tight")})}],
      toDOM(node) { return ["ul", {"data-tight": node.attrs.tight ? "true" : null}, 0] }
    },

    list_item: {
      attrs: {align: {default: "left"}},
      content: "paragraph block*",
      defining: true,
      parseDOM: [{tag: "li"}],
      toDOM() { return ["li", 0] }
    },

    text: {
      group: "inline",
      toDOM(node) { return node.text }
    },

    image: {
      inline: true,
      attrs: {
        src: {},
        alt: {default: null},
        title: {default: null}
      },
      group: "inline",
      draggable: true,
      parseDOM: [{tag: "img[src]", getAttrs(dom) {
        return {
          src: dom.getAttribute("src"),
          title: dom.getAttribute("title"),
          alt: dom.getAttribute("alt")
        }
      }}],
      toDOM(node) { return ["img", node.attrs] }
    },

    hard_break: {
      inline: true,
      group: "inline",
      selectable: false,
      parseDOM: [{tag: "br"}],
      toDOM() { return ["br"] }
    }

    ,
    itemreference: {
      inline: true,
      selectable: false, //If you remove this, it will cause a javascript error and mess up editing 
      attrs: {
          class: "lrw-itemreference-anchor",
          projectid: {},
          id: {},
          linktogroup: "preview",
          displayattribute: "bates",
          bates: {},
          page: { default: null }
      },
      group: "inline",
      toDOM: function(node) { return ["a", node.attrs, 0] },
      parseDOM: [{tag: "a",  getAttrs(dom) {

        console.log("We are in the parseDom function");
        
        let res = {
          class: dom.getAttribute("class"),
          projectod: dom.getAttribute("projectid"),
          id: dom.getAttribute("id"),
          linktogroup: dom.getAttribute("linktogroup"),
          displayattribute: dom.getAttribute("displayattribute"),
          bates:  dom.getAttribute("bates"),
          page:  dom.getAttribute("page")
        }
        console.log(res);
        return res;
      }}]
    },
  },

  marks: {
    em: {
      parseDOM: [{tag: "i"}, {tag: "em"},
                 {style: "font-style", getAttrs: value => value == "italic" && null}],
      toDOM() { return ["em"] }
    },

    underline: {
      parseDOM: [{tag: "u"}, {tag: "underline"},
                 {style: "font-style", getAttrs: value => value == "underline" && null}],
      toDOM() { return ["u"] }
    },

    strikethrough: {
      parseDOM: [{style: "text-decoration", getAttrs: value => value == "line-through" && null}],
      toDOM() { return ["span", {style: "text-decoration: line-through"}] }
    },
      
    strong: {
      parseDOM: [{tag: "b"}, {tag: "strong"},
                 {style: "font-weight", getAttrs: value => /^(bold(er)?|[5-9]\d{2,})$/.test(value) && null}],
      toDOM() { return ["strong"] }
    },
    link: {
      attrs: {
        href: {},
        title: {default: null},
      },
      inclusive: false,
      parseDOM: [{tag: "a[href]", getAttrs(dom) {
        return {href: dom.getAttribute("href"), title: dom.getAttribute("title")}
      }}],
      toDOM(node) { return ["a", node.attrs] }
    },
        // itemreference: {
        //   inclusive: false,
        //   attrs: {
        //     id: {},
        //     displayattribute: {},
        //     _displayvalue: {},
        //     linktogroup: {},
        //     page: {}
        //   },        
        //   toDOM: function () 
        //     { 
        //         return [
        //             "span", 
        //             { class: "itemreference" }
        //         ]; 
        //     }
        // },        
    code: {
      parseDOM: [{tag: "code"}],
      toDOM() { return ["code"] }
    }
  }
})


let tableSchema = tableNodes(
  {
      tableGroup: "block",
      cellContent: "block+",
      cellAttributes: {
          "gridlines": {
              default: true,
              setDOMAttr: function(value, attrs)
              {
                  if (!value) { 
                      attrs.class = (attrs.class || "") + " nogridlines "; 
                  } 
              }
          },
          "data-colwidth": {
            default: null,
            setDOMAttr: function(value, attrs)
            {
              if(value)
              {
                attrs.style =
                (attrs.style || '') + `width: ${value};`
              }
            }
        }
      }
  }
)

tableSchema.table_row.attrs = {
  projectid: { default: null },
  itemid: { default: null },
  linktogroup: { default: "preview" },
  displayattribute: { default: "bates" },
  bates: { default: null },
  page: { default: null },
  command: { default: null }
}

tableSchema.table_row.parseDom = [{ tag: 'tr', getAttrs(dom) {

  console.log("We are in the parseDom function");
  
  let res = {
    projectod: dom.getAttribute("projectid"),
    itemid: dom.getAttribute("itemid"),
    linktogroup: dom.getAttribute("linktogroup"),
    displayattribute: dom.getAttribute("displayattribute"),
    bates:  dom.getAttribute("bates"),
    page:  dom.getAttribute("page"),
    command:  dom.getAttribute("command")
  }
  console.log(res);
  return res;
} }],
tableSchema.table_row.toDOM =  function(node){
  return ['tr', node.attrs,  0];
},


console.log("Here is the table schema:")
console.log(tableSchema);


export const schema = new Schema({nodes: schemaNoTables.spec.nodes.append(tableSchema), marks: schemaNoTables.spec.marks});
