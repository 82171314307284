import crel from "crel";
import {Plugin, PluginSpec, TextSelection} from "prosemirror-state"
import {Decoration, DecorationSet} from "prosemirror-view";
import { ProjectService } from "../../../services/project.service";

export class linkTooltipPluginContainer 
{
    projectId: any;
    constructor(projectId, private projectService: ProjectService)
    {
        this.projectId = projectId;
    }
    
    getPlugin()
    {
        var self = this;
        var linkTooltipPlugin = new Plugin({
                props: {
                      handleKeyDown(view, event)
                      {
                          console.log("A Key was pressed! This is from the linktooltipplugin");
                          return false;
                      }
                    ,            
                    decorations(state) {
                        return self.linkTooltipDecorator(state);
                    }
                }
            }
        )
        return linkTooltipPlugin;
    }
    
    

    render(state)
    {
        var self = this;
        console.log("Rendering. The Project id is:");
        console.log(self.projectId);

        var innerLink = 
        crel
        (
            "a", 
            {
                class:"reset-a linktooltip-link noselect", 
                contentEditable: false, 
                target: "_blank",
            }, 
            "Open Link (" + self.getGroup(state.selection.$anchor.node()) + ")"
        )        
        innerLink.addEventListener("click", () => {
            self.projectService.getFileAccessUrlByItemId
            (
                self.projectId, 
                self.getId(state.selection.$anchor.node()), 
                self.getGroup(state.selection.$anchor.node())
            )
            .subscribe
            (
                url => 
                {
                    console.log("We are in the onclick function of the anchor");
                    console.log(url);
                    console.log("the group name is " + self.getGroup(state.selection.$anchor.node()));
                    var url2 = url + (self.getGroup(state.selection.$anchor.node()) == "preview" ? ".pdf" : "");
                    console.log("The url is " + url2);
                    var a = <HTMLAnchorElement> document.createElement('A');
                    var filePath = url2;
                    a.href = filePath;
                    a.target = "_blank";
                    a.download = filePath.substr(filePath.lastIndexOf('/') + 1);
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
           );                            
        });
       // return innerLink;

        // return crel
        // (
        //     "div", 
        //     {
        //         class: "linktooltip-wrapper noselect", contentEditable: false
        //     }, 
        //     crel
        //     (
        //         "div", 
        //         {
        //             class: "linktooltip-inner noselect", 
        //             contentEditable: false,
        //         }, 
        //         innerLink
        //     )
        // );



        return crel
        (
            "div", 
            {
                class: "linktooltip-wrapper noselect", contentEditable: false
            }, 
            crel
            (
                "div", 
                {
                    class: "linktooltip-inner noselect", 
                    contentEditable: false,
                }, 
                crel
                (
                    "a", 
                    {
                        class:"reset-a linktooltip-link noselect", 
                        contentEditable: false, 
                        target: "_blank",
                        on:
                        {
                            click: () => {
                                self.projectService.getFileAccessUrlByItemId
                                (
                                    self.projectId, 
                                    self.getId(state.selection.$anchor.node()), 
                                    self.getGroup(state.selection.$anchor.node())
                                )
                                .subscribe
                                (
                                    url => 
                                    {
                                        console.log("We are in the onclick function of the anchor");
                                        console.log("the group name is " + self.getGroup(state.selection.$anchor.node()));
                                        var url2 = url + (self.getGroup(state.selection.$anchor.node()) == "preview" ? ".pdf" : "");
                                        console.log("The url is " + url2);
                                        var a = <HTMLAnchorElement> document.createElement('A');
                                        var filePath = url2;
                                        a.href = filePath;
                                        a.target = "_blank";
                                        a.download = filePath.substr(filePath.lastIndexOf('/') + 1);
                                        document.body.appendChild(a);
                                        a.click();
                                        document.body.removeChild(a);
                                    }
                               );                            
                            }
                        }
                    }, 
                    "Open Link"
                )
            )
        );
    }

    getHref(node)
    {
        for(var i = 0; i < node.marks.length; i++)
        {
            if(node.marks[i].type.name == "link")
            {
                return node.marks[i].attrs.href;
            }
        }
        for(var i2 = 0; i2 < node.childCount; i2++)
        {
            for(var j = 0; j < node.child(i2).marks.length; j++)
            {
                if(node.child(i2).marks[j].type.name == "link")
                {
                    return node.child(i2).marks[j].attrs.href;
                }
            }
        }
        return null;    
    }

    getId(node)
    {
        for(var i = 0; i < node.marks.length; i++)
        {
            if(node.marks[i].type.name == "itemreference")
            {
                return node.marks[i].attrs.id;
            }
        }
        for(var i2 = 0; i2 < node.childCount; i2++)
        {
            for(var j = 0; j < node.child(i2).marks.length; j++)
            {
                if(node.child(i2).marks[j].type.name == "itemreference")
                {
                    return node.child(i2).marks[j].attrs.id;
                }
            }
        }
        return null;    
    }
    
    getGroup(node)
    {
        for(var i = 0; i < node.marks.length; i++)
        {
            if(node.marks[i].type.name == "itemreference")
            {
                return node.marks[i].attrs.linktogroup;
            }
        }
        for(var i2 = 0; i2 < node.childCount; i2++)
        {
            for(var j = 0; j < node.child(i2).marks.length; j++)
            {
                if(node.child(i2).marks[j].type.name == "itemreference")
                {
                    return node.child(i2).marks[j].attrs.linktogroup;
                }
            }
        }
        return null;    
    }

    isNodeALink(node)
    {
        console.log("Here is the node")
        console.log(node)
        if(node.selection)
        {
            console.log("There is a selection");
            console.log(node.selection.node.attrs.class);
            
        }
        else
        {
            console.log("no selection")
        }
        var marks = node.marks();


        console.log("Here are the marks")
        console.log(marks)

        for(var i = 0; i < marks.length; i++)
        {
            if(marks[i].type.name == "itemreference")
            {
                console.log("found a link mark in the selected node")
                console.log(node.nodeSize);
                return true;
            }
        }
    //    for(var i2 = 0; i2 < node.childCount; i2++)
    //    {
    //        for(var j = 0; j < node.child(i2).marks.length; j++)
    //        {
    //            if(node.child(i2).marks[j].type.name == "link")
    //            {
    //                console.log("found a link mark in a child node")
    //                console.log("parent node")
    //                console.log(node);
    //                return true;
    //            }
    //        }
    //    }
        return false;
    }

    getEndOfNode(node)
    {

    }


    isSelectionInsideLink(state)
    {
        var cursor = (state.selection as TextSelection).$cursor || undefined;
        if(cursor)
        {
            console.log("There is a cursor");
            console.log(cursor);
            return this.isNodeALink(cursor);
        }
        return false;
    }

    linkTooltipDecorator(state) {
        console.log("In linktooltipdecorator")
        let sel = state.selection
        if (!sel.empty) return null
        if(this.isSelectionInsideLink(state))
        {
            console.log("Selection is inside a link")
            return DecorationSet.create(state.doc, [Decoration.widget(sel.from, this.render(state))])
        }
        else
        {
            console.log("Selection is not inside a link");
        }
        return null;
    }
}



