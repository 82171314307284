<div class="container newAdministratorForm">
    <form [formGroup]="form.getForm()" (ngSubmit)="onSubmit();">
        <div class="form-group">
            <label for="first_name">Username</label>
            <input type="text" class="form-control" id="username"  name="username" formControlName="username">
            <small class="error-alert" *ngIf="form.hasError('username')">Username is required</small>
        </div>
        <div class="form-group">
            <label for="password">Password</label>
            <input type="password" class="form-control" id="password" name="password" formControlName="password">
            <small class="error-alert" *ngIf="form.hasError('password')">Password is required</small>
        </div>
        <div class="form-group">
            <label for="confirm_password">Confirm Password</label>
            <input type="password" class="form-control" id="confirm_password" name="confirm_password" formControlName="confirm_password">
            <small class="error-alert" *ngIf="form.hasError('confirm_password')">Passwords do not match</small>
        </div>
        <button class="btn btn-success" type="submit">Submit</button>
        <button class="btn btn-default" type="button" (click)="clearForm()">Reset</button>
    </form>
</div>