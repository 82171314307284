import {throwError as observableThrowError,  Observable } from 'rxjs';
import { switchMap, debounceTime, catchError } from 'rxjs/operators';
import { Component, OnInit, Input, OnChanges, Inject, Pipe, PipeTransform, Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { map } from 'rxjs/operators';

import {configSettings} from '../../config';
let config = new configSettings();

@Injectable()
export class S3Service {
    constructor( 
        private httpClient: HttpClient,  
    ) 
    { 
    }

    uploadFileWithPreSignedURL(file: File, preSignedURL) : Observable<any>
    {
        console.log("SENDING FILE TO URL " + preSignedURL)
        return this.httpClient.put<any>(
            preSignedURL, file
        ).pipe(
            map(
                (res) => {
                    console.log("SENT TO S3.");
                    return res;
                }
            ),
            catchError(this.handleError)
        );

    }


    private async handleError (error: Response | any) 
    {
        console.log("S3 Service: There has been an error");
        console.log(error);
        let errMsg: string;
        if(error instanceof Response) {
            const body = await error.json() || '';
            const err = body.error || JSON.stringify(body);
            console.log("First Part");
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        }
        else
        {
            console.log("Second Part");
            errMsg = error.message ? error.message : error.toString();
        }
        console.error(errMsg);
        return observableThrowError(errMsg);
    }
}