import { Component, Input, Output, EventEmitter,ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';


@Component({
    selector: 'lrw-filter-tags',
    templateUrl: './filter.tags.component.html',
    styleUrls: ['./filter.tags.component.css']
})

export class FilterTagsComponent {
    copyArray(source, dest) //copy contents of doctyles list to checkedTypes, keep reference intact
    {
        dest.length = 0;
        [].push.apply(dest, source);
    }
    private _tags: any = {};
    private _tagIds: any = [];
    allTagged = false;
    allUntagged = false;
    untagged = {'state': "checked"};
    masterCheckStatus = "";
    masterBanStatus = "";

    @Input('tags') 
    set tags(val: any) { 
        this._tags = val; 
        this._tagIds = Object.keys(this._tags);  
                this.refreshMasterCheckStatus();
        this.refreshMasterBanStatus();    
    };
    get tags(): any { return this._tags };
    get tagIds(): any { return this._tagIds };

    default = [];

    
    private _data = {includeMethod: "Any", includeIds: [], bannedIds: []};
    
    get anyOrAll():any {
        return this._data.includeMethod;
    }
    set anyOrAll(val:any) {
        this._data.includeMethod = val;
    }
    
    @Input('data') 
    set data(value: any) {
        this._data = value;
        this._data.includeIds = value.includeIds || [];
        this._data.bannedIds = value.bannedIds || [];
        this.refreshMasterCheckStatus();
        this.refreshMasterBanStatus();
        this.ref.detectChanges();
    }
    
    get data(): any { 
        return this._data;
    };
    
    @Output() filterChange: EventEmitter<string> = new EventEmitter();
    
    constructor(private ref: ChangeDetectorRef) {
    }
    
    onCheckboxClick(event, tagId)
    {
        this.toggleItem(tagId);
        this.filterChange.emit(this.data);
    }
    
    getCheckedTagStateById(id)
    {
        if(this.data.includeIds.indexOf(id) != -1)
        {
            return "checked";    
        }
        else if (this.data.bannedIds.indexOf(id) != -1)
        {
                return "banned"
        }
        else
        {
                return "";
        }
    }
    setChecked(event, tagId)
    {
        event.stopPropagation();

        if(this.getCheckedTagStateById(tagId) == "checked")
        {
            this.setNone(tagId);
        }
        else
        {
            this.setCheckedNoEvent(tagId);        
        }
        
        
        this.filterChange.emit(this.data);
        this.refreshMasterCheckStatus();
        this.refreshMasterBanStatus();        
    }
    
    setCheckedNoEvent(tagId)
    {
        if(this.data.bannedIds.indexOf(tagId) != -1)
        {
            this.data.bannedIds.splice(this.data.bannedIds.indexOf(tagId), 1);    
        }
        
        if(this.data.includeIds.indexOf(tagId) == -1)
        {
            this.data.includeIds.push(tagId);    
        }
        
    }
    
    setBanned(event, tagId)
    {
        event.stopPropagation();

        if(this.getCheckedTagStateById(tagId) == "banned")
        {
                this.setNone(tagId);
        }
        else
        {
            this.setBannedNoEvent(tagId);        
        }
        
        this.filterChange.emit(this.data);
        this.refreshMasterCheckStatus();
        this.refreshMasterBanStatus();        
    }
    
    setBannedNoEvent(tagId)
    {
        if(this.data.includeIds.indexOf(tagId) != -1)
        {
            this.data.includeIds.splice(this.data.includeIds.indexOf(tagId), 1);    
        }
        
        if(this.data.bannedIds.indexOf(tagId) == -1)
        {
            this.data.bannedIds.push(tagId);    
        }
        
    }
    
    setNone(tagId)
    {
        if(this.data.includeIds.indexOf(tagId) != -1)
        {
            this.data.includeIds.splice(this.data.includeIds.indexOf(tagId), 1);    
        }
        if(this.data.bannedIds.indexOf(tagId) != -1)
        {
            this.data.bannedIds.splice(this.data.bannedIds.indexOf(tagId), 1);    
        }
        
    }
    
    toggleItem(tagId)
    {
        if(this.getCheckedTagStateById(tagId) == "")
        {
            this.setCheckedNoEvent(tagId);        
        }
        else
        {
            this.setNone(tagId);    
        }
         this.filterChange.emit(this.data);   
        this.refreshMasterCheckStatus();
        this.refreshMasterBanStatus();
    }


    //Check All/None
    setToEmptyCheck() {
        this.data.includeIds.length = 0;
         this.filterChange.emit(this.data);   
        this.refreshMasterCheckStatus();
        this.refreshMasterBanStatus();        
    }
    checkIfEmptyCheck() {
        return this.data.includeIds.length == 0;
    }
    setToAllCheck() {
        for(var i = 0; i < this.tagIds.length; i++)
        {
            this.setCheckedNoEvent(this.tagIds[i]);
        }
         this.filterChange.emit(this.data);   
        this.refreshMasterCheckStatus();
        this.refreshMasterBanStatus();        
    }
    checkIfAllCheck() {
        return this.data.includeIds.length == this.tagIds.length;
        
    }
    allnoneCheck(event)
    {
        if(!this.checkIfAllCheck())
        {
            this.setToAllCheck();
        }
        else
        {
            this.setToEmptyCheck();
        }
        this.refreshMasterCheckStatus();
        this.refreshMasterBanStatus();
        this.filterChange.emit(this.data);
    }
    refreshMasterCheckStatus()
    {
        if(this.checkIfAllCheck())
        {
            this.masterCheckStatus = "all";
        }
        else if(this.checkIfEmptyCheck())
        {
            this.masterCheckStatus = "none";
        }
        else
        {
            this.masterCheckStatus = "partial";
        }
    }
    
    
    //All/None Ban
    setToEmptyBan() {
        this.data.bannedIds.length = 0;
    }
    checkIfEmptyBan() {
        return this.data.bannedIds.length == 0;
    }
    setToAllBan() {
        for(var i = 0; i < this.tagIds.length; i++)
        {
            this.setBannedNoEvent(this.tagIds[i]);
        }
    }
    checkIfAllBan() {
        return this.data.bannedIds.length == this.tagIds.length;
    }
    allnoneBan(event)
    {
        if(!this.checkIfAllBan())
        {
            this.setToAllBan();
        }
        else
        {
            this.setToEmptyBan();
        }
        this.refreshMasterBanStatus();
        this.refreshMasterCheckStatus();
        this.filterChange.emit(this.data);
    }
    refreshMasterBanStatus()
    {
        if(this.checkIfAllBan())
        {
            this.masterBanStatus = "all";
        }
        else if(this.checkIfEmptyBan())
        {
            this.masterBanStatus = "none";
        }
        else
        {
            this.masterBanStatus = "partial";
        }
    }  
    
    getNumActiveTagfilters()
    {
        return this.data.includeIds.length + this.data.bannedIds.length;
    }
    
    setAnyOrAll(val)
    {
        this.anyOrAll = val;
        if(this.getNumActiveTagfilters() > 0)
        {
            this.ref.detectChanges();
            this.filterChange.emit(this.data);
        }

    }

    @Output() isDefault = function() {
        return this.getNumActiveTagfilters() == 0;
    }
}
