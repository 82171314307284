import { Component, AfterViewInit, Input, Output, EventEmitter, HostListener, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, Pipe } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Project_ServerService } from "../../../project_server.service";
import { ProjectService } from "../../services/project.service";
import { SocketService } from "../../../socket.service";
import { VirtualScrollerComponent } from 'ngx-virtual-scroller';

var offset = require('document-offset');
import * as _ from "lodash";
import { ItemViewerComponent } from '../itemviewer/itemviewer';
import { NgDropdownPanelService } from '@ng-select/ng-select/lib/ng-dropdown-panel.service';

export enum keyCodes 
{
    a = 65,
    up = 38,
    down = 40,
    left = 37,
    right = 39,
    enter = 13,
    space = 32,
    home = 36,
    end = 35,
    pgup = 33,
    pgdown = 34,
    escape = 27,
    shift = 16,
    ctrl = 17
}

@Component({
    selector: 'lrw-datagrid',
    templateUrl: './datagrid.html',
    styleUrls: ['./datagrid.css'],
    providers: [
        Project_ServerService
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class DataGridComponent implements AfterViewInit {
    copyArray(source, dest) //copy contents of doctyles list to checkedTypes, keep reference intact
    {
        dest.length = 0;
        [].push.apply(dest, source);
    }
    @Input() public projectId: string;
    @Input() public captureKeyEvents: boolean;

    //TODO This was triggering when we knew the server. We may need to do this on init now
    // @Input('serverDetails')
    // set serverDetails (val: any) { 
    //     this._serverDetails = val; 
    //     this.updateDisplayItems(true);

    //     if(this._serverDetails)
    //     {
    //         var self = this;
    //         self.socket.on('connect', function() {
    //             console.log("Socket Connected!");
    //             self.socket.emit('room', self.projectId);
    //         });
    //         this.socket.connect();

    //         this.socket.on("item_tags.multiple_modified", function(itemIds) {
    //             console.log("Multiple Tags Modified")
    //              self.projectService.getItemTagsReport(self.serverDetails, self.projectId, itemIds).subscribe((data) => {
    //                  console.log("Starting to update tags");
    //                  for(var i = 0; i < data.length; i++)
    //                  {
    //                     var displayIndex = self.getIndex(data[i].item_id);
    //                     if(displayIndex != -1)
    //                     {
    //                         if(self.displayItems[displayIndex].tag_ids == null)
    //                         {
    //                             self.displayItems[displayIndex].tag_ids = [];
    //                         }
    //                         self.copyArray(data[i].tag_ids, self.displayItems[displayIndex].tag_ids);
    //                     }
    //                  }
    //                  console.log("Finished updating tags");
    //                  self.ref.markForCheck();
    //                  self.itemDataUpdated.emit(self.principalId);
    //              });
    //         });
    //     }
    // }


    @Output() principalChange: EventEmitter<string> = new EventEmitter();
    @Output() itemDataUpdated: EventEmitter<string> = new EventEmitter();

    set subPrincipalIds(val: string[]) {
        this._subPrincipalIds = val;
    }

    @Output() get subPrincipalIds(): string[] {
        return this._subPrincipalIds;
    }

    @Output() get allIds(): string[] {
        return this.displayItems.map(obj => obj.id);;
    }

    @Input('sorting') 
    set sorting(val: any) { this._sorting = val; this.updateDisplayItems(false); };
    @Input('queryIds') 
    set queryIds(val: any[]) { this._queryIds = val; }
    
    _tags: any = {};
    @Input('tags') 
    set tags(val: any) { this._tags = val;}
    get tags(): any { return this._tags };

    @Input('filters') 
    set filters(val: any) { 
        console.log("Updating Filters"); 
        this._filters = val; 
        this._destinationPage1Base = 1;
        this.updateDisplayItems(true);
    };
    
    @Input('lrwsfilters') 
    set lrwsfilters(val: any) { 
        console.log("Updating Filters"); 
        this._lrwsfilters = val; 
        this._destinationPage1Base = 1;
        this.updateDisplayItems(true);
    };
    
    @Input('limit') 
    set limit(val: any) { 
        this._limit = val;         
        this.totalPages = Math.ceil(this.dataLength / this.limit) || 1;
        this.fakePagesArray = new Array(this.totalPages);        
        this.updateDisplayItems(false); 
    };

    @Input('columns') 
    set columns(val: any) { 
        this._columns = val;
    };
    get columns(): any { return this._columns;};

    @Input('junkInstruction') 
    set junkInstruction(val: any) { 
        this._junkInstruction = val;            
        this.updateDisplayItems(true); 
    };
    set dataLength(val: number) { this._dataLength = val;};

    @Input('displayTimezone') 
    set displayTimezone(val: string) { 
        this._displayTimezone = val;         
    };
    get displayTimezone(): string { return this._displayTimezone;};

    
    @Input('destinationPage1Base') 
    set destinationPage1Base(val: number) { this._destinationPage1Base = val; this.updateDisplayItems(false); };
    set updatingData(val: boolean) {this._updatingData = val;}
    get queryIds(): any[] { return this._queryIds; } 
    get serverDetails(): any { return this._serverDetails };
    get sorting(): any { return this._sorting };
    get filters(): any { return this._filters };
    get lrwsfilters(): any { return this._lrwsfilters };
    get limit(): any { return this._limit };
    get junkInstruction(): any { return this._junkInstruction };
    get dataLength(): number { return this._dataLength };
    get destinationPage1Base(): number { return this._destinationPage1Base };
    get updatingData(): boolean { return this._updatingData; };

    private _displayTimezone: any
    private _serverDetails: any;
    private _queryIds: any = [];
    displayItems: any = [];
    viewPortItems: any = [];
    private _sorting: any = [];
    private _filters: string = "";
    private _lrwsfilters: string = "";
    private _limit: number;
    private _columns: any;
    private _junkInstruction: any;
    private _destinationPage1Base: number = 1;
    displayItemRequest: any;
    private _updatingData: boolean = false;
    scrollEventLastActioned;
    dividerResizeStart;
    resizingColumn;
    dragging = false;
    mouseDownColumnWidth;
    totalPages = 1;
    fakePagesArray = [];
    private _dataLength;
    displayPage = 0;
    noDataMessage = "";
    socket;

     masterCheckStatus = "none";
     checkedIds = [];
     principalId = "";
     principalIndex = 0;
     _subPrincipalIds = [];

     @ViewChild(VirtualScrollerComponent, { static: true })
     private virtualScroll: VirtualScrollerComponent;
     
     public showLoadingSpinner()
     {
         this._updatingData = true;
         this.ref.detectChanges();
     }
    
    onResize(event)
    {
        console.log("resize detected");
    }
    
     scrollTo(index)
     {
         console.log("THE INDEX IS: " + index);

         console.log(this.virtualScroll.viewPortInfo);


         if(index > this.virtualScroll.viewPortInfo.endIndex - 2)
         {
            this.virtualScroll.scrollInto(this.displayItems[index], false, 60);  
         }
         else if(index < this.virtualScroll.viewPortInfo.startIndex)
         {
            this.virtualScroll.scrollInto(this.displayItems[index], true);  
         }
//         this.virtualScroll.scrollInto(this.displayItems[index], false);
     }
     
    constructor(private projectService: ProjectService, private ref: ChangeDetectorRef, private socketService: SocketService) {
        this.updatingData = true;
        this.scrollEventLastActioned = Date.now();
        this.dataLength = -1;
        this.socket = this.socketService.getSocket();
    }
    
    ngAfterViewInit()
    {

        this.updateDisplayItems(true);

        var self = this;
        self.socket.on('connect', function() {
            console.log("Socket Connected!");
            self.socket.emit('room', self.projectId);
        });
        this.socket.connect();

        this.socket.on("item_tags.multiple_modified", function(itemIds) {
            console.log("Multiple Tags Modified")
                self.projectService.getItemTagsReport(self.projectId, itemIds).subscribe((data) => {
                    console.log("Starting to update tags");
                    for(var i = 0; i < data.length; i++)
                    {
                        var displayIndex = self.getIndex(data[i].item_id);
                        if(displayIndex != -1)
                        {
                            if(self.displayItems[displayIndex].tag_ids == null)
                            {
                                self.displayItems[displayIndex].tag_ids = [];
                            }
                            self.copyArray(data[i].tag_ids, self.displayItems[displayIndex].tag_ids);
                        }
                    }
                    console.log("Finished updating tags");
                    self.ref.markForCheck();
                    itemIds.forEach(id => {
                        self.itemDataUpdated.emit(id);
                    });
                });
        });


        this.socket.on("item.notes_updated", function(data) 
        {
            console.log("Notes Updated")
            var displayIndex = self.getIndex(data.item_id);
            self.displayItems[displayIndex].attributes['summary/notes'] = data.notes;
            self.displayItems[displayIndex] = { ...self.displayItems[displayIndex] }
            console.log(self.displayItems[displayIndex]);
            self.ref.markForCheck();
            self.ref.detectChanges();
            self.itemDataUpdated.emit(data.item_id);
        });


        this.socket.on("set_index_category.multiple_modified", function(data) 
        {
            console.log("Notes Updated")
            for(let i = 0; i < data.item_ids.length; i++)
            {
                let itemId = data.item_ids[i];
                var displayIndex = self.getIndex(itemId);
                self.displayItems[displayIndex].attributes['summary/index_category'] = data.index_category;
                self.displayItems[displayIndex] = { ...self.displayItems[displayIndex] }
                console.log(self.displayItems[displayIndex]);
            }
            self.ref.markForCheck();
            self.ref.detectChanges();
        });
    }
    
    cols: any;
    totalRecords = 0;

    gridScrollPosX = 0;

    titleCase(str) {
        if(str)
        {
            str = str.toLowerCase().split(' ');
            for(var i = 0; i < str.length; i++) {
                str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
            }
            return str.join(' ');
        }
        else
        {
            return "";
        }
    }

    throttledPageKeyEvent = _.throttle(this.pageKeyEvent, 300, {leading: true, trailing: true});
    throttledGeneralKeyEvent = _.throttle(this.generalKeyEvent, 60, {leading: true, trailing: true});

    @HostListener('window:keydown', ['$event'])
     keyEvent(event: KeyboardEvent) {
        if(this.captureKeyEvents)
        {
             if(event.keyCode == keyCodes.pgdown || event.keyCode == keyCodes.pgup)
             {
                event.stopPropagation();
                event.preventDefault();
                 this.throttledPageKeyEvent(event);
             }
             else if(event.keyCode == keyCodes.down || event.keyCode == keyCodes.up || event.keyCode == keyCodes.end || event.keyCode == keyCodes.home || event.keyCode == keyCodes.space || event.keyCode == keyCodes.enter || event.keyCode == keyCodes.a)
             {
                event.stopPropagation();
                event.preventDefault();
                 this.throttledGeneralKeyEvent(event);
             }
        }
     }
    
    pageKeyEvent(event: KeyboardEvent) 
    {
        let numVisibleItems = this.virtualScroll.viewPortInfo.endIndex - this.virtualScroll.viewPortInfo.startIndex - 1;
         if(event.key == "PageDown")
         {
            if(this.updatingData) {return;};
            event.stopPropagation();
            event.preventDefault();
            if(this.displayItems.length > 1)
            {
                var id = this.displayItems[this.incrementedPrincipalIndex(numVisibleItems-1)].id
                if(event.shiftKey)
                {
                    this.addToSubPrincipalShift(id);
                    this.setPrincipal(id, false);
                }
                else
                {
                    this.setPrincipal(id, !(event.ctrlKey || event.metaKey));
                }
            }
             return false;
         }
         
         if(event.key == "PageUp")
         {
             if(this.updatingData) {return;};
            event.stopPropagation();
            event.preventDefault();
            if(this.displayItems.length > 1)
            {
                var id = this.displayItems[this.decrementedPrincipalIndex(numVisibleItems-1)].id;
                if(event.shiftKey)
                {
                    this.addToSubPrincipalShift(id);
                    this.setPrincipal(id, false);
                }
                else
                {
                    this.setPrincipal(id, !(event.ctrlKey || event.metaKey));
                }
            }
             return false;
         }
        
    }
    
    generalKeyEvent(event: KeyboardEvent)
    {
         if(event.keyCode === keyCodes.down)
         {
             if(this.updatingData) {return;};
             event.stopPropagation();
             event.preventDefault();
             this.setPrincipal(this.displayItems[this.incrementedPrincipalIndex()].id, !event.shiftKey);
             return false;
         }
         if(event.keyCode == keyCodes.up)
         {
             if(this.updatingData) {return;};
             event.stopPropagation();
             event.preventDefault();
             this.setPrincipal(this.displayItems[this.decrementedPrincipalIndex()].id, !event.shiftKey);
             return false;
         }
         
         if(event.keyCode == keyCodes.end)
         {
             if(this.updatingData) {return;};
            event.stopPropagation();
            event.preventDefault();
            if(this.displayItems.length > 1)
            {
                 var id = this.displayItems[this.displayItems.length-1].id;
                if(event.shiftKey)
                {
                    this.addToSubPrincipalShift(id);
                    this.setPrincipal(id, false);
                }
                else
                {
                    this.setPrincipal(id, !(event.ctrlKey || event.metaKey));
                }
                
            }
             return false;
         }
         if(event.keyCode == keyCodes.home)
         {
             if(this.updatingData) {return;};
             event.stopPropagation();
             event.preventDefault();
             if(this.displayItems.length > 1)
             {
                 var id = this.displayItems[0].id;
                if(event.shiftKey)
                {
                    this.addToSubPrincipalShift(id);
                    this.setPrincipal(id, false);
                }
                else
                {
                    this.setPrincipal(id, !(event.ctrlKey || event.metaKey));
                }
                 
             }
             return false;
         }
         
         if(event.keyCode === keyCodes.space || event.keyCode === keyCodes.enter)
         {
             if(this.updatingData) {return;};
             event.stopPropagation();
             event.preventDefault();
             this.toggleCheckedSubPrincipal(this.principalId);
             return false;
         }

         if(event.keyCode === keyCodes.a)
         {
            if((event.ctrlKey || event.metaKey))
            {
                event.stopPropagation();
                event.preventDefault();
                this.addAllToSubPrincipal();
            }
            return false;
         }
        
    }

    onMasterCheckboxClick(event, id)
    {
        if(this.masterCheckStatus != "all")
        {
            this.checkAll();
        }
        else
        {
            this.checkNone();
        }
        this.refreshMasterCheckStatus();
    }

    checkAll()
    {
        console.log("There are " + this.queryIds.length + " query ids");
        this.checkedIds = this.queryIds;
        this.refreshMasterCheckStatus();
    }

    checkNone()
    {
        this.checkedIds = [];
        this.refreshMasterCheckStatus();
    }

    onCheckboxClick(event, id)
    {
        if(this.subPrincipalIds.indexOf(id) != -1)
        {
            event.stopPropagation();
            this.toggleCheckedSubPrincipal(this.principalId);
        }
        else
        {
            this.toggleChecked(id);
        }
        this.refreshMasterCheckStatus();
    }

    isChecked(id)
    {
        return this.checkedIds.indexOf(id) != -1;
    }

     toggleCheckedSubPrincipal(id)
     {
         this.checkedIds = this.checkedIds || [];
         if(this.isChecked(id))
         {
             this.checkedIds = this.checkedIds.filter((i) => { return this.subPrincipalIds.indexOf(i) == -1;});
         }
         else
         {
             for(var i = 0; i < this.subPrincipalIds.length; i++)
             {
                 if(this.checkedIds.indexOf(this.subPrincipalIds[i]) == -1)
                 {
                     this.checkedIds.push(this.subPrincipalIds[i]);
                 }
             }
         }
         this.refreshMasterCheckStatus();
     }

     toggleChecked(id)
     {
         this.checkedIds = this.checkedIds || [];
         if(this.isChecked(id))
         {
             this.checkedIds = this.checkedIds.filter((i) => { return i != id});
         }
         else
         {
             this.checkedIds.push(id);
         }
         this.refreshMasterCheckStatus();
     }

     incrementedPrincipalIndex(num = 1) {
         return Math.min(this.principalIndex + num, this.displayItems.length - 1);
     }
     
     decrementedPrincipalIndex(num = 1) {
         return Math.max(this.principalIndex-num, 0);
     }
     
    getOffset(limit, page1Base)
    {
        return limit * (page1Base - 1);
    }

    updateTotalPages()
    {
        this.totalPages = Math.ceil(this.dataLength / this.limit) || 1;
        this.fakePagesArray = new Array(this.totalPages);
    }



    reorderChildrenBehindParents(items) {
        const parents = [];
        const childrenMap = new Map();
    
        // Separate parent and children items
        items.forEach(item => {
            const parentId = item.attributes['application/parent_id'];
            if (parentId === null || !items.some(i => i.id === parentId)) {
                parents.push(item);
            } else {
                if (!childrenMap.has(parentId)) {
                    childrenMap.set(parentId, []);
                }
                childrenMap.get(parentId).push(item);
            }
        });
    
        // Rebuild the array recursively
        const rebuildArray = (parent) => {
            const result = [parent];
            const children = childrenMap.get(parent.id) || [];
            children.forEach(child => {
                result.push(...rebuildArray(child));
            });
            return result;
        };
    
        // Clear the original array and push reordered items into it
        items.length = 0;
        parents.forEach(parent => {
            items.push(...rebuildArray(parent));
        });
    

        for (let i = 0; i < items.length; i++) {
            items[i].index = i+1;
        }
    }



    public updateDisplayItems(requeryDataLength = true)
    {
        if(this.displayItemRequest)
        {
            this.displayItemRequest.unsubscribe();
        }
        this.updatingData = true;

        this.displayItemRequest = 
        this.projectService.getLrwsQueryItems(this.projectId, this.sorting, this._lrwsfilters, this.limit, this.getOffset(this.limit, this.destinationPage1Base), this.junkInstruction).subscribe(
        //this.projectService.getQueryItems(this.projectId, this.sorting, this.filters, this.limit, this.getOffset(this.limit, this.destinationPage1Base)).subscribe(
            response => {
                var newdisplayItems = [];
                console.log(response);
                for(var i = 0; i < response.length; i++)
                {   
                    response[i].attributes['summary/notes'] = response[i].attributes['summary/notes'] || ''; //can't be null or the notes don't update the grid
                    let folder = ""
                    try {
                        folder = response[i].attributes['attributes/file/path'].split("/").slice(1, -1).join("/"); //remove import level and filename
                      } catch (error) {
                      }
                    response[i].attributes['attributes/file/location'] = folder;
                    
                    newdisplayItems.push(response[i])
                }

                this.reorderChildrenBehindParents(newdisplayItems);

                if(response.length > 0) 
                {
                    if(requeryDataLength || this.dataLength == -1)
                    {
                        this.dataLength = -1;
                        this.projectService.getLrwsQueryItemsCount(this.projectId, this._lrwsfilters, this.junkInstruction).subscribe(
                            response => {
                                this.dataLength = response;
                                this.ref.markForCheck();
                                this.updateTotalPages();
                            }
                        );
                    }
                }
                else
                {
                    this.noDataMessage = "No results";
                    this.dataLength = 0;
                    this.setPrincipal(null);
                }

                this.updateTotalPages();

                this.updatingData = false;
                this.displayItems = newdisplayItems;

                if(this.getIndex(this.principalId) == -1 && this.displayItems.length > 0) //Ensure there is always a principal on the current page
                {
                    this.setPrincipal(this.displayItems[0].id);
                }
                else
                {
                    this.setPrincipal(this.principalId); //updates the index
                }

                this.refreshMasterCheckStatus();
                this.scrollTo(this.principalIndex);
                this.displayPage = this.destinationPage1Base;
                this.ref.markForCheck();
            },
            err => {
                this.displayItems = [];
                this.dataLength = 0;
                this.noDataMessage = "An error has occured. Try refreshing the page."
                this.updatingData = false;
                console.log(err);
            }
        );
    }

    sortByHeader(dataColumn: string) {
        console.log("Sort by header");
        console.log(dataColumn);
        if(this.sorting.length == 0) {
            this.sorting.push({column: "summary/title", ascending: true});
        }
        if(this.sorting[0].column == dataColumn)
        {
            this.sorting[0].ascending = !this.sorting[0].ascending;
        }
        else
        {
            this.sorting = [
                {column: dataColumn, ascending: true},
                this.sorting[0]
            ];
        }
        this.updateDisplayItems(false);
        console.log(this.sorting);
    }

    onScrollEvent(event) {
            var headerCells = document.getElementById("dataGridHeaderRow").querySelectorAll('.tableHeaderCell') as NodeListOf<HTMLElement>;
            for(var i = 0; i < headerCells.length; i++)
            {
                headerCells.item(i).style.transform = "translateX(" + ((event.target.scrollLeft) * -1) + "px)";
            }
    }
     
    onRowFocus(event, id)
    {
        if(id == this.principalId) 
        {
            return;
        }
        
        if(event.shiftKey)
        {
            this.addToSubPrincipalShift(id);
            this.setPrincipal(id, false);
        }
        else
        {
            this.setPrincipal(id, !(event.ctrlKey || event.metaKey));
        }
    }
    
     getIndex(id)
     {
         for(var i = 0; i < this.displayItems.length; i++)
         {
             if(this.displayItems[i].id == id)
             {
                 return i;
             }
         }
         return -1;
     }
     
    addToSubPrincipalShift(endId)
    {
        var principalIndex = this.principalIndex;
        var endIndex = this.getIndex(endId);
        
        var rangeMin = Math.min(principalIndex, endIndex);
        var rangeMax = Math.max(principalIndex, endIndex);
        
        for(var i = rangeMin; i <= rangeMax; i++)
        {
            this.addToSubPrincipal(this.displayItems[i].id);
        }
    }
     
     addToSubPrincipal(id)
     {
         if(this.subPrincipalIds.indexOf(id) == -1)
         {
             this.subPrincipalIds.push(id);
         }
     }
     
     addAllToSubPrincipal()
     {
         this.subPrincipalIds = [];
         for(var i = 0; i < this.displayItems.length; i++)
         {
            this.subPrincipalIds.push(this.displayItems[i].id);
         }
     }
    
     clearSubPrincipal()
     {
         this.subPrincipalIds = [];
     }
    
     emitPrincipalChange()
    {
        this.principalChange.emit(this.getItem(this.principalId));
    }
    
    throttledPrincipalChangeEmit = _.debounce(this.emitPrincipalChange, 150, {leading: true, trailing: true});
    
    
     setPrincipal(id, resetSubPrincipal = true)
     {
         if(resetSubPrincipal)
         {
             this.clearSubPrincipal();
         }
         this.addToSubPrincipal(id);
         this.principalId = id;
         this.principalIndex = this.getIndex(id);
         this.scrollTo(this.principalIndex);
         
         //this.throttledPrincipalChangeEmit();
         this.principalChange.emit(this.getItem(id));
     }
    

    refreshMasterCheckStatus() {
        if(this.dataLength == 0) 
        {
            this.masterCheckStatus = "none";
        }
        else if(this.checkedIds.length == this.dataLength)
        {
            this.masterCheckStatus = "all";
        }
        else if (this.checkedIds.length > 0)
        {
            this.masterCheckStatus = "partial";
        }
        else
        {
            this.masterCheckStatus = "none";
        }
    }

    dividerMouseDown(column, event) {
        this.dragging = true;
        this.resizingColumn = column;
        this.mouseDownColumnWidth = this.resizingColumn.width;
        this.dividerResizeStart = event.pageX;
        console.log(this.dividerResizeStart);
    }

    @HostListener('document:mousemove', ['$event'])
    mouseMove(event) {
        if(this.dragging)
        {
            this.resizingColumn.width = Math.max(this.mouseDownColumnWidth + event.pageX - this.dividerResizeStart, 10);
            console.log(this.dividerResizeStart);
        }
    }

    @HostListener('document:mouseup', ['$event'])
    mouseup(event) {
        this.dragging = false;
    }

    nextPage()
    {
        this.goToPage1Base(Math.min(this.destinationPage1Base + 1, this.totalPages));
    }
    previousPage() {
        this.goToPage1Base(Math.max(this.destinationPage1Base - 1, 1));
    }

    goToPage1Base(i)
    {
        if(i != this.destinationPage1Base)
        {
            this.destinationPage1Base = i;
         }
    }
    
    getItemData(id)
    {
        for(var i = 0; i < this.displayItems.length; i++)
        {
            if(this.displayItems[i].id == id)
            {
                var data = this.displayItems[i].attributes;
                data.files = this.displayItems[i].files;
                data.tags = this.displayItems[i].tags;
                return data;
            }
        }
        return {};
    }
    getItem(id)
    {
        for(var i = 0; i < this.displayItems.length; i++)
        {
            if(this.displayItems[i].id == id)
            {
                return this.displayItems[i];
            }
        }
        return {};
    }
    selectAll(event: Event) {
        event.preventDefault(); // Prevent the checkbox from being checked
        this.addAllToSubPrincipal();
      }
}