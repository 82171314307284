<div class="formContainer">
    <h2>Change password</h2>
<!--    <div>Change password for {{email}}</div>-->
    <form [formGroup]="form.getForm()" (ngSubmit)="onSubmit(); false" class="form-change_password" *ngIf="!success">
        <div class="form-group" [class.invalid]="hasError">
            <label for="existing_password">Existing Password</label>
            <input type="password" class="form-control" id="existing_password" name="existing_password" formControlName="existing_password">
            <small class="error-alert" *ngIf="form.hasError('existing_password')">Existing password is required</small>
        </div>        
        <div class="form-group"  [class.invalid]="hasError">
            <label for="new_password">New Password</label>
            <input type="password" class="form-control" id="new_password" name="new_password" formControlName="new_password">
            <small class="error-alert" *ngIf="form.hasError('new_password')">New password is required</small>
        </div>
        <div class="form-group"  [class.invalid]="hasError">
            <label for="new_confirm_password">Confirm New Password</label>
            <input type="password" class="form-control" id="new_confirm_password" name="new_confirm_password" formControlName="new_confirm_password">
            <small class="error-alert" *ngIf="form.hasError('new_confirm_password')">Passwords do not match</small>
        </div>
        <button class="btn btn-lg btn-primary btn-block submit" type="button" (click)="onSubmit(f.value)">Submit</button>
    </form>
    <h2 *ngIf="success">
        Password changed!
    </h2>
</div>
