import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { Administrator } from '../../model/administrator'
import { AdministratorService } from '../../services/administrator.service';
import { EmitterService } from '../../../emitter.service';
import { ValidationManager } from "ng2-validation-manager";

@Component({
  selector: 'new-administrator-form',
  templateUrl: './administrator.new.component.html',
  styleUrls: ['./administrator.new.component.css']
})

export class NewAdministratorFormComponent implements OnInit {

    model = new Administrator('','', '', '');
    submitted = false;
    form;
    
    ngOnInit()
    {
        this.form = new ValidationManager({
            username: 'required',
            password: 'required',
            confirm_password: 'required|equalTo:password'
        })
    }
    
    constructor(private administratorService: AdministratorService) {

    }
    onSubmit()
    {
        if(this.form.isValid()) {
            this.administratorService.newAdministrator(this.form.getValue('username'), this.form.getValue('password'), this.form.getValue('confirm_password'));
            this.form.reset();
        }
    }

    clearForm()
    {
        this.form.reset();
    }

    get diagnoistics() { return JSON.stringify(this.model);}
}
