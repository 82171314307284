import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'lrw-filter-modifiers',
    templateUrl: './filter.modifiers.component.html',
    styleUrls: ['./filter.modifiers.component.css']
})

export class FilterModifiersComponent {
    anyOrAll = "Any";
    includeKeywords = [];
    excludeKeywords = [];
    @Output() filterChange: EventEmitter<any> = new EventEmitter();
    
    get data():any {
        return {};
    }

    @Input('data') set data(value: any) {
    }
    
    constructor() {

    }

}