<div class="accounteditcomponent">
    <h1>Project Details: {{project.name}}</h1>
<ul>
    <li>Project Id: {{project.id}}</li>
    <li>Project Name: {{project.name}}</li>
</ul>

<a href="projects/{{project.id}}/view">View Project</a>

<h2>Users</h2>
<table class="table table-bordered table-striped">
    <thead>
        <tr>
            <td>Email</td>
            <td>Role</td>
            <td>Remove</td>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let user of project.users">
            <td><a href="users/{{user.id}}">{{user.email}}</a></td>
            <td>{{user.role_name}}</td>
            <td><button class="btn btn-danger btn-xs" (click)="removeUser(user);">Remove</button></td>
        </tr>
    </tbody>
</table>

<div class="collapse-container">
    <a data-toggle='collapse' data-target='#add-user-collapser'>+ Add New User</a>
    <div class="collapse" id="add-user-collapser">
        <form [formGroup]="addUserForm.getForm()">
            <div class="form-group">
                <label for="user">User email:</label>
                <input type="text" class="form-control" id="user" formControlName="user" placeholder="example@email.com">
            </div>
            <div class="form-group">
                <label for="role">Role:</label>
                <select class="form-control" id="role" formControlName="role">
                    <option *ngFor="let role of roles" value="{{role.id}}">{{role.name}}</option>
                </select>
            </div>
            <button class="btn btn-success" type="submit" (click)="onSubmitAddUser();">Submit</button>
        </form>
    </div>
</div>