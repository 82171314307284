<div class="homecomponent">
<!-- <account-widget></account-widget> -->
<h1 class="heading-light">Welcome, {{current_user_email}}</h1>
<div class="workspaceTitleWrapper">
<h2 class="heading-light">Workspaces</h2>
<!-- <a data-bs-toggle="modal" (click)="newWorkspaceName = ''" data-bs-target="#newWorkspaceModal" class="link-light">+ Create New Workspace</a> -->

</div>
<project-list></project-list>

</div>

<div class="modal" #newWorkspaceModal id="newWorkspaceModal" (click)="$event.stopPropagation()" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
          <h1>New Workspace</h1>
      </div>
      <div class="modal-body">
        <div class="info-container"><span class="info-icon bi bi-info"></span><span class="info-message">A workspace is a place to store your projects, control access, and manage billing.</span></div>
        <div class="inputControl">
          <span>Workspace Name: </span>
          <span>
            <input class="form-control section-input inset-input" type="text" placeholder="e.g. Doe Citizen Lawyers Pty Ltd" [(ngModel)]="newWorkspaceName" id="newWorkspaceNameInput" defaultValue="">
          </span>
        </div>
      </div>
      <div class="modal-footer">
          <button class="btn btn-success apply-filters" (click)="createWorkspace(newWorkspaceName)"  data-bs-dismiss="modal" >Create</button>
          <a class="apply-filters"><span [style.marginLeft.px]="5"  data-bs-dismiss="modal">Cancel</span></a>
      </div>
    </div>
  </div>
</div>