<div class="container editAdministratorForm">

    <div>
        Username: {{administrator.username}}
    </div><div>
<!--        Created: {{administrator.created}}-->
    </div><div>
<!--        Updated: {{administrator.updated}}-->
    </div>
    
    <form [formGroup]="form.getForm()" (ngSubmit)="onSubmit();">
        <div class="form-group">
            <label for="new_username">New Username</label>
            <input type="text" class="form-control" id="new_username" name="new_username" formControlName="new_username">
            <small class="error-alert" *ngIf="form.hasError('new_username')">Username is required</small>
        </div>
        <div class="form-group">
            <label for="existing_password">Existing Password</label>
            <input type="password" class="form-control" id="existing_password" name="existing_password" formControlName="existing_password">
            <small class="error-alert" *ngIf="form.hasError('existing_password')">Existing password is required</small>
        </div>        
        <div class="form-group">
            <label for="new_password">New Password</label>
            <input type="password" class="form-control" id="new_password" name="new_password" formControlName="new_password">
            <small class="error-alert" *ngIf="form.hasError('new_password')">New password is required</small>
        </div>
        <div class="form-group">
            <label for="new_confirm_password">Confirm New Password</label>
            <input type="password" class="form-control" id="new_confirm_password" name="new_confirm_password" formControlName="new_confirm_password">
            <small class="error-alert" *ngIf="form.hasError('new_confirm_password')">Passwords do not match</small>
        </div>
        <button class="btn btn-success" type="submit">Submit</button>
        <button class="btn btn-default" type="button" (click)="clearForm()">Reset</button>
    </form>
</div>