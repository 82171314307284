// Dependencies
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { createCustomElement } from '@angular/elements';
import { Response, RequestOptions, ConnectionBackend } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Http } from '@angular/http';
import { RouterModule, Routes, RouteReuseStrategy } from '@angular/router';
import { AngularSplitModule } from 'angular-split';
import { ModalDialogModule } from 'ngx-modal-dialog';
import { TimeagoModule } from 'ngx-timeago';
import { AvatarModule } from 'ngx-avatar';

// From my app
import { AppComponent } from './app.component';
import { AdministratorModule } from './administrator/administrator.module';
import { AdministratorListComponent} from './administrator/components/list/administrator.list.component'
import { AdministratorEditComponent} from './administrator/components/edit/administrator.edit.component'
import { ChangePasswordComponent } from  './administrator/components/change_password/administrator.change_password.component';
import { UserModule } from './user/user.module';
import { ResetPasswordComponent } from './user/components/password_reset/user.password_reset.component';
import { LoginEmailComponent } from './user/components/login_email/user.login_email.component';
import { NewUserFormComponent } from './user/components/new/user.new.component';
import { UnregisteredComponent } from './user/components/unregistered/user.unregistered.component';
import { LoginPasswordComponent } from './user/components/login_password/user.login_password.component';
import { UserActivationComponent } from './user/components/activation/user.activation.component';
import { MessageComponent } from './user/components/message/user.message.component';
import { AccountModule } from './account/account.module';
import { AccountListComponent } from './account/components/list/account.list.component';
import { AccountEditComponent } from './account/components/edit/account.edit.component';
import { AccountEditProjectComponent } from './account/components/editProject/account.editProject.component';
import { ProjectModule } from './project/project.module';
import { ProjectListComponent } from './project/components/list/project.list.component';
import { ProjectEditComponent } from './project/components/edit/project.edit.component';
import { ProjectViewComponent } from './project/components/view/project.view.component';
import { PapersViewComponent } from './project/components/papersview/papersview.component';
import { ProjectInfoComponent } from './project/components/info/project.info.component';
import { AvatarComponent } from './project/components/avatar/avatar';
import { DocEditorComponent } from './project/components/doceditor/doceditor';
import { HomeComponent } from './home/home.component';
import { ErrorComponent } from './error/error.component';
import { EmitterService } from './emitter.service';
import { AuthGuard } from './auth-guard.service';
import { AuthService } from './auth.service';
import { ItemReferenceComponent } from './project/components/doceditor/components/itemreference.component';
import { CacheRouteReuseStrategy } from './cache_route_reuse_strategy';
import { HttpAuthInterceptor } from './http-auth-interceptor';


const appRoutes: Routes = [
    {
        path: 'projects',
        component: ProjectListComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'projects/:project_id/evidence',
        component: ProjectViewComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'projects/:project_id/info',
        component: ProjectInfoComponent,
        canActivate: [AuthGuard]
    },    
    {
        path: 'projects/:project_id/papers',
        component: PapersViewComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'projects/:project_id/papers/:paper_id',
        component: DocEditorComponent,
        canActivate: [AuthGuard]
    },        
    {
        path: 'projects/:project_id/view',
        component: ProjectViewComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'accounts',
        component: AccountListComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'accounts/:id',
        component: AccountEditComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'accounts/:id/projects/:project_id',
        component: AccountEditProjectComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'administrators',
        component: AdministratorListComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'administrators/:id',
        component: AdministratorEditComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'login',
        component: LoginEmailComponent
    },
    {
        path: 'login2',
        component: LoginPasswordComponent
    },
    {
        path: 'users/new',
        component: NewUserFormComponent
    },
    {
        path: 'users/unregistered',
        component: UnregisteredComponent
    },
    {
        path: 'users/change_password',
        component: ChangePasswordComponent
    },
    {
        path: 'users/password_reset/:token',
        component: ResetPasswordComponent
    },
    {
        path: 'users/password_reset',
        component: ResetPasswordComponent
    },
    {
        path: 'users/activation',
        component: UserActivationComponent
    },
    {
        path: 'users/activation/:token',
        component: UserActivationComponent
    },
    {
        path: 'message/:message',
        component: MessageComponent
    },        
    {
        path: '',
        component: HomeComponent,
        canActivate: [AuthGuard]
    },
    {
        path: '**',
        component: ErrorComponent,
        canActivate: [AuthGuard]
    }
]

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ErrorComponent,
    ItemReferenceComponent,
    AvatarComponent
  ],
  imports: [
    RouterModule.forRoot
    (
        appRoutes,
        {
            paramsInheritanceStrategy: "always",
            scrollPositionRestoration: "enabled"
        }
    ),
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    UserModule,
    AdministratorModule,
    AccountModule,
    ProjectModule,
    AngularSplitModule,
    ModalDialogModule.forRoot(),
    TimeagoModule.forRoot(),
    AvatarModule
  ],
  providers: [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpAuthInterceptor,
        multi: true
    },
    EmitterService,
    AuthService,
    {
        provide: RouteReuseStrategy,
        useClass: CacheRouteReuseStrategy
    }
  ],
  bootstrap: [AppComponent],
    entryComponents: [
        ItemReferenceComponent
    ]    
})

export class AppModule { 
   constructor(private injector: Injector)
   {
       const itemReferenceComponent = createCustomElement(ItemReferenceComponent, {injector});
       customElements.define('lrw-paper-itemreference', itemReferenceComponent);
   }
   
   ngDoBootstrap(){};
}