import { Component, AfterViewInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../../../auth.service';
import { UserService } from '../../services/user.service';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'login',
    templateUrl: './user.login_email.component.html',
    styleUrls: ['./user.login_email.component.css']
})

export class LoginEmailComponent implements AfterViewInit {
    errorMsg: string;
    hasError: boolean;
    componentForm: any;
    email: string;
    @ViewChild('autofocus', { static: true }) autofocus: ElementRef;
    constructor(public authService: AuthService, private router: Router, private route: ActivatedRoute, public userService: UserService, private renderer: Renderer2) {
        
    }

    ngAfterViewInit() {
        //this.renderer.invokeElementMethod(this.autofocus.nativeElement, 'focus');
        this.autofocus.nativeElement.focus();
    }
    onSubmit(form: any) {
        this.userService.getPublicStatus(form.email).subscribe((status) => {
            if(status.registered)
            {
                localStorage.setItem("loginEmail", status.email);
                this.router.navigate(['/login2'], {queryParams: { returnUrl: this.route.snapshot.queryParams['returnUrl'] }});
            }
            else
            {
                localStorage.setItem("loginEmail", status.email);
                this.router.navigate(['/users/unregistered'], {queryParams: { returnUrl: this.route.snapshot.queryParams['returnUrl'] }});
            }
        }, (err) => {
             this.errorMsg = "User " + form.email + " not found";
                this.hasError = true;
       });
    }
}