import { Component, OnInit, Input, Output, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'lrw-tagicon',
    templateUrl: './tagicon.html',
    styleUrls: ['./tagicon.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class TagiconComponent {
    _tag: any;
    
    @Input('tag') set tag(value: any) {
        this._tag = value;
    }
    get tag():any {
        return this._tag;
    }
    constructor(private ref: ChangeDetectorRef) {
    }
}
