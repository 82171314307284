<div class="pagediv">
    <!-- <div class="navContainer" *ngIf="authService.loggedIn() && authService.isCurrentUserAuthenticated()"> -->
    <div class="navContainer" >
        <div class="project-nav">
            <ul class="project-nav-navigation">
                <!-- <li class="brand">&alpha;</li> -->
                <li class="breadcrumbs">
                    <div *ngIf="current_user_id" class="breadcrumbEntry" routerLink="/">
                        <img class="navLogo" src="./assets/images/logo-navbar.svg"/>
                    </div>
                    <span *ngIf="projectId" class="separator">&nbsp;|&nbsp;</span>
                    <span class="breadcrumbEntry" *ngIf="projectId" routerLink="/projects/{{projectId}}/evidence">
                        <div class="projectEntry">
                            <div class="projectImageContainer" >
                                <div class="projectImage" [style.background-image]="projectImage"></div>
                            </div>
                            <div class="projectName">{{projectName}}</div>
                        </div>
                    </span>
                </li>
                <li class="projectControls"  *ngIf="projectId">
                    <div class="pageTab" [ngClass]="{active: current_route=='evidence'}" routerLink="projects/{{projectId}}/evidence">Items</div>
                    <div class="pageTab" [ngClass]="{active: current_route=='papers'}" routerLink="projects/{{projectId}}/papers">Indexes</div>
                    <div class="pageTab" [ngClass]="{active: current_route=='info'}" routerLink="projects/{{projectId}}/info">Project Info</div>
                    <div *ngIf="paperName.length > 0" class="pageTab" [ngClass]="{active: true}">{{paperName}}</div>
                    <div class="importStatusList">
                        <div *ngFor="let importStatus of projectService.getCurrentImports(projectId)" class="importStatusEntry">
                            <div class="importProgressBar">
                                    <div class="progress-bar bg-success" [ngClass]="{'bg-warning': importStatus.completed == false, 'active': importStatus.completed == false, 'progress-bar-striped': importStatus.completed == false}" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                                        <div>
                                            <span class="spinner-border spinner-border-sm progress-spinner" role="status" aria-hidden="true" *ngIf="importStatus.completed == false"></span>
                                            <i class="bi bi-check-circle"  *ngIf="importStatus.completed == true"></i>
                                            {{importStatus.importName}}
                                        </div>
                                        <div>
                                            {{importStatus.status}}
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div class="importDirtyRefresher" *ngIf="projectService.importsDirty" (click)="reload()">
                            <span class="bi bi-arrow-clockwise"></span>
                            <div>
                                Refresh new items
                            </div>
                        </div>
                    </div>
                </li>
                <li class="authli">

                    <div class="dropdown notifications-parent" *ngIf="authService.loggedIn() && notifications.length > 0" dropdown (onShown)="notificationsShown()" (onHidden)="notificationsHidden()">
                        <div class="notifications-container" style="margin: 0; border: 0px;" data-bs-toggle="dropdown"  id="notificationBellButton">
                            <div class="bi bi-bell-fill authIcon"></div>
                            <div class="notifications-count" *ngIf="notifications.length > 0">{{notifications.length}}</div>
                        </div>
                        <div class="dropdown-menu dropdown-menu-right notifications-dropdown"  aria-labelledby="currentUserButton">
                            <div class="notifications-header">
                                <span class="notifications-header-title">Notifications</span>
                                <span class="notifications-header-dismiss-all"><a (click)="dismissAllNotifications()">Dismiss All</a></span>
                            </div>
                            <ul class="notifications-list">
                                <li class="notification-item" *ngFor="let notification of notifications" [ngClass]="{'unread-notification': notification.viewed==false}"><span class="timeago">{{notification.created | timeago:true}}</span>{{notification.message}}</li>
                            </ul>
                        </div>
                    </div>

                    <div class="dropdown authMenu" *ngIf="authService.loggedIn()">
                        <div id="currentUserButton" data-bs-toggle="dropdown" aria-haspopup="true" style="margin-top: 5px; border: 0px;">
                            <lrw-avatar size="32" user_id="{{current_user_id}}" user_email="{{current_user_email}}" class="authIcon"></lrw-avatar>
                            <!-- <i class="bi bi-person-circle authIcon"></i> -->
                        </div>
                        <div class="dropdown-menu dropdown-menu-right userMenu"  aria-labelledby="currentUserButton">
                            <div class="big-avatar-container">
                                <lrw-avatar size="64" user_id="{{current_user_id}}" user_email="{{current_user_email}}" class="authIcon"></lrw-avatar>
                            </div>
                            <div class="avatar-email">{{current_user_email}}</div>
                            <div class="footerButtons">
                                <button type="button" class="btn btn-primary btn-sm button-shadow" (click)="authService.logout()">Sign Out</button>
                            </div>
                        </div>
                    </div>    
                    
                    
                </li>
            </ul>
        </div>
    </div>
    <div class="bodyContainer">
        <router-outlet (activate)='onActivate($event)'></router-outlet>
    </div>
</div>