<h1>Registered Administrators</h1>
<table class="table table-bordered table-striped">
    <thead>
        <tr>
            <th>Username</th>
            <th>Created</th>
            <th>Updated</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let administrator of administrators">
            <td>
                <a routerLink="{{'/administrators/' + administrator.id}}"><span>{{administrator.username}}</span></a>
            </td>
            <td>{{administrator.created}}</td>
            <td>{{administrator.updated}}</td>
        </tr>
    </tbody>
</table>
<div class="new-administrator-form-collapse-container">
    <a data-toggle='collapse' data-target='#new-administrator-collapser'>+ Add New Administrator</a>
    <div class="collapse" id="new-administrator-collapser">
        <new-administrator-form></new-administrator-form>
    </div>
</div>