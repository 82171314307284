import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule, FormGroup } from '@angular/forms';
import { HttpModule, JsonpModule } from '@angular/http';
import { MomentTimezonePickerModule } from 'moment-timezone-picker';
import { ModalDialogModule } from 'ngx-modal-dialog';

import { AccountListComponent } from './components/list/account.list.component'
import { AccountEditComponent } from './components/edit/account.edit.component'
import { AccountEditProjectComponent } from './components/editProject/account.editProject.component'
import { NewAccountFormComponent } from './components/new/account.new.component'
import { AccountComponent } from './index'
import { AccountService } from './services/account.service'
import { RoleService } from '../role/services/role.service'
import { AuthGuard } from '../auth-guard.service'

@NgModule({
  imports: [
      CommonModule,
      BrowserModule,
      HttpModule,
      FormsModule,
      ReactiveFormsModule,
      JsonpModule,
      MomentTimezonePickerModule, //add this
      ModalDialogModule.forRoot()
  ],
  declarations: [
      AccountListComponent,
      AccountEditComponent,
      AccountEditProjectComponent,
      NewAccountFormComponent,
      AccountComponent
  ],
    providers: [
        AccountService,
        RoleService,
        AuthGuard
    ],
    exports: [
        AccountListComponent,
        AccountComponent
    ]
})
export class AccountModule { }
