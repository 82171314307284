<div class="loginComponent">
<div class="formContainer">
    <h2 class="form-signin-heading">Create a Password</h2>
    <div>{{tokenEmail}}</div>
    <br>
    <div>You are new here. Please create a password.</div>
    <form class="form-signin" #f="ngForm" *ngIf="tokenStatus=='valid'">
<!--
        <div class="form-group" [class.invalid]="hasError">
            <label for="first_name">First name</label>
            <input id="first_name" name="first_name" ngModel class="form-control" placeholder="" required type="text">
        </div>
        <div class="form-group" [class.invalid]="hasError">
            <label for="last_name">Last name</label>
            <input id="last_name" name="last_name" ngModel class="form-control" placeholder="" required type="text">
        </div>
-->
            
        <div class="form-group" [class.invalid]="refpwd.invalid && !refpwd.untouched">
            <label for="password">New password</label>
            <input id="password" name="password" #refpwd="ngModel" ngModel class="form-control" placeholder="" required minlength="7" type="password">
            <div *ngIf="refpwd.invalid && !refpwd.untouched">must be at least 7 characters</div>
        </div>
        <br>
        <div class="form-group" [class.invalid]="hasError || (refpwd2.value != refpwd.value && !refpwd2.untouched)">            
            <label for="password2">Confirm password</label>
            <input id="password2" name="password2" #refpwd2="ngModel" ngModel class="form-control" placeholder="" required type="password">
            <div *ngIf="refpwd2.value != refpwd.value && !refpwd2.untouched">passwords don't match</div>
        </div>
        <button class="btn btn-lg btn-primary btn-block submit" type="button" (click)="onSubmit(f.value)">Submit</button>
    </form>
    <div *ngIf="tokenStatus=='invalid'">
        <h2 class="form-signin-heading">Link invalid</h2>
        This is not a valid activation link. Please try the login page to generate a new link.
    </div>
    <div *ngIf="tokenStatus=='expired'">
        <form class="form-signin" #initiateResetForm="ngForm">
            <h2 class="form-signin-heading">Link expired</h2>
            <div>
                Please enter your email to generate a new activation link.
            </div>        
            <label for="email" class="sr-only">Email</label>
            <input id="email" name="email" ngModel class="form-control" placeholder="Email" required="" type="email">
            <button class="btn btn-lg btn-primary btn-block" type="button" (click)="onInitiateReset(initiateResetForm.value)">Continue</button>
        </form>
    </div>
    <div *ngIf="tokenStatus=='none'">
        <form class="form-signin" #initiateResetForm="ngForm">
            <h2 class="form-signin-heading">Activate user</h2>
            <div>
                Please enter your email address and we will send you a link to activate your access.
            </div>        
            <label for="email" class="sr-only">Email</label>
            <input id="email" name="email" ngModel class="form-control" placeholder="Email" required="" type="email">
            <button class="btn btn-lg btn-primary btn-block" type="button" (click)="onInitiateReset(initiateResetForm.value)">Continue</button>
        </form>
    </div>    
</div>
</div>