<div class="tableHeaderRow" id="dataGridHeaderRow" [ngStyle]="{'transform': 'translateX(' + gridScrollPosX + 'px)'}">
    <div class="tableHeaderCell rowNum"><input type="checkbox" class="selectAllCheckbox" (click)="selectAll($event)" ></div>
<!--    <div class="tableHeaderCell checkColumn"><div class="glyphicon dataGridCheckbox" [ngClass]="{'glyphicon-ok': masterCheckStatus == 'all', 'glyphicon-minus': masterCheckStatus == 'partial'}" (click)="onMasterCheckboxClick()">&nbsp;</div></div>-->
<!--    <div class="tableHeaderCell iconColumn">Type</div>-->
    <ng-container *ngFor="let column of columns; let i = index">
        <div  [style.max-width.px]="column.width + (i == 0 ? 3 : 0)" [style.min-width.px]="column.width + (i == 0 ? 3 : 0)" class="tableHeaderCell" [ngClass]="{'primarySort': sorting.length > 0 && column.dataColumn == sorting[0].column}">
            <div class="tableHeaderContent" (click)="sortByHeader(column.dataColumn)" [ngClass]="{'primarySortAscending': sorting.length > 0 && column.dataColumn == sorting[0].column && sorting[0].ascending == true, 'primarySortDescending': sorting.length > 0 && column.dataColumn == sorting[0].column && sorting[0].ascending == false, noUnderline: updatingData}">{{column.name}}</div>
            <div class="tableHeaderDivider resizableSplitterVertical" (mousedown)="dividerMouseDown(column, $event)" mousemove="dividerMouseMove(column, $event)" mouseup="dividerMouseUp(column, $event)"></div>
        </div>
    </ng-container>
</div>
<div class="busyOverlay" [ngClass]="{loaderVisible: updatingData}" *ngIf="updatingData">
    <lrw-spinner></lrw-spinner>
</div>
<div class="noItems" *ngIf="dataLength == 0 && updatingData == false">{{noDataMessage}}</div>
<virtual-scroller [items]="displayItems" (vsUpdate)="viewPortItems = $event" class="virtualScrollElement" [ngClass]="{updatingData: updatingData}" (scroll)="onScrollEvent($event)" id="datagridvirtualscrollelement" [scrollAnimationTime]="0">
    <div *ngFor="let displayEntry of viewPortItems" class="itemTableRow" [ngClass]="{subPrincipal: subPrincipalIds.indexOf(displayEntry.id) != -1, principal: principalId==displayEntry.id, blurry: updatingData, odd: displayEntry.index % 2, even: !(displayEntry.index % 2)}">
        <div class="itemTableCellContainer rowNum" >
            <div class="itemTableCellContent">{{displayEntry.index}}</div>
        </div>
        <div *ngFor="let column of columns" [style.max-width.px]="column.width" [style.min-width.px]="column.width" class="itemTableCellContainer" (click)="onRowFocus($event, displayEntry.id)">
            <div *ngIf="column.name != 'Tags' && column.name != 'Title'" class="itemTableCellContent" [style.max-width.px]="column.width" [style.min-width.px]="column.width">
                <lrw-attribute-value
                    [attribute-name]="column.dataColumn"
                    [attribute-value]="column.titleCase ? titleCase(displayEntry.attributes[column.dataColumn]) : displayEntry.attributes[column.dataColumn]"
                    [display-timezone]="displayTimezone"
                >
            </lrw-attribute-value>
            </div>
            <div *ngIf="column.name == 'Tags'"  class="itemTableCellContent">
                <lrw-tagicon *ngFor="let tag of displayEntry.tag_ids | sortedTags: tags" [tag]="tag"></lrw-tagicon>
            </div>
            <div *ngIf="column.name == 'Title'" class="itemTableCellContent" [style.max-width.px]="column.width" [style.min-width.px]="column.width">

                <div class="familyIndicator bi bi-paperclip" *ngIf="getItemData(displayEntry.id)['application/top_level_relationship'] == 'descendant'"></div>
                <!-- <div class="familyIndicator" *ngIf="getItemData(displayEntry.id)['application/top_level_relationship'] != 'descendant'"></div> -->

                <lrw-icon [itemType]='getItemData(displayEntry.id)["summary/type"]'></lrw-icon>
                <span class="documentTitle">{{column.titleCase ? titleCase(getItemData(displayEntry.id)[column.dataColumn]) : getItemData(displayEntry.id)[column.dataColumn]}}</span>
            </div>            
        </div>
    </div>
</virtual-scroller>
<div class="tableFooter">
    <ul class="list-inline">
        <li class="list-inline-item noWrap" *ngIf="totalPages > 1">
            <div class="pagination-container">
                <div class="pagination-link pagination-previous" [ngClass]="{'pagination-link-disabled': !(displayPage > 1)}" (click)="previousPage()">◄</div>
                <div class="pagination-ellipsis">Page</div>                
                <div class="pagination-current-page dropup">
                    <button class="pagination-link btn dropdown-toggle" type="button" id="pageDropup" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                        {{displayPage}}
                    </button>
                    <div class="dropdown-menu pageDropdown" aria-labelledby="pageDropup">
                        <li><a class="pageDropupLink" (click)="goToPage1Base(index+1)" *ngFor="let a of fakePagesArray; let index = index">Page {{index + 1}}</a></li>
                    </div>
                </div>
                <div class="pagination-ellipsis">of</div>
                <div class="pagination-link pagination-page" (click)="goToPage1Base(totalPages)">{{totalPages}}</div>
                <div class="pagination-link pagination-next" [ngClass]="{'pagination-link-disabled': !(totalPages > displayPage)}" (click)="nextPage()">►</div>
            </div>
        </li>
        <li class="statusBarContainer noWrap">
            <ul class="list-inline statusBar">
                <li  class="list-inline-item" class="statusBarSection"  *ngIf="displayPage > 0">
                        <!-- <span class="label">Displaying</span> -->
                        <span class="value">{{(((displayPage-1) * limit) + 1).toLocaleString()}} - {{(((displayPage-1) * limit) + displayItems.length).toLocaleString()}}</span>
                        <span class="label">of</span>
                        <span class="value">{{dataLength.toLocaleString() == -1 ? 'Loading ... ' : dataLength.toLocaleString()}}</span>
                        <span class="label">results</span>
                </li>
<!--
                <li  class="list-inline-item" class="statusBarSection">
                        <span class="label">Highlighted:<br></span><span class="value" *ngIf="displayPage > 0">{{subPrincipalIds.length.toLocaleString()}}</span>
                </li>
                <li  class="list-inline-item" class="statusBarSection">
                        <span class="label">Checked:<br></span><span class="value" *ngIf="displayPage > 0">{{checkedIds.length.toLocaleString()}}</span>
                </li>
-->
            </ul>
        </li>
    </ul>
</div>