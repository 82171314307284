import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { Administrator } from '../../model/administrator'
import { AdministratorService } from '../../services/administrator.service';
import { EmitterService } from '../../../emitter.service';
import { ValidationManager } from "ng2-validation-manager";
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'edit-administrator-form',
  templateUrl: './administrator.edit.component.html',
  styleUrls: ['./administrator.edit.component.css']
})

export class AdministratorEditComponent implements OnInit {

    administrator = new Administrator('','','', '');
    submitted = false;
    form;
    
    ngOnInit()
    {
        this.form = new ValidationManager({
            new_username: 'required',
            existing_password: 'required',
            new_password: 'required',
            new_confirm_password: 'required|equalTo:new_password'
        })        
        
        this.route.params.subscribe(params => { 
            this.administratorService.get(params.id).subscribe(
                administrator => {
                    this.administrator = administrator;
                },
                err => {
                    console.log(err);
                }
            );
        });
    }
    constructor(private administratorService: AdministratorService, private route: ActivatedRoute) {
            console.log("We are in the constructor for the AdministratorEditComponent");
    }
    onSubmit()
    {
//        if(this.form.isValid()) {
//            this.administratorService.edit(
//                this.administrator.id, 
//                this.administrator.username, 
//                this.form.getValue('existing_password'), 
//                this.form.getValue('new_username'), 
//                this.form.getValue('new_password'), 
//                this.form.getValue('new_confirm_password')
//            );
//            this.form.reset();
//        }
    }

    clearForm()
    {
        this.form.reset();
    }

    get diagnoistics() { return JSON.stringify(this.administrator);}
}
