import {throwError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { map, catchError, switchMap } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';

//var config = require('./config');

console.log("In auth service. Importing config");
import {configSettings} from './config';
console.log("In Auth Service. We have imported the config");
let config = new configSettings();
console.log("Here is the authservice config");
console.log(config);

@Injectable()
export class AuthService {
    constructor(private http: Http, private router: Router) {
        this.jwtHelper = new JwtHelperService();
    }
    jwtHelper: JwtHelperService;
    tokenAutoRefresher:any;

    login (credentials, returnUrl = null): Observable<any> {
        console.log("We are logging in!!!")
        var self = this;
        console.log("Here is the authservice config");
        console.log(config);
        console.log("calling " + config.api.urls.userauthenticate);
        return this.http.post(config.api.urls.userauthenticate, credentials)
        .pipe(map (
            res => {
                localStorage.setItem('id_token', res.json().token);

                self.setTokenAutoReissue();

                console.log("Logged in");
                if(returnUrl)
                {
                    console.log("redirecting to " + returnUrl);
                    return this.router.navigate([returnUrl]);
                }
            }
        ))
    }

    loggedIn() {
        return localStorage.getItem('id_token') != null;
    }
    
    logout(url = '/')
    {
        localStorage.removeItem('id_token');
        this.router.navigate(['/login'], {queryParams: { returnUrl: url }});
    }
    

    logoutNoRedirect()
    {
        localStorage.removeItem('id_token');
    }

    getToken()
    {
   //     console.log("GETTING TOKEN")
        var token = localStorage.getItem('id_token');
        if(token == null)
        {
            console.log("ERROR: Null Token Returned");
        }

        return token;
    }

    getTokenHeader()
    {
        return {
            'x-access-token': this.getToken(),
            'Access-Control-Allow-Origin': '*'
        };
    }
    
    getCurrentUser()
    {
        if(this.getToken())
        {
            return this.jwtHelper.decodeToken(this.getToken());
        }
        else
        {
            return null;
        }
        
    }
    
    async isCurrentUserAuthenticated() {
        const token = this.getToken();
        if(!this.jwtHelper.isTokenExpired(token))
        {
            return true;
        }
        else
        {
            return this.refreshToken().pipe(
                map((res) => {
                    !this.jwtHelper.isTokenExpired(token);
                }),
                catchError((error) => {
                    return throwError(error);
                })
            );
            
        }
    }
    
    getAuthorisedRequestOptionsX(contentType = 'application/json')
    {
        console.log("Authorised X Options")
        return { 
            headers: new HttpHeaders
            (
                {
                    'Content-Type': contentType,
                    'Accept': 'application/json', 
                    'x-access-token': this.getToken(),
                    'Access-Control-Expose-Headers': "x-access-token"
                }
            )
        }
    }    

    getAuthorisedRequestOptions( headers = {} )
    {
        var authHeaders = {
            'Content-Type': 'application/json',
            'Accept': 'application/json', 
            'x-access-token': this.getToken()
        }

        var allHeaders = {...authHeaders, ...headers};
        return new RequestOptions({ 
            headers: new Headers(allHeaders)
        })
    } 

    getAuthorisedRequestOptionsNoContentType( headers = {} )
    {
        var authHeaders = {
            'x-access-token': this.getToken()
        }

        var allHeaders = {...authHeaders, ...headers};
        return new RequestOptions({ 
            headers: new Headers(allHeaders)
        })
    }    

    getAuthorisedRequestOptionsNoContentTypeHttpClient()
    {
        console.log("getting the auth headers");
        var headers = new HttpHeaders({
                'x-access-token': this.getToken(),
                'Access-Control-Allow-Origin': '*'
            });

        console.log(headers);
        return {headers: headers};
    } 

    getAuthorisedRequestOptionsPdf( headers = {} )
    {
        var authHeaders = {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf', 
            'x-access-token': this.getToken()
        }

        var allHeaders = {...authHeaders, ...headers};
        return new RequestOptions({ 
            headers: new Headers(allHeaders)
        })
    }
    

    getAuthorisedRequestOptionsHttpClient()
    {
        console.log("getting the auth headers");
        var headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json', 
                'x-access-token': this.getToken(),
                'Access-Control-Allow-Origin': '*'
            });

        console.log(headers);
        return {headers: headers};
    }

    refreshToken(): Observable<Response> {
        var self = this;
        console.log("Refreshing Token")
        console.log("Sending to " + config.api.urls.user_reissue_token);
        
        return this.http.post(config.api.urls.user_reissue_token, {}, this.getAuthorisedRequestOptions())
        // ...and calling .json() on the response to return the data
        .pipe(map((res) => {
            console.log("We are in the refresh token function response");
            console.log("here is the body json")
            let body = res.json();
            console.log(body)
            localStorage.setItem('id_token', body.token);
            self.setTokenAutoReissue();
            return body || {};
        }))
        // ...deal with any errors
        .pipe(catchError((err) => {
            self.logout();
            console.log("There was an error refreshing the token");
            console.log(err);
            throw err
        }));
    }

    setTokenAutoReissue()
    {
        var self = this;
        let tokenExpiryDate = self.jwtHelper.getTokenExpirationDate(this.getToken());
        console.log("Here is the token expiry date")
        console.log(tokenExpiryDate)
        let timeoutTime = tokenExpiryDate.getTime() - Date.now() - 20000; //reissue 20 seconds before token expires
        console.log("Here is the timeout time: " + timeoutTime);
        try
        {
            clearTimeout(self.tokenAutoRefresher);
        }
        catch (e)
        {

        }
        self.tokenAutoRefresher = setTimeout(
            () => {
                self.refreshToken()
                .subscribe(
                    () => {},
                    (err) => {
                        console.log(err);
                    }
                );
            },
            timeoutTime
        )        
    }
}