import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'lrw-filter-parties',
    templateUrl: './filter.parties.component.html',
    styleUrls: ['./filter.parties.component.css']
})

export class FilterPartiesComponent {    

    private _data: any = {any: {}};
    get data():any {
        return this._data;
    }
    @Input('data') set data(value: any) {
        this._data = value ? value : null;
        
    }
    
    @Output() filterChange: EventEmitter<any> = new EventEmitter();
    
    constructor() {
    }
    
    filterInputs = {any: ""};
    
    addFilter(filtertype, newVal) {
        if(this.data[filtertype].indexOf(newVal) == -1)
        {
            this.data[filtertype].push(newVal);
        }
        this.filterInputs[filtertype] = "";
        this.filterChange.emit(this.data);
    }
    
    removeFilter(filtertype, val) {
        var index = this.data[filtertype].indexOf(val)
        if( index != -1)
        {
            this.data[filtertype].splice(index, 1);
        }
        this.filterChange.emit(this.data);
    }
    
    onKeyDown(filtertype, event) {
        if(event.keyCode==13)
        {
            this.addFilter(filtertype, this.filterInputs[filtertype])
        }
    }
}
