<div class="container">
<form class="form-signin" #f="ngForm" (ngSubmit)="onLogin(f.value)" *ngIf="!authService.loggedIn()">
    <h2 class="form-signin-heading">Please sign in</h2>
    <label for="username" class="sr-only">Username</label>
    <input id="username" name="username" ngModel class="form-control" placeholder="Username" required="" autofocus="" type="text">
    <label for="password" class="sr-only">Password</label>
    <input id="password" name="password" ngModel class="form-control" placeholder="Password" required="" type="password">
    <button class="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>
</form>
</div>
