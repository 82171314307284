import { Component } from '@angular/core';
import { AuthService } from '../../../auth.service';
import { UserService } from '../../services/user.service';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
    selector: 'login',
    templateUrl: './user.message.component.html',
    styleUrls: ['./user.message.component.css']
})

export class MessageComponent {
    messageName: string;
    constructor(public authService: AuthService, private router: Router, private route: ActivatedRoute, private userService: UserService) {
        this.messageName = "none";
        this.route.params.subscribe(params => {
            this.messageName = params['message'];
        })        
    }
}